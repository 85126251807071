import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

export const NON_FOUNDING_POOL_ID = 0
export const FOUNDING_POOL_ID = [2, 3, 4, 5]
export const LOCKED_POOL_ID = [6, 7]
export const SIX_POOL_ID = 1

const pools: SerializedPoolConfig[] = [
  {
    sousId: NON_FOUNDING_POOL_ID,
    stakingToken: serializedTokens.plearn,
    earningToken: serializedTokens.plearn,
    contractAddress: {
      97: '0x7604914F8B69D05926D2b5D4363296b9227b8ae2',
      56: '0x40E6F70F5B20b5108AE181753ba7E7FF1e9dAE74',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0',
    sortOrder: 2,
    isFinished: true,
  },
  {
    sousId: 1,
    stakingToken: serializedTokens.six,
    earningToken: serializedTokens.plearn,
    contractAddress: {
      97: '0x97722F5db9452524FE71dB6798EAFF6bB61bf582',
      56: '0xf51c533D94E7F745852A9Ab1E0ECbb4dDa9545f9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 3,
    tokenPerBlock: '0',
    isFinished: true,
  },
  {
    sousId: 2, // Founding Investor pool round 1
    stakingToken: serializedTokens.plearn,
    earningToken: serializedTokens.plearn,
    contractAddress: {
      97: '0xb74358a0b670eD18e87F432a78e563ca0f3547A5',
      56: '0x12d798f040416Cc75bf5a37a26549f68461380fC',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.5843321918',
    foundingInvestorPoolRound: 'round 1',
  },
  {
    sousId: 3, // Founding Investor pool round 2
    stakingToken: serializedTokens.plearn,
    earningToken: serializedTokens.plearn,
    contractAddress: {
      97: '0x21F85c28fA5Cc52C5327ED1a89D83D0006AF68fe',
      56: '0xDffa6583f0c873BBdEb77A1b9372b94f3e03CC3d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.4367936644',
    foundingInvestorPoolRound: 'round 2',
  },
  {
    sousId: 4, // Founding Investor pool round 3
    stakingToken: serializedTokens.plearn,
    earningToken: serializedTokens.plearn,
    contractAddress: {
      97: '0xAe67948c4A6465Bc5DAb4010b110B411D1D87a77',
      56: '0xa4f2952735cb2bde4de84c58364c522c9cbf6fcc',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.06100171233',
    foundingInvestorPoolRound: 'round 3',
  },
  {
    sousId: 5, // Founding Investor pool round 4
    stakingToken: serializedTokens.plearn,
    earningToken: serializedTokens.plearn,
    contractAddress: {
      97: '0x8c84F516244084A1e001f93A1e6A220c1740AE0c',
      56: '0x9F24Dc773e35B30016461a23f6A526E5bA8C6bB9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.02140410959',
    foundingInvestorPoolRound: 'round 4',
  },
  {
    sousId: 6, // High-yield Pool 21 days
    stakingToken: serializedTokens.plearn,
    earningToken: serializedTokens.plearn,
    contractAddress: {
      97: '0xE8477b497D0577031bD6B24312013db0D45b923E',
      56: '0x9b45a8eeD3eF6DA3bE222147533Da542aa384006',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0',
    pendingWithdrawalContractAddress: {
      97: '0xFe036e1752074D8572C880CA27c342d2CdF11b86',
      56: '0xC26a3E07D8CCF34195e943C0bb705f206Dd57030',
    },
    lockPeriod: '21 days',
    isFinished: true,
  },
  {
    sousId: 7, // High-yield Pool 7 days
    stakingToken: serializedTokens.plearn,
    earningToken: serializedTokens.plearn,
    contractAddress: {
      97: '0x0F8d75386626C03d891f6F044806103221a25EFe',
      56: '0xc38d542326545470a12B06Bf8e315DE55B0B6B46',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0',
    pendingWithdrawalContractAddress: {
      97: '0xF7bb298f039f0623795512a4004C97c8bd9a710d',
      56: '0x7E4e06C81B41284198C0693cd98eb357257Fc3d9',
    },
    lockPeriod: '7 days',
    isFinished: true,
  },
]

export default pools
