import { Currency } from '@plearn/sdk'
import { request, gql } from 'graphql-request'
import { stringify } from 'qs'
import { GRAPH_API_NFTMARKET, API_PLEARN_NFT } from 'config/constants/endpoints'
import { getErc721Contract } from 'utils/contractHelpers'
import { ethers } from 'ethers'
import map from 'lodash/map'
import { uniq } from 'lodash'
import { pancakeBunniesAddress } from 'views/Nft/market/constants'
import { simpleBSCRpcProvider } from 'utils/providers'
import {
  TokenMarketData,
  ApiCollections,
  TokenIdWithCollectionAddress,
  NftToken,
  NftLocation,
  Collection,
  ApiResponseCollectionTokens,
  ApiResponseSpecificToken,
  ApiCollection,
  CollectionMarketDataBaseFields,
  Transaction,
  Offer,
  ApiSingleTokenData,
  NftAttribute,
  ApiTokenFilterResponse,
  NftActivityFilter,
  MarketEvent,
  OfferSide,
  OfferType,
} from './types'
import { getBaseNftFields, getBaseTransactionFields, getCollectionBaseFields } from './queries'

/**
 * API HELPERS
 */

/**
 * Fetch static data from all collections using the API
 * @returns
 */
export const getCollectionsApi = async (): Promise<ApiCollection[]> => {
  const res = await fetch(`${API_PLEARN_NFT}/collections`)
  if (res.ok) {
    const json = await res.json()
    return json.data
  }
  console.error('Failed to fetch NFT collections', res.statusText)
  return []
}

/**
 * Fetch static data from a collection using the API
 * @returns
 */
export const getCollectionApi = async (collectionAddress: string): Promise<ApiCollection> => {
  const res = await fetch(`${API_PLEARN_NFT}/collections/${collectionAddress}`)
  if (res.ok) {
    const json = await res.json()
    return json.data
  }
  console.error(`API: Failed to fetch NFT collection ${collectionAddress}`, res.statusText)
  return null
}

/**
 * Fetch static data for all nfts in a collection using the API
 * @param collectionAddress
 * @param size
 * @param page
 * @returns
 */
export const getNftsFromCollectionApi = async (
  collectionAddress: string,
  size = 100,
  page = 1,
): Promise<ApiResponseCollectionTokens> => {
  // const isPBCollection = collectionAddress.toLowerCase() === pancakeBunniesAddress.toLowerCase()
  // const requestPath = `${API_NFT}/collections/${collectionAddress}/tokens${!isPBCollection ? `?page=${page}&size=${size}` : ``}`
  const requestPath = `${API_PLEARN_NFT}/collections/${collectionAddress}/tokens`

  const res = await fetch(requestPath)
  if (res.ok) {
    const data = await res.json()
    return data
  }
  console.error(`API: Failed to fetch NFT tokens for ${collectionAddress} collection`, res.statusText)
  return null
}

/**
 * Fetch a single NFT using the API
 * @param collectionAddress
 * @param tokenId
 * @returns NFT from API
 */
export const getNftApi = async (
  collectionAddress: string,
  tokenId: string,
): Promise<ApiResponseSpecificToken['data']> => {
  // const res = await fetch(`${API_NFT}/collections/${collectionAddress}/tokens/${tokenId}`)
  const res = await fetch(`${API_PLEARN_NFT}/collections/${collectionAddress}/tokens/${tokenId}`)
  if (res.ok) {
    const json = await res.json()
    return json.data
  }

  console.error(`API: Can't fetch NFT token ${tokenId} in ${collectionAddress}`, res.status)
  return null
}

/**
 * Fetch a list of NFT from different collections
 * @param from Array of { collectionAddress: string; tokenId: string }
 * @returns Array of NFT from API
 */
export const getNftsFromDifferentCollectionsApi = async (
  from: { collectionAddress: string; tokenId: string }[],
): Promise<NftToken[]> => {
  const promises = from.map((nft) => getNftApi(nft.collectionAddress, nft.tokenId))
  const responses = await Promise.all(promises)
  // Sometimes API can't find some tokens (e.g. 404 response)
  // at least return the ones that returned successfully
  return responses
    .filter((resp) => resp)
    .map((res, index) => ({
      tokenId: res.tokenId,
      name: res.name,
      collectionName: res.collection.name,
      collectionAddress: from[index].collectionAddress,
      description: res.description,
      attributes: res.attributes,
      createdAt: res.createdAt,
      updatedAt: res.updatedAt,
      image: {
        original: res.image?.original,
        thumbnail: res.image?.thumbnail,
        mp4: res.image?.mp4,
        webm: res.image?.webm,
        gif: res.image?.gif,
      },
    }))
}

/**
 * SUBGRAPH HELPERS
 */

export const getDealTokensSg = async (): Promise<Currency[]> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getDealTokens {
          bundle(id: "0") {
            dealTokens {
              id
              name
              symbol
              decimals
            }
          }
        }
      `,
    )
    return res.bundle.dealTokens.map((token) => {
      return {
        decimals: Number(token.decimals),
        symbol: token.symbol,
        name: token.name,
      }
    })
  } catch (error) {
    console.error('Failed to fetch deal tokens', error)
    return []
  }
}

/**
 * Fetch market data from a collection using the Subgraph
 * @returns
 */
export const getCollectionSg = async (collectionAddress: string): Promise<CollectionMarketDataBaseFields> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getCollectionData($collectionAddress: String!) {
          collection(id: $collectionAddress) {
            ${getCollectionBaseFields()}
          }
        }
      `,
      { collectionAddress },
    )
    return res.collection
  } catch (error) {
    console.error('Failed to fetch collection', error)
    return null
  }
}

/**
 * Fetch market data from all collections using the Subgraph
 * @returns
 */
export const getCollectionsSg = async (): Promise<CollectionMarketDataBaseFields[]> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        {
          collections {
            ${getCollectionBaseFields()}
          }
        }
      `,
    )
    return res.collections
  } catch (error) {
    console.error('Failed to fetch NFT collections', error)
    return []
  }
}

/**
 * Fetch market data for nfts in a collection using the Subgraph
 * @param collectionAddress
 * @param first
 * @param skip
 * @returns
 */
export const getNftsFromCollectionSg = async (
  collectionAddress: string,
  first = 1000,
  skip = 0,
): Promise<TokenMarketData[]> => {
  // Squad to be sorted by tokenId as this matches the order of the paginated API return. For PBs - get the most recent,
  // const isPBCollection = collectionAddress.toLowerCase() === pancakeBunniesAddress.toLowerCase()

  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getNftCollectionMarketData($collectionAddress: String!) {
          collection(id: $collectionAddress) {
            id
            nfts(orderBy: tokenId, skip: $skip, first: $first) {
             ${getBaseNftFields()}
            }
          }
        }
      `,
      { collectionAddress, skip, first },
    )
    return res.collection.nfts
  } catch (error) {
    console.error('Failed to fetch NFTs from collection', error)
    return []
  }
}

/**
 * Fetch market data for PancakeBunnies NFTs by bunny id using the Subgraph
 * @param bunnyId - bunny id to query
 * @param existingTokenIds - tokens that are already loaded into redux
 * @returns
 */
export const getNftsByBunnyIdSg = async (
  bunnyId: string,
  existingTokenIds: string[],
  orderDirection: 'asc' | 'desc',
): Promise<TokenMarketData[]> => {
  try {
    const where =
      existingTokenIds.length > 0 ? { isTradable: true, tokenId_not_in: existingTokenIds } : { isTradable: true }
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getNftsByBunnyIdSg($collectionAddress: String!, $where: NFT_filter, $orderDirection: String!) {
          nfts(first: 30, where: $where, orderBy: currentAskPrice, orderDirection: $orderDirection) {
            ${getBaseNftFields()}
          }
        }
      `,
      {
        collectionAddress: pancakeBunniesAddress,
        where,
        orderDirection,
      },
    )
    return res.nfts
  } catch (error) {
    console.error(`Failed to fetch collection NFTs for bunny id ${bunnyId}`, error)
    return []
  }
}

/**
 * Fetch market data for PancakeBunnies NFTs by bunny id using the Subgraph
 * @param bunnyId - bunny id to query
 * @param existingTokenIds - tokens that are already loaded into redux
 * @returns
 */
export const getMarketDataForTokenIds = async (
  collectionAddress: string,
  existingTokenIds: string[],
): Promise<TokenMarketData[]> => {
  try {
    if (existingTokenIds.length === 0) {
      return []
    }
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getMarketDataForTokenIds($collectionAddress: String!, $where: NFT_filter) {
          collection(id: $collectionAddress) {
            id
            nfts(first: 1000, where: $where) {
              ${getBaseNftFields()}
            }
          }
        }
      `,
      {
        collectionAddress,
        where: { tokenId_in: existingTokenIds },
      },
    )
    return res.collection.nfts
  } catch (error) {
    console.error(`Failed to fetch market data for NFTs stored tokens`, error)
    return []
  }
}

export const getNftsMarketData = async (
  where = {},
  first = 1000,
  orderBy = 'id',
  orderDirection: 'asc' | 'desc' = 'desc',
  skip = 0,
): Promise<TokenMarketData[]> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getNftsMarketData($first: Int, $skip: Int!, $where: NFT_filter, $orderBy: NFT_orderBy, $orderDirection: OrderDirection) {
          nfts(where: $where, first: $first, orderBy: $orderBy, orderDirection: $orderDirection, skip: $skip) {
            ${getBaseNftFields()}
            transactionHistory {
              ${getBaseTransactionFields()}
            }
          }
        }
      `,
      { where, first, skip, orderBy, orderDirection },
    )

    return res.nfts
  } catch (error) {
    console.error('Failed to fetch NFTs market data', error)
    return []
  }
}

export const getAllPancakeBunniesLowestPrice = async (bunnyIds: string[]): Promise<Record<string, number>> => {
  try {
    const singlePancakeBunnySubQueries = bunnyIds.map(
      (
        bunnyId,
      ) => `b${bunnyId}:nfts(first: 1, where: { tokenId: ${bunnyId}, isTradable: true }, orderBy: currentAskPrice, orderDirection: asc) {
        currentAskPrice
      }
    `,
    )
    const rawResponse: Record<string, { currentAskPrice: string }[]> = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getAllPancakeBunniesLowestPrice {
          ${singlePancakeBunnySubQueries}
        }
      `,
    )
    return Object.keys(rawResponse).reduce((lowestPricesData, subQueryKey) => {
      const bunnyId = subQueryKey.split('b')[1]
      return {
        ...lowestPricesData,
        [bunnyId]:
          rawResponse[subQueryKey].length > 0 ? parseFloat(rawResponse[subQueryKey][0].currentAskPrice) : Infinity,
      }
    }, {})
  } catch (error) {
    console.error('Failed to fetch PancakeBunnies lowest prices', error)
    return {}
  }
}

export const getAllPancakeBunniesRecentUpdatedAt = async (bunnyIds: string[]): Promise<Record<string, number>> => {
  try {
    const singlePancakeBunnySubQueries = bunnyIds.map(
      (
        bunnyId,
      ) => `b${bunnyId}:nfts(first: 1, where: { tokenId: ${bunnyId}, isTradable: true }, orderBy: updatedAt, orderDirection: desc) {
        updatedAt
      }
    `,
    )
    const rawResponse: Record<string, { updatedAt: string }[]> = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getAllPancakeBunniesLowestPrice {
          ${singlePancakeBunnySubQueries}
        }
      `,
    )
    return Object.keys(rawResponse).reduce((updatedAtData, subQueryKey) => {
      const bunnyId = subQueryKey.split('b')[1]
      return {
        ...updatedAtData,
        [bunnyId]: rawResponse[subQueryKey].length > 0 ? Number(rawResponse[subQueryKey][0].updatedAt) : -Infinity,
      }
    }, {})
  } catch (error) {
    console.error('Failed to fetch PancakeBunnies latest market updates', error)
    return {}
  }
}

/**
 * Returns the lowest price of any NFT in a collection
 */
export const getLowestPriceInCollection = async (collectionAddress: string) => {
  try {
    const response = await getNftsMarketData(
      { collection: collectionAddress, isTradable: true },
      1,
      'currentAskPrice',
      'asc',
    )

    if (response.length === 0) {
      return 0
    }

    const [nftSg] = response
    return parseFloat(nftSg.currentAskPrice)
  } catch (error) {
    console.error(`Failed to lowest price NFTs in collection ${collectionAddress}`, error)
    return 0
  }
}

/**
 * Fetch user trading data for buyTradeHistory, sellTradeHistory and askOrderHistory from the Subgraph
 * @param where a User_filter where condition
 * @returns a UserActivity object
 */
export const getUserActivity = async (
  address: string,
): Promise<{ offerHistory: Offer[]; buyTradeHistory: Transaction[]; sellTradeHistory: Transaction[] }> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getUserActivity($address: String!) {
          user(id: $address) {
            buyTradeHistory(first: 250, orderBy: timestamp, orderDirection: desc) {
              ${getBaseTransactionFields()}
              nft {
                ${getBaseNftFields()}
              }
            }
            sellTradeHistory(first: 250, orderBy: timestamp, orderDirection: desc) {
              ${getBaseTransactionFields()}
              nft {
                ${getBaseNftFields()}
              }
            }
            offerHistory(first: 500, orderBy: updatedAt, orderDirection: desc) {
              id
              transaction
                side
                nft {
                  ${getBaseNftFields()}
                }
                withBNB
                dealToken
                askPrice
                creator {
                  id
                }
                status
                updatedAt
                expire
                updateHistory {
                  id
                  askPrice
                  timestamp
                  type
                }
            }
          }
        }
      `,
      { address },
    )
    return res.user || { askOrderHistory: [], buyTradeHistory: [], sellTradeHistory: [] }
  } catch (error) {
    console.error('Failed to fetch user Activity', error)
    return {
      offerHistory: [],
      buyTradeHistory: [],
      sellTradeHistory: [],
    }
  }
}

export const getCollectionActivity = async (
  address: string,
  nftActivityFilter: NftActivityFilter,
  activityPeriodDuration: number,
  itemPerQuery,
): Promise<{ offers?: Offer[]; transactions?: Transaction[] }> => {
  const getUpdateHistoryOrderEvent = (orderType: MarketEvent): OfferType => {
    switch (orderType) {
      case MarketEvent.LISTINGS:
        return OfferType.NEW
      case MarketEvent.OFFER:
        return OfferType.NEW
      case MarketEvent.MODIFY:
        return OfferType.MODIFY
      case MarketEvent.CANCEL_LISTINGS:
        return OfferType.CANCEL
      case MarketEvent.CANCEL_OFFER:
        return OfferType.CANCEL
      default:
        return OfferType.MODIFY
    }
  }

  const getOfferOrderEvent = (orderType: MarketEvent): OfferSide => {
    switch (orderType) {
      case MarketEvent.OFFER:
        return OfferSide.BUY
      case MarketEvent.CANCEL_OFFER:
        return OfferSide.BUY
      case MarketEvent.LISTINGS:
        return OfferSide.SELL
      case MarketEvent.CANCEL_LISTINGS:
        return OfferSide.SELL
      case MarketEvent.MODIFYBUY:
        return OfferSide.BUY
      default:
        return OfferSide.SELL
    }
  }

  const isFetchAllCollections = address === ''

  const hasCollectionFilter = nftActivityFilter.collectionFilters.length > 0

  const collectionFilterGql = !isFetchAllCollections
    ? `collection: ${JSON.stringify(address)}`
    : hasCollectionFilter
    ? `collection_in: ${JSON.stringify(nftActivityFilter.collectionFilters)}`
    : ``
  const activityDuration = Math.floor((new Date().getTime() - activityPeriodDuration * 1000) / 1000)
  const offersPeriodLTE = activityPeriodDuration > 0 ? `timestamp_gte: ${activityDuration}` : ``
  const transactionPeriodLTE = activityPeriodDuration > 0 ? `timestamp_gte: ${activityDuration}` : ``
  const { typeFilters } = nftActivityFilter
  if (nftActivityFilter.typeFilters.includes(MarketEvent.MODIFY)) {
    typeFilters.concat([MarketEvent.MODIFYBUY])
  }
  const offerTypeFilter = nftActivityFilter.typeFilters
    .filter((marketEvent) => marketEvent !== MarketEvent.SELL)
    .map((marketEvent) => getOfferOrderEvent(marketEvent))
  const offerTypeFilterGql = offerTypeFilter.length > 0 ? `side_in: ${JSON.stringify(offerTypeFilter)}` : ``

  const updateHistoryTypeFilter = nftActivityFilter.typeFilters
    .filter((marketEvent) => marketEvent !== MarketEvent.SELL)
    .map((marketEvent) => getUpdateHistoryOrderEvent(marketEvent))

  const updateHistoryOrderIncluded = nftActivityFilter.typeFilters.length === 0 || updateHistoryTypeFilter.length > 0
  const updateHistoryTypeFilterGql =
    updateHistoryTypeFilter.length > 0 ? `type_in: ${JSON.stringify(updateHistoryTypeFilter)}` : ``
  const transactionIncluded =
    nftActivityFilter.typeFilters.length === 0 ||
    nftActivityFilter.typeFilters.some(
      (marketEvent) => marketEvent === MarketEvent.BUY || marketEvent === MarketEvent.SELL,
    )

  let offerOrderQueryItem = itemPerQuery / 2
  let transactionQueryItem = itemPerQuery / 2

  if (!updateHistoryOrderIncluded || !transactionIncluded) {
    offerOrderQueryItem = !updateHistoryOrderIncluded ? 0 : itemPerQuery
    transactionQueryItem = !transactionIncluded ? 0 : itemPerQuery
  }

  const askOrderGql = updateHistoryOrderIncluded
    ? `offers(first: ${offerOrderQueryItem}, orderBy: updatedAt, orderDirection: desc, where:{
          ${collectionFilterGql}, ${offerTypeFilterGql}
        }) {
          id
          transaction
          side
          nft {
            ${getBaseNftFields()}
          }
          withBNB
          dealToken
          askPrice
          creator {
            id
          }
          status
          updatedAt
          expire
          updateHistory(first: ${offerOrderQueryItem}, where:{${updateHistoryTypeFilterGql}, ${offersPeriodLTE}}) {
            id
            askPrice
            timestamp
            type
          }
        }`
    : ``
  const transactionGql = transactionIncluded
    ? `transactions(first: ${transactionQueryItem}, orderBy: timestamp, orderDirection: desc, where:{
          ${collectionFilterGql}, ${transactionPeriodLTE}
        }) {
          ${getBaseTransactionFields()}
            nft {
              ${getBaseNftFields()}
            }
        }`
    : ``

  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
          query getCollectionActivity {
            ${askOrderGql}
            ${transactionGql}
          }
        `,
    )
    return res || { offers: [], transactions: [] }
  } catch (error) {
    console.error('Failed to fetch collection Activity', error)
    return {
      offers: [],
      transactions: [],
    }
  }
}

export const getTokenActivity = async (
  tokenId: string,
  collectionAddress: string,
): Promise<{ offers: Offer[]; transactions: Transaction[] }> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
            query getCollectionActivity($tokenId: String!, $address: String!) {
              nfts(where:{tokenId: $tokenId, collection: $address}) {
                transactionHistory(orderBy: timestamp, orderDirection: desc) {
                  ${getBaseTransactionFields()}
                    nft {
                      ${getBaseNftFields()}
                    }
                }
                offerHistory(orderBy: updatedAt, orderDirection: desc) {
                  id
                  transaction
                  side
                  nft {
                    ${getBaseNftFields()}
                  }
                  withBNB
                  dealToken
                  askPrice
                  creator {
                    id
                  }
                  status
                  updatedAt
                  expire
                  updateHistory {
                    id
                    askPrice
                    timestamp
                    type
                  }
                }
              }
            }
          `,
      { tokenId, address: collectionAddress },
    )
    if (res.nfts.length > 0) {
      return { offers: res.nfts[0].offerHistory, transactions: res.nfts[0].transactionHistory }
    }
    return { offers: [], transactions: [] }
  } catch (error) {
    console.error('Failed to fetch token Activity', error)
    return {
      offers: [],
      transactions: [],
    }
  }
}

export const combineTokenMarketDataAndOfferHistory = (
  marketData: TokenMarketData,
  offerHistory: Offer[],
): TokenMarketData => {
  return {
    tokenId: marketData.tokenId,
    metadataUrl: marketData.metadataUrl,
    currentOfferId: marketData.currentOfferId,
    currentAskPrice: marketData.currentAskPrice,
    currentOfferExpire: marketData.currentOfferExpire,
    currentAskToken: marketData.currentAskToken,
    currentSeller: marketData.currentSeller,
    latestTradedPrice: marketData.latestTradedPrice,
    latestTradedToken: marketData.latestTradedToken,
    highestTradePrice: marketData.highestTradePrice,
    tradeVolumeBNB: marketData.tradeVolumeBNB,
    totalTrades: marketData.totalTrades,
    isTradable: marketData.isTradable,
    collection: marketData.collection,
    createdAt: marketData.createdAt,
    updatedAt: marketData.updatedAt,
    owner: marketData.owner,
    transactionHistory: marketData.transactionHistory,
    offerHistory,
  }
}

/**
 * Get the most recently listed NFTs
 * @param first Number of nfts to retrieve
 * @returns NftTokenSg[]
 */
export const getLatestListedNfts = async (first: number): Promise<TokenMarketData[]> => {
  try {
    const res = await request(
      GRAPH_API_NFTMARKET,
      gql`
        query getLatestNftMarketData($first: Int) {
          nfts(where: { isTradable: true }, orderBy: updatedAt , orderDirection: desc, first: $first) {
            ${getBaseNftFields()}
            collection {
              id
            }
          }
        }
      `,
      { first },
    )

    return res.nfts
  } catch (error) {
    console.error('Failed to fetch NFTs market data', error)
    return []
  }
}

/**
 * Filter NFTs from a collection
 * @param collectionAddress
 * @returns
 */
export const fetchNftsFiltered = async (
  collectionAddress: string,
  filters: Record<string, string | number>,
): Promise<ApiTokenFilterResponse> => {
  const res = await fetch(`${API_PLEARN_NFT}/collections/${collectionAddress}/filter?${stringify(filters)}`)

  if (res.ok) {
    const data = await res.json()
    return data
  }

  console.error(`API: Failed to fetch NFT collection ${collectionAddress}`, res.statusText)
  return null
}

/**
 * OTHER HELPERS
 */

export const getMetadataWithFallback = (apiMetadata: ApiResponseCollectionTokens['data'], bunnyId: string) => {
  // The fallback is just for the testnet where some bunnies don't exist
  return (
    apiMetadata[bunnyId] ?? {
      name: '',
      description: '',
      collection: { name: 'Pancake Bunnies' },
      image: {
        original: '',
        thumbnail: '',
      },
    }
  )
}

export const getPancakeBunniesAttributesField = (bunnyId: string) => {
  // Generating attributes field that is not returned by API
  // but can be "faked" since objects are keyed with bunny id
  return [
    {
      traitType: 'bunnyId',
      value: bunnyId,
      displayType: null,
    },
  ]
}

export const combineApiAndSgResponseToNftToken = (
  apiMetadata: ApiSingleTokenData,
  marketData: TokenMarketData,
  attributes: NftAttribute[],
) => {
  return {
    tokenId: marketData.tokenId,
    name: apiMetadata.name,
    description: apiMetadata.description,
    collectionName: apiMetadata.collection.name,
    collectionAddress: pancakeBunniesAddress,
    image: apiMetadata.image,
    marketData,
    attributes,
  }
}

export const fetchWalletTokenIdsForCollections = async (
  account: string,
  collections: ApiCollections,
): Promise<TokenIdWithCollectionAddress[]> => {
  const walletNftPromises = map(collections, async (collection): Promise<TokenIdWithCollectionAddress[]> => {
    const { address: collectionAddress } = collection
    const contract = getErc721Contract(collectionAddress, simpleBSCRpcProvider)
    let balanceOfResponse

    try {
      balanceOfResponse = await contract.balanceOf(account)
    } catch (e) {
      console.error(e)
      return []
    }

    const balanceOf = balanceOfResponse.toNumber()

    // User has no NFTs for this collection
    if (balanceOfResponse.eq(0)) {
      return []
    }

    const getTokenId = async (index: number) => {
      try {
        const tokenIdBn: ethers.BigNumber = await contract.tokenOfOwnerByIndex(account, index)
        const tokenId = tokenIdBn.toString()
        return tokenId
      } catch (error) {
        console.error('getTokenIdAndData', error)
        return null
      }
    }

    const tokenIdPromises = []

    // For each index get the tokenId
    for (let i = 0; i < balanceOf; i++) {
      tokenIdPromises.push(getTokenId(i))
    }

    const tokenIds = await Promise.all(tokenIdPromises)
    const nftLocation = NftLocation.WALLET
    const tokensWithCollectionAddress = tokenIds.map((tokenId) => {
      return { tokenId, collectionAddress, nftLocation }
    })

    return tokensWithCollectionAddress
  })

  const walletNfts = await Promise.all(walletNftPromises)
  return walletNfts.flat()
}

/**
 * Helper to combine data from the collections' API and subgraph
 */
export const combineCollectionData = (
  collectionApiData: ApiCollection[],
  collectionSgData: CollectionMarketDataBaseFields[],
): Record<string, Collection> => {
  const collectionsMarketObj: Record<string, CollectionMarketDataBaseFields> = collectionSgData.reduce(
    (prev, current) => ({ ...prev, [current.id]: { ...current } }),
    {},
  )

  return collectionApiData.reduce((accum, current) => {
    const collectionMarket = collectionsMarketObj[current.address]
    const collection: Collection = {
      ...current,
      ...collectionMarket,
    }

    return {
      ...accum,
      [current.address]: collection,
    }
  }, {})
}

/**
 * Evaluate whether a market NFT is in a users wallet, their profile picture, or on sale
 * @param isOnSale boolean
 * @param nft NftToken in wallet
 * @param profileNft Optional nft of users' profile picture
 * @returns NftLocation enum value
 */
export const getNftLocationForMarketNft = (
  isOnSale: boolean,
  nft: NftToken,
  profileNft?: TokenIdWithCollectionAddress,
): NftLocation => {
  if (isOnSale) {
    return NftLocation.FORSALE
  }
  if (!profileNft) {
    return NftLocation.WALLET
  }

  if (profileNft) {
    return profileNft.collectionAddress === nft.collectionAddress && profileNft.tokenId === nft.tokenId
      ? NftLocation.PROFILE
      : NftLocation.WALLET
  }
  console.error(`Cannot determine location for tokenID ${nft.tokenId}, defaulting to NftLocation.WALLET`)
  return NftLocation.WALLET
}

/**
 * Construct complete TokenMarketData entities with a users' wallet NFT ids and market data for their wallet NFTs
 * @param walletNfts TokenIdWithCollectionAddress
 * @param marketDataForWalletNfts TokenMarketData[]
 * @returns TokenMarketData[]
 */
export const attachMarketDataToWalletNfts = (
  walletNfts: TokenIdWithCollectionAddress[],
  marketDataForWalletNfts: TokenMarketData[],
): TokenMarketData[] => {
  const walletNftsWithMarketData = walletNfts.map((walletNft) => {
    const marketData = marketDataForWalletNfts.find(
      (marketNft) =>
        marketNft.tokenId === walletNft.tokenId &&
        marketNft.collection.id.toLowerCase() === walletNft.collectionAddress.toLowerCase(),
    )
    return (
      marketData ?? {
        tokenId: walletNft.tokenId,
        collection: {
          id: walletNft.collectionAddress,
        },
        nftLocation: walletNft.nftLocation,
        owner: null,
        metadataUrl: null,
        createdAt: null,
        updatedAt: null,
        currentOfferId: null,
        currentAskPrice: null,
        currentAskToken: null,
        currentSeller: null,
        latestTradedPrice: null,
        latestTradedToken: null,
        highestTradePrice: null,
        tradeVolumeBNB: null,
        totalTrades: null,
        isTradable: null,
        transactionHistory: null,
      }
    )
  })
  return walletNftsWithMarketData
}

/**
 * Attach TokenMarketData and location to NftToken
 * @param nftsWithMetadata NftToken[] with API metadata
 * @param nftsForSale  market data for nfts that are on sale (i.e. not in a user's wallet)
 * @param walletNfts makret data for nfts in a user's wallet
 * @param tokenIdsInWallet array of token ids in user's wallet
 * @param tokenIdsForSale array of token ids of nfts that are on sale
 * @param profileNftId profile picture token id
 * @returns NFT[]
 */
export const combineNftMarketAndMetadata = (
  nftsWithMetadata: NftToken[],
  nftsForSale: TokenMarketData[],
  walletNfts: TokenMarketData[],
  tokenIdsInWallet: string[],
  tokenIdsForSale: string[],
  profileNft?: TokenIdWithCollectionAddress,
): NftToken[] => {
  const completeNftData = nftsWithMetadata.map<NftToken>((nft) => {
    // Get metadata object
    const isOnSale =
      nftsForSale.filter(
        (forSaleNft) => forSaleNft.tokenId === nft.tokenId && forSaleNft.collection.id === nft.collectionAddress,
      ).length > 0
    let marketData
    if (isOnSale) {
      marketData = nftsForSale.find(
        (marketNft) => marketNft.tokenId === nft.tokenId && marketNft.collection.id === nft.collectionAddress,
      )
    } else {
      marketData = walletNfts.find(
        (marketNft) => marketNft.tokenId === nft.tokenId && marketNft.collection.id === nft.collectionAddress,
      )
    }

    const location = getNftLocationForMarketNft(isOnSale, nft, profileNft)
    return { ...nft, marketData, location }
  })
  return completeNftData
}

/**
 * Get in-wallet, on-sale & profile pic NFT metadata, complete with market data for a given account
 * @param account
 * @param collections
 * @param profileNftWithCollectionAddress
 * @returns Promise<NftToken[]>
 */
export const getCompleteAccountNftData = async (
  account: string,
  collections: ApiCollections,
  profileNftWithCollectionAddress?: TokenIdWithCollectionAddress,
): Promise<NftToken[]> => {
  const walletNftIdsWithCollectionAddress = await fetchWalletTokenIdsForCollections(account, collections)
  if (profileNftWithCollectionAddress?.tokenId) {
    walletNftIdsWithCollectionAddress.unshift(profileNftWithCollectionAddress)
  }

  const uniqueCollectionAddresses = uniq(
    walletNftIdsWithCollectionAddress.map((walletNftId) => walletNftId.collectionAddress),
  )

  const walletNftsByCollection = uniqueCollectionAddresses.map((collectionAddress) => {
    return {
      collectionAddress,
      idWithCollectionAddress: walletNftIdsWithCollectionAddress.filter(
        (walletNft) => walletNft.collectionAddress === collectionAddress,
      ),
    }
  })

  const walletMarketDataRequests = walletNftsByCollection.map((walletNftByCollection) => {
    const tokenIdIn = walletNftByCollection.idWithCollectionAddress.map((walletNft) => walletNft.tokenId)
    return getNftsMarketData({
      tokenId_in: tokenIdIn,
      collection: walletNftByCollection.collectionAddress,
    })
  })

  const walletMarketDataResponses = await Promise.all(walletMarketDataRequests)
  const walletMarketData = walletMarketDataResponses.flat()

  const walletNftsWithMarketData = attachMarketDataToWalletNfts(walletNftIdsWithCollectionAddress, walletMarketData)

  const walletTokenIds = walletNftIdsWithCollectionAddress
    .filter((walletNft) => {
      // Profile Pic NFT is no longer wanted in this array, hence the filter
      return profileNftWithCollectionAddress?.tokenId !== walletNft.tokenId
    })
    .map((nft) => nft.tokenId)

  const marketDataForSaleNfts = await getNftsMarketData({ currentSeller: account })
  const tokenIdsForSale = marketDataForSaleNfts.map((nft) => nft.tokenId)

  const forSaleNftIds = marketDataForSaleNfts.map((nft) => {
    return { collectionAddress: nft.collection.id, tokenId: nft.tokenId }
  })

  const combineMetadataForSaleAndWallet = await getNftsFromDifferentCollectionsApi([
    ...forSaleNftIds,
    ...walletNftIdsWithCollectionAddress,
  ])
  const metadataForAllNfts = combineMetadataForSaleAndWallet.filter((item, index, obj) => obj.indexOf(item) === index)

  const completeNftData = combineNftMarketAndMetadata(
    metadataForAllNfts,
    marketDataForSaleNfts,
    walletNftsWithMarketData,
    walletTokenIds,
    tokenIdsForSale,
    profileNftWithCollectionAddress,
  )

  return completeNftData
}

/**
 * Fetch distribution information for a collection
 * @returns
 */
export const getCollectionDistributionApi = async <T>(collectionAddress: string): Promise<T> => {
  const res = await fetch(`${API_PLEARN_NFT}/collections/${collectionAddress}/distribution`)
  if (res.ok) {
    const data = await res.json()
    return data
  }
  console.error(`API: Failed to fetch NFT collection ${collectionAddress} distribution`, res.statusText)
  return null
}
