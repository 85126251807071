import { serializeTokens } from './tokens'
import { SerializedMemberPoolConfig } from './types'

const serializedTokens = serializeTokens()

const pool: SerializedMemberPoolConfig = {
  stakingToken: serializedTokens.plearn,
  earningPlnToken: serializedTokens.plearn,
  earningPcc: serializedTokens.pcc,
  contractAddress: {
    97: '0xbe1091A6c62a3722dEE797376F1D4a59A6CD181B',
    56: '0x7BAf4da5e51f9C365135C85EcE5180324d4BDcBf',
  },
  harvest: true,
  isFinished: false,
}

export default pool
