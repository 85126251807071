import styled from 'styled-components'
import { Text, Flex } from '@plearn/uikit'

export const ClickableColumnHeader = styled(Text)`
  cursor: pointer;
`

export const TableWrapper = styled(Flex)`
  width: 100%;
  padding-top: 16px;
  flex-direction: column;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  overflow-x: auto;
  max-width: calc(100vw - 48px);
`

export const PageButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.2em;
  margin-bottom: 1.2em;
`

export const Arrow = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`

export const Break = styled.div`
  height: 1px;
  /* background-color: ${({ theme }) => theme.colors.secondary}; */
  width: 100%;
`

export const BorderBottomView = styled(Flex)`
  position: relative;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 8px;
  text-align: center;
  display: flex;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    /* left: 50%;
    transform: translateX(-50%); */
    width: calc(100% + 30px);
    height: 1px;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`

export const BorderBottomViewAndAlignLeft = styled(Flex)`
  position: relative;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(90%);
    height: 1px;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`
