import { useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { BIG_ZERO, BIG_TEN } from 'utils/bigNumber'
import { getBalanceNumber } from 'utils/formatBalance'
import { useWeb3React } from '@web3-react/core'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { simpleRpcProvider } from 'utils/providers'
import { FOUNDING_POOL_ID } from 'config/constants/pools'
import { BLOCKS_PER_YEAR } from 'config'
import { useBlock } from 'state/block/hooks'
import useRefresh from 'hooks/useRefresh'
import {
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
  fetchCakeVaultPublicData,
  fetchCakeVaultUserData,
  fetchCakeVaultFees,
  fetchPoolsStakingLimitsAsync,
} from '.'
import { State, DeserializedPool } from '../types'
import { transformPool } from './helpers'

export const useFetchPublicPoolsData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    const fetchPoolsPublicData = async () => {
      const blockNumber = await simpleRpcProvider.getBlockNumber()
      dispatch(fetchPoolsPublicDataAsync(blockNumber))
    }

    fetchPoolsPublicData()
    dispatch(fetchPoolsStakingLimitsAsync())
  }, [dispatch, slowRefresh])
}

export const useFetchUserPools = (account) => {
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])
}

export const usePools = (): { pools: DeserializedPool[]; userDataLoaded: boolean } => {
  const { pools, userDataLoaded } = useSelector((state: State) => ({
    pools: state.pools.data,
    userDataLoaded: state.pools.userDataLoaded,
  }))
  return { pools: pools.map(transformPool), userDataLoaded }
}

export const usePoolTVL= (): BigNumber => {
  const { pools: poolsWithoutAutoVault, userDataLoaded } = usePools()
  const sum: BigNumber = poolsWithoutAutoVault.map(a => a.totalStaked.times(a.stakingTokenPrice) ).reduce(function(a, b)
  {
    return a.plus(b);
  });
  
  const tvl = getBalanceNumber(sum)
  const totalLiquidity = useMemo(() => {
    return new BigNumber(tvl)
  }, [tvl])

  return totalLiquidity
}

export const useFetchUnlockedTokenAndRewardTokenFoundingInvestorPool= (): number => {
  const { pools: poolsWithoutAutoVault, userDataLoaded } = usePools()
  const foundingInvestorPools = poolsWithoutAutoVault.filter((pool) => FOUNDING_POOL_ID.includes(pool.sousId))
  const { currentBlock } = useBlock()
  const totalReward: number = foundingInvestorPools.map(pool => pool.isFinished ? (pool.endBlock - pool.startBlock) * Number(pool.tokenPerBlock) : (currentBlock - pool.startBlock) * Number(pool.tokenPerBlock) ).reduce(function(pool, b)
  {
    return pool + b;
  });

  const totalFoundingInvestorLockedToken = 13650000
  const UnlockedTokenPerBlock  = totalFoundingInvestorLockedToken / (BLOCKS_PER_YEAR * 2)

  const totalUnlockedToken: number = foundingInvestorPools.map(pool => pool.isFinished ? totalFoundingInvestorLockedToken : (currentBlock - pool.startBlock) * UnlockedTokenPerBlock ).reduce(function(pool, b)
  {
    return pool + b;
  });

  const sum = totalReward + totalUnlockedToken
  const sumUnlockedAndReward = useMemo(() => {
    return sum > 0 ? sum : 0
  }, [sum])

  return sumUnlockedAndReward
}


export const useFetchCakeVault = () => {
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchCakeVaultPublicData())
  }, [dispatch, fastRefresh])

  useEffect(() => {
    dispatch(fetchCakeVaultUserData({ account }))
  }, [dispatch, fastRefresh, account])

  useEffect(() => {
    dispatch(fetchCakeVaultFees())
  }, [dispatch])
}

export const useCakeVault = () => {
  const {
    totalShares: totalSharesAsString,
    pricePerFullShare: pricePerFullShareAsString,
    totalCakeInVault: totalCakeInVaultAsString,
    estimatedCakeBountyReward: estimatedCakeBountyRewardAsString,
    totalPendingCakeHarvest: totalPendingCakeHarvestAsString,
    fees: { performanceFee, callFee, withdrawalFee, withdrawalFeePeriod },
    userData: {
      isLoading,
      userShares: userSharesAsString,
      cakeAtLastUserAction: cakeAtLastUserActionAsString,
      lastDepositedTime,
      lastUserActionTime,
    },
  } = useSelector((state: State) => state.pools.cakeVault)

  const estimatedCakeBountyReward = useMemo(() => {
    return new BigNumber(estimatedCakeBountyRewardAsString)
  }, [estimatedCakeBountyRewardAsString])

  const totalPendingCakeHarvest = useMemo(() => {
    return new BigNumber(totalPendingCakeHarvestAsString)
  }, [totalPendingCakeHarvestAsString])

  const totalShares = useMemo(() => {
    return new BigNumber(totalSharesAsString)
  }, [totalSharesAsString])

  const pricePerFullShare = useMemo(() => {
    return new BigNumber(pricePerFullShareAsString)
  }, [pricePerFullShareAsString])

  const totalCakeInVault = useMemo(() => {
    return new BigNumber(totalCakeInVaultAsString)
  }, [totalCakeInVaultAsString])

  const userShares = useMemo(() => {
    return new BigNumber(userSharesAsString)
  }, [userSharesAsString])

  const cakeAtLastUserAction = useMemo(() => {
    return new BigNumber(cakeAtLastUserActionAsString)
  }, [cakeAtLastUserActionAsString])

  return {
    totalShares,
    pricePerFullShare,
    totalCakeInVault,
    estimatedCakeBountyReward,
    totalPendingCakeHarvest,
    fees: {
      performanceFee,
      callFee,
      withdrawalFee,
      withdrawalFeePeriod,
    },
    userData: {
      isLoading,
      userShares,
      cakeAtLastUserAction,
      lastDepositedTime,
      lastUserActionTime,
    },
  }
}
