import { Currency, ETHER, Token } from '@plearn/sdk'
import { BinanceIcon } from '@plearn/uikit'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import tokens from 'config/constants/tokens'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import getTokenLogoURL from '../../utils/getTokenLogoURL'
import Logo from './Logo'

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER)
      return process.env.REACT_APP_BASE_CHAIN === 'BSC'
        ? []
        : process.env.REACT_APP_BASE_CHAIN === 'POLYGON'
        ? [getTokenLogoURL(tokens.wmatic.address)]
        : [getTokenLogoURL(tokens.weth.address)]

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }
      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === ETHER) {
    return process.env.REACT_APP_BASE_CHAIN === 'BSC' ? (
      <BinanceIcon width={size} style={style} />
    ) : (
      <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
    )
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
