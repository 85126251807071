import React, { useRef } from 'react'
import styled from 'styled-components'
import { Flex } from '@plearn/uikit'
// import { useTranslation } from 'contexts/Localization'
import { DeserializedPool } from 'state/types'
import { LOCKED_POOL_ID, FOUNDING_POOL_ID } from 'config/constants/pools'
import PoolRow from './PoolRow'

interface PoolsTableProps {
  pools: DeserializedPool[]
  userDataLoaded: boolean
  account: string
}

const StyledTable = styled.div`
  border-radius: ${({ theme }) => theme.radii.card};
  scroll-margin-top: 64px;

  background-color: ${({ theme }) => theme.card.background};
  > div:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  }
`

const StyledTableBorderRow = styled.div`
  margin-top: -2px;
  background-color: #231f20;
  height: 5px;
`

const StyledTableBorder = styled.div`
  border-radius: ${({ theme }) => theme.radii.card};
  background-color: unset;
  padding: 1px 1px 3px 1px;
  background-size: 400% 400%;
`

// const ScrollButtonContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   padding-top: 5px;
//   padding-bottom: 5px;
// `

const PoolsTable: React.FC<PoolsTableProps> = ({ pools, userDataLoaded, account }) => {
  const tableWrapperEl = useRef<HTMLDivElement>(null)
  // const { t } = useTranslation()
  // const scrollToTop = (): void => {
  //   tableWrapperEl.current.scrollIntoView({
  //     behavior: 'smooth',
  //   })
  // }
  return (
    <StyledTableBorder>
      <StyledTable id="pools-table" role="table" ref={tableWrapperEl}>
        {pools.map((pool) => (
          <React.Fragment key={pool.isAutoVault ? 'auto-cake' : pool.sousId}>
            <PoolRow
              key={pool.isAutoVault ? 'auto-cake' : pool.sousId}
              pool={pool}
              account={account}
              userDataLoaded={userDataLoaded}
            />
            {/* {LOCKED_POOL_ID.includes(pool.sousId) && pool.sousId === 4&& (
              <span>
                <StyledTableBorderRow>{}</StyledTableBorderRow>
              </span>
            )} */}
          </React.Fragment>
        ))}
        {/* <ScrollButtonContainer>
          <Button variant="text" onClick={scrollToTop}>
            {t('To Top')}
            <ChevronUpIcon color="primary" />
          </Button>
        </ScrollButtonContainer> */}
      </StyledTable>
    </StyledTableBorder>
  )
}

export default PoolsTable
