import isEmpty from 'lodash/isEmpty'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { pancakeBunniesAddress } from 'views/Nft/mint/constants'
import {
  getNftsFromCollectionApi,
  getCollectionsApi,
  combineCollectionData,
  getCollectionApi,
  getNftsFromDifferentCollectionsApi,
  getCompleteAccountNftData,
  getMetadataWithFallback,
  getPancakeBunniesAttributesField,
  combineApiAndSgResponseToNftToken,
  fetchNftsFiltered,
} from './helpers'
import {
  State,
  Collection,
  ApiCollections,
  TokenIdWithCollectionAddress,
  NFTMintInitializationState,
  UserNftInitializationState,
  NftToken,
  NftLocation,
  ApiSingleTokenData,
  NftAttribute,
  NftFilterLoadingState,
  RoundData,
  RoundStatus,
} from './types'

import { fetchNftRoundData } from './fetchNftData'

// Default state for nftMint
const initialState: State = {
  initializationState: NFTMintInitializationState.UNINITIALIZED,
  data: {
    collections: {},
    nfts: {},
    tryVideoNftMedia: true,
    filters: {
      loadingState: NftFilterLoadingState.IDLE,
      activeFilters: {},
      showOnlyOnSale: true,
      nftsStatusShow: 'Available',
      ordering: {
        field: 'tokenId',
        direction: 'asc',
      },
    },
    loadingState: {
      isUpdatingPancakeBunnies: false,
      latestPancakeBunniesUpdateAt: 0,
    },
    users: {},
    user: {
      userNftsInitializationState: UserNftInitializationState.UNINITIALIZED,
      nfts: [],
      activity: {
        initializationState: UserNftInitializationState.UNINITIALIZED,
        offerHistory: [],
        buyTradeHistory: [],
        sellTradeHistory: [],
      },
    },
    roundData: {
      status: RoundStatus.CLOSE,
      mintPrice: '0',
      maxMintPerWallet: 0,
      roundConflict: 0,
      minTierRequired: 0,
      userMintedCount: 0,
      collectionData: {
        maxSupply: 0,
        totalSupply: 0,
        minTokenId: -1,
        maxTokenId: -1,
        mintedTokenIds: [],
      },
      items: [],
      dealToken: '-',
      isFetching: true,
    },
    nftCartItems: {}, // User can buy multiple nfts at time
  },
}
/**
 * Fetch all collections data by combining data from the API (static metadata)
 */
export const fetchCollections = createAsyncThunk<Record<string, Collection>>('nft/mint/fetchCollections', async () => {
  const [collections, collectionsMarket] = await Promise.all([getCollectionsApi(), []])
  return combineCollectionData(collections, collectionsMarket)
})

/**
 * Fetch collection data by combining data from the API (static metadata)
 */
export const fetchCollection = createAsyncThunk<Record<string, Collection>, string>(
  'nft/mint/fetchCollection',
  async (collectionAddress) => {
    const [collection] = await Promise.all([getCollectionApi(collectionAddress), []])
    return combineCollectionData([collection], [])
  },
)

/**
 * Fetch all NFT data for a collections by combining data from the API (static metadata)
 * @param collectionAddress
 */
export const fetchNftsFromCollections = createAsyncThunk<
  NftToken[],
  { collectionAddress: string; page: number; size: number }
>('nft/mint/fetchNftsFromCollections', async ({ collectionAddress, page, size }) => {
  try {
    if (collectionAddress === pancakeBunniesAddress) {
      // PancakeBunnies don't need to pre-fetch "all nfts" from the collection
      // When user visits IndividualNFTPage required nfts will be fetched via bunny id
      return []
    }

    const { data } = await getNftsFromCollectionApi(collectionAddress, size, page)

    if (!data) {
      return []
    }

    const tokenIds = Object.values(data).map((nft: ApiSingleTokenData) => nft.tokenId)
    const nftsMarket = []

    return tokenIds.map((tokenId: string) => {
      const marketData = nftsMarket.find((nft) => nft.tokenId === tokenId) // เหมือนจะไม่ได้ใช้??

      const apiToken = data[tokenId]
      const isBundled = !isEmpty(apiToken.bundles)
      const bundleData = isBundled ? apiToken.bundles[0] : null
      return {
        // tokenId here is just a dummy one to satisfy TS. TokenID does not play any role in gird display below
        tokenId: apiToken.tokenId,
        name: apiToken.name,
        description: apiToken.description,
        collectionAddress,
        collectionName: isBundled ? `${apiToken.collection.name}` : apiToken.collection.name,
        image: apiToken.image,
        attributes: apiToken.attributes,
        isBundled: !!bundleData,
        bundleTokenId: bundleData?.tokenId,
        bundleName: bundleData?.name,
        bundleImage: bundleData?.image,
        bundleAttributes: bundleData?.attributes,
        rarityScore: apiToken.score,
        rank: apiToken.rank,
        marketData,
      }
      // return {
      //   tokenId: id,
      //   name: apiMetadata.name,
      //   description: apiMetadata.description,
      //   collectionName: apiMetadata.collection.name,
      //   collectionAddress,
      //   image: apiMetadata.image,
      //   attributes: apiMetadata.attributes,
      //   rarityScore: apiMetadata.score,
      //   marketData,
      // }
    })
  } catch (error) {
    console.error(`Failed to fetch collection NFTs for ${collectionAddress}`, error)
    return []
  }
})

export const filterNftsFromCollection = createAsyncThunk<
  NftToken[],
  { collectionAddress: string; nftFilters: Record<string, NftAttribute> }
>('nft/mint/filterNftsFromCollection', async ({ collectionAddress, nftFilters }) => {
  try {
    const attrParams = Object.values(nftFilters).reduce(
      (accum, attr) => ({
        ...accum,
        [attr.traitType]: attr.value,
      }),
      {},
    )

    const { data } = await fetchNftsFiltered(collectionAddress, attrParams)

    const nftTokens: NftToken[] = Object.values(data).map((apiToken) => {
      const isBundled = !isEmpty(apiToken.bundles)
      const bundleData = isBundled ? apiToken.bundles[0] : null

      return {
        tokenId: apiToken.tokenId,
        name: apiToken.name,
        description: apiToken.description,
        collectionAddress,
        collectionName: isBundled ? `${apiToken.collection.name}` : apiToken.collection.name,
        image: apiToken.image,
        attributes: apiToken.attributes,
        isBundled: !!bundleData,
        bundleTokenId: bundleData?.tokenId,
        bundleName: bundleData?.name,
        bundleImage: bundleData?.image,
        bundleAttributes: bundleData?.attributes,
        rarityScore: apiToken.score,
        rank: apiToken.rank,
        marketData: null,
      }
    })

    return nftTokens
  } catch {
    return []
  }
})

/**
 * This action keeps data on the individual PancakeBunny page up-to-date. Operation is a twofold
 * 1. Update existing NFTs in the state in case some were sold or got price modified
 * 2. Fetch 30 more NFTs with specified bunny id
 */
export const fetchNewPBAndUpdateExisting = createAsyncThunk<
  NftToken[],
  {
    bunnyId: string
    existingTokensWithBunnyId: string[]
    allExistingPBTokenIds: string[]
    existingMetadata: ApiSingleTokenData
    orderDirection: 'asc' | 'desc'
  }
>('nft/mint/fetchNewPBAndUpdateExisting', async ({ bunnyId, existingMetadata, orderDirection }) => {
  try {
    // 1. Update existing NFTs in the state in case some were sold or got price modified
    const [updatedNfts, updatedNftsMarket] = await Promise.all([getNftsFromCollectionApi(pancakeBunniesAddress), []])

    if (!updatedNfts?.data) {
      return []
    }
    const updatedTokens = updatedNftsMarket.map((marketData) => {
      const apiMetadata = getMetadataWithFallback(updatedNfts.data, '')
      const attributes = getPancakeBunniesAttributesField('')
      return combineApiAndSgResponseToNftToken(apiMetadata, marketData, attributes)
    })

    // 2. Fetch 30 more NFTs with specified bunny id
    let newNfts = { data: { [bunnyId]: existingMetadata } }

    if (!existingMetadata) {
      newNfts = await getNftsFromCollectionApi(pancakeBunniesAddress)
    }
    const nftsMarket = []

    if (!newNfts?.data) {
      return updatedTokens
    }

    const moreTokensWithRequestedBunnyId = nftsMarket.map((marketData) => {
      const apiMetadata = getMetadataWithFallback(newNfts.data, '')
      const attributes = getPancakeBunniesAttributesField('')
      return combineApiAndSgResponseToNftToken(apiMetadata, marketData, attributes)
    })
    return [...updatedTokens, ...moreTokensWithRequestedBunnyId]
  } catch (error) {
    console.error(`Failed to update PancakeBunnies NFTs`, error)
    return []
  }
})

export const fetchUserNfts = createAsyncThunk<
  NftToken[],
  { account: string; profileNftWithCollectionAddress?: TokenIdWithCollectionAddress; collections: ApiCollections }
>('nft/mint/fetchUserNfts', async ({ account, profileNftWithCollectionAddress, collections }) => {
  const completeNftData = await getCompleteAccountNftData(account, collections, profileNftWithCollectionAddress)
  return completeNftData
})

export const updateUserNft = createAsyncThunk<
  NftToken,
  { tokenId: string; collectionAddress: string; location?: NftLocation }
>('nft/mint/updateUserNft', async ({ tokenId, collectionAddress, location = NftLocation.WALLET }) => {
  const marketDataForNft = []
  const metadataForNft = await getNftsFromDifferentCollectionsApi([{ tokenId, collectionAddress }])
  const completeNftData = { ...metadataForNft[0], location, marketData: marketDataForNft[0] }

  return completeNftData
})

export const removeUserNft = createAsyncThunk<string, { tokenId: string }>(
  'nft/mint/removeUserNft',
  async ({ tokenId }) => tokenId,
)

export const addUserNft = createAsyncThunk<
  NftToken,
  { tokenId: string; collectionAddress: string; nftLocation?: NftLocation }
>('nft/mint/addUserNft', async ({ tokenId, collectionAddress, nftLocation = NftLocation.WALLET }) => {
  const marketDataForNft = []
  const metadataForNft = await getNftsFromDifferentCollectionsApi([{ tokenId, collectionAddress }])

  return {
    ...metadataForNft[0],
    location: nftLocation,
    marketData: marketDataForNft[0],
  }
})

export const NFT_PHASE1_TIMESTAMP = process.env.REACT_APP_NFT_PHASE1_TIMESTAMP
export const NFT_PHASE1_OPEN = new Date() > new Date(Number(NFT_PHASE1_TIMESTAMP))

export const fetchNFTRoundData = createAsyncThunk<
  RoundData,
  { roundId: number; collectionAddress: string; account?: string }
>('nft/mint/fetchNFTRoundData', async ({ roundId, collectionAddress, account }) => {
  const roundData = await fetchNftRoundData(roundId, collectionAddress, account)
  return roundData
})

export const NftMint = createSlice({
  name: 'NftMint',
  initialState,
  reducers: {
    addTokenToCart: (state, action: PayloadAction<NftToken>) => {
      const { collectionAddress } = action.payload
      const currentItems = state.data.nftCartItems[collectionAddress] || []
      state.data.nftCartItems[collectionAddress] = [...currentItems, action.payload] // [...state.data.nftCartItems[collectionAddress], action.payload]
      
      // state.data.nftCartItems = {
      //   ...state.data.nftCartItems,
      //   [action.payload.collectionAddress]: [...state.data.nftCartItems[action.payload.collectionAddress], action.payload]
      // }
    },
    removeTokenFromCart: (state, action: PayloadAction<NftToken>) => {
      state.data.nftCartItems[action.payload.collectionAddress] = state.data.nftCartItems[action.payload.collectionAddress].filter((items) => items.tokenId !== action.payload.tokenId)
    },
    clearTokenFromCart: (state) => {
      state.data.nftCartItems = {}
    },
    addAttributeFilter: (state, action: PayloadAction<NftAttribute>) => {
      state.data.filters.activeFilters = {
        ...state.data.filters.activeFilters,
        [action.payload.traitType]: action.payload,
      }
    },
    removeAttributeFilter: (state, action: PayloadAction<string>) => {
      if (state.data.filters.activeFilters[action.payload]) {
        delete state.data.filters.activeFilters[action.payload]
      }
    },
    removeAllFilters: (state, action: PayloadAction<string>) => {
      state.data.filters.activeFilters = {}
      state.data.nfts[action.payload] = undefined
    },
    setOrdering: (state, action: PayloadAction<{ field: string; direction: 'asc' | 'desc' }>) => {
      state.data.filters.ordering = action.payload
    },
    setShowOnlyOnSale: (state, action: PayloadAction<boolean>) => {
      state.data.filters.showOnlyOnSale = action.payload
    },
    setTryVideoNftMedia: (state, action: PayloadAction<boolean>) => {
      state.data.tryVideoNftMedia = action.payload
    },
    setShowNftsStatus: (state, action: PayloadAction<string>) => {
      state.data.filters.nftsStatusShow = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(filterNftsFromCollection.pending, (state) => {
      state.data.filters.loadingState = NftFilterLoadingState.LOADING
    })
    builder.addCase(filterNftsFromCollection.fulfilled, (state, action) => {
      const { collectionAddress, nftFilters } = action.meta.arg

      state.data.filters = {
        ...state.data.filters,
        loadingState: NftFilterLoadingState.IDLE,
        activeFilters: nftFilters,
      }
      state.data.nfts[collectionAddress] = action.payload
    })

    builder.addCase(fetchCollection.fulfilled, (state, action) => {
      state.data.collections = { ...state.data.collections, ...action.payload }
    })
    builder.addCase(fetchCollections.fulfilled, (state, action) => {
      state.data.collections = action.payload
      state.initializationState = NFTMintInitializationState.INITIALIZED
    })
    builder.addCase(fetchNftsFromCollections.pending, (state) => {
      state.data.filters.loadingState = NftFilterLoadingState.LOADING
    })
    builder.addCase(fetchNftsFromCollections.fulfilled, (state, action) => {
      const { collectionAddress } = action.meta.arg
      const existingNfts: NftToken[] = state.data.nfts[collectionAddress] ?? []
      const existingNftsWithoutNewOnes = existingNfts.filter(
        (nftToken) => !action.payload.find((newToken) => newToken.tokenId === nftToken.tokenId),
      )

      state.data.filters = {
        ...state.data.filters,
        loadingState: NftFilterLoadingState.IDLE,
        activeFilters: {},
      }
      state.data.nfts[collectionAddress] = [...existingNftsWithoutNewOnes, ...action.payload]
    })
    builder.addCase(fetchNewPBAndUpdateExisting.pending, (state) => {
      state.data.loadingState.isUpdatingPancakeBunnies = true
    })
    builder.addCase(fetchNewPBAndUpdateExisting.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.data.nfts[pancakeBunniesAddress] = action.payload
      }
      state.data.loadingState.isUpdatingPancakeBunnies = false
      state.data.loadingState.latestPancakeBunniesUpdateAt = Date.now()
    })
    builder.addCase(fetchNewPBAndUpdateExisting.rejected, (state) => {
      state.data.loadingState.isUpdatingPancakeBunnies = false
      state.data.loadingState.latestPancakeBunniesUpdateAt = Date.now()
    })
    builder.addCase(fetchUserNfts.rejected, (state) => {
      state.data.user.userNftsInitializationState = UserNftInitializationState.ERROR
    })
    builder.addCase(fetchUserNfts.pending, (state) => {
      state.data.user.userNftsInitializationState = UserNftInitializationState.INITIALIZING
    })
    builder.addCase(fetchUserNfts.fulfilled, (state, action) => {
      state.data.user.nfts = action.payload
      state.data.user.userNftsInitializationState = UserNftInitializationState.INITIALIZED
    })
    builder.addCase(updateUserNft.fulfilled, (state, action) => {
      const userNftsState: NftToken[] = state.data.user.nfts
      const nftToUpdate = userNftsState.find((nft) => nft.tokenId === action.payload.tokenId)
      const indexInState = userNftsState.indexOf(nftToUpdate)
      state.data.user.nfts[indexInState] = action.payload
    })
    builder.addCase(removeUserNft.fulfilled, (state, action) => {
      const copyOfState: NftToken[] = [...state.data.user.nfts]
      const nftToRemove = copyOfState.find((nft) => nft.tokenId === action.payload)
      const indexInState = copyOfState.indexOf(nftToRemove)
      copyOfState.splice(indexInState, 1)
      state.data.user.nfts = copyOfState
    })
    builder.addCase(addUserNft.fulfilled, (state, action) => {
      state.data.user.nfts = [...state.data.user.nfts, action.payload]
    })
    builder.addCase(fetchNFTRoundData.pending, (state) => {
      state.data.roundData.isFetching = true
    })
    builder.addCase(fetchNFTRoundData.fulfilled, (state, action) => {
      state.data.roundData = action.payload
      state.data.roundData.isFetching = false
    })
  },
})

// Actions
export const {
  addTokenToCart,
  removeTokenFromCart,
  clearTokenFromCart,
  addAttributeFilter,
  removeAttributeFilter,
  removeAllFilters,
  setOrdering,
  setShowOnlyOnSale,
  setShowNftsStatus,
  setTryVideoNftMedia,
} = NftMint.actions

export default NftMint.reducer
