import React from 'react'
import { Button, Text, useModal, Flex, Skeleton, Heading } from '@plearn/uikit'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { PoolCategory } from 'config/constants/types'
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import styled from 'styled-components'
import { BIG_ZERO } from 'utils/bigNumber'
import { DeserializedPool } from 'state/types'
import { getAddress } from 'utils/addressHelpers'

import { ActionContainer, ActionTitles, ActionContent } from './styles'
import ClaimModal from '../../PoolCard/Modals/ClaimModal'
import ViewUserLocks from '../../PoolCard/Modals/ViewUserLocks'

const BorderBottomView = styled(Flex)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 8px;
  text-align: center;
  display: inline-flex;
  text-underline-offset: 0.3em;
`

interface PendingWithdrawalActionProps extends DeserializedPool {
  userDataLoaded: boolean
}

const PendingWithdrawalAction: React.FunctionComponent<PendingWithdrawalActionProps> = ({
  sousId,
  pendingWithdrawalContractAddress,
  poolCategory,
  earningToken,
  userData,
  userDataLoaded,
  earningTokenPrice,
}) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  const isCompoundPool = sousId === 0
  const isBnbPool = poolCategory === PoolCategory.BINANCE

  const unlockable = userData?.lockedBalances ? new BigNumber(userData?.lockedBalances.unlockable) : BIG_ZERO
  const unlockableTokenBalance = getBalanceNumber(unlockable, earningToken.decimals)
  const unlockableTokenDollarBalance = getBalanceNumber(
    unlockable.multipliedBy(earningTokenPrice),
    earningToken.decimals,
  )
  const hasUnlockable = unlockable.gt(0)
  const fullBalance = getFullDisplayBalance(unlockable, earningToken.decimals)
  const formattedBalance = formatNumber(unlockableTokenBalance, 3, 3)
  const lockData = userData?.lockedBalances ? userData?.lockedBalances.lockData : undefined
  const locked = userData?.lockedBalances ? new BigNumber(userData?.lockedBalances.locked) : BIG_ZERO
  const lockedFullDisplay = getFullDisplayBalance(new BigNumber(locked), 18, 5)
  const lockedDisplayString = locked.gt(0) ? `${lockedFullDisplay} PLN` : ''

  const [onPresentCollect] = useModal(
    <ClaimModal
      formattedBalance={formattedBalance}
      fullBalance={fullBalance}
      earningToken={earningToken}
      earningsDollarValue={unlockableTokenDollarBalance}
      sousId={sousId}
      pendingWithdrawalContractAddress={getAddress(pendingWithdrawalContractAddress)}
      isBnbPool={isBnbPool}
      isCompoundPool={isCompoundPool}
    />,
  )
  const [onPresentUserLocks] = useModal(<ViewUserLocks sousId={sousId} lockData={lockData} />)

  const actionTitle = (
    <>
      <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
        {t('Unstake')}{' '}
      </Text>
    </>
  )

  const actionTitleAndLockedDisplay = (
    <>
      <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
        {t('Unstake')}{' '}
      </Text>
      <Text fontSize="14px" color="primary" as="span" textTransform="uppercase" ml="4px">
        {lockedDisplayString}
      </Text>
      <BorderBottomView>
        <Button height="auto" width="0px" scale="sm" onClick={onPresentUserLocks} variant="text">
          {t('View')}
        </Button>
      </BorderBottomView>
    </>
  )

  if (!account) {
    return (
      <ActionContainer>
        <ActionTitles>{actionTitle}</ActionTitles>
        <ActionContent>
          <Heading>0</Heading>
          <Button disabled>{t('Claim')}</Button>
        </ActionContent>
      </ActionContainer>
    )
  }

  if (!userDataLoaded) {
    return (
      <ActionContainer>
        <ActionTitles>{actionTitle}</ActionTitles>
        <ActionContent>
          <Skeleton width={180} height="32px" marginTop={14} />
        </ActionContent>
      </ActionContainer>
    )
  }

  return (
    <ActionContainer>
      <ActionTitles>{actionTitleAndLockedDisplay}</ActionTitles>
      <ActionContent>
        <Flex flex="1" pt="16px" flexDirection="column" alignSelf="flex-start">
          <>
            {hasUnlockable ? (
              <>
                <Balance lineHeight="1" bold fontSize="20px" decimals={5} value={unlockableTokenBalance} />
                {earningTokenPrice > 0 && (
                  <Balance
                    display="inline"
                    fontSize="12px"
                    color="textSubtle"
                    decimals={2}
                    prefix="~"
                    value={unlockableTokenDollarBalance}
                    unit=" USD"
                  />
                )}
              </>
            ) : (
              <>
                <Heading color="textDisabled">0</Heading>
                <Text fontSize="12px" color="textDisabled">
                  0 USD
                </Text>
              </>
            )}
          </>
        </Flex>
        <Button disabled={!hasUnlockable} onClick={onPresentCollect}>
          {t('Claim')}
        </Button>
      </ActionContent>
    </ActionContainer>
  )
}

export default PendingWithdrawalAction
