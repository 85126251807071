import { Address } from '../types'

export enum CollectionKey {
  PANCAKE = 'pancake',
  SQUAD = 'pancakeSquad',
  APE = 'ape',
  WHISKEY = 'whiskey',
}

export type Collection = {
  name: string
  description?: string
  slug: string
  address: Address
  roundId?: string
}

export type Collections = {
  [key in CollectionKey]: Collection
}
