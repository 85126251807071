import { stringify } from 'qs'
import { API_PLEARN_NFT } from 'config/constants/endpoints'
import { getErc721Contract } from 'utils/contractHelpers'
import { ethers } from 'ethers'
import map from 'lodash/map'
import { uniq } from 'lodash'
import { pancakeBunniesAddress, plearnApeAddress } from 'views/Nft/mint/constants'
import tokensInCollection from 'config/nftapi/tokensInCollection.json'
import whiskeyTokensInCollection from 'config/nftapi/whiskeyTokensInCollection.json'
import {
  TokenMarketData,
  ApiCollections,
  TokenIdWithCollectionAddress,
  NftToken,
  NftLocation,
  Collection,
  ApiResponseCollectionTokens,
  ApiResponseSpecificToken,
  ApiCollection,
  CollectionMarketDataBaseFields,
  ApiSingleTokenData,
  NftAttribute,
  ApiTokenFilterResponse,
} from './types'

/**
 * API HELPERS
 */

/**
 * Fetch static data from all collections using the API
 * @returns
 */
export const getCollectionsApi = async (): Promise<ApiCollection[]> => {
  // const res = await fetch(`${API_NFT}/collections`)
  const res = await fetch(`${API_PLEARN_NFT}/collections`) // For mockup
  if (res.ok) {
    const json = await res.json()
    return json.data
  }
  console.error('Failed to fetch NFT collections', res.statusText)
  return []

  // For mockup
  // return collectionsData.data
}

/**
 * Fetch static data from a collection using the API
 * @returns
 */
export const getCollectionApi = async (collectionAddress: string): Promise<ApiCollection> => {
  // const res = await fetch(`${API_NFT}/collections/${collectionAddress}`)
  const res = await fetch(`${API_PLEARN_NFT}/collections/${collectionAddress}`) // For mockup
  if (res.ok) {
    const json = await res.json()
    return json.data
  }
  console.error(`API: Failed to fetch NFT collection ${collectionAddress}`, res.statusText)
  return null

  // For mockup
  // const data: ApiCollection = {
  //   address: collectionData.data.address,
  //   owner: collectionData.data.owner,
  //   name: collectionData.data.name,
  //   description: collectionData.data.description,
  //   symbol: collectionData.data.symbol,
  //   totalSupply: collectionData.data.totalSupply,
  //   verified: collectionData.data.verified,
  //   isBundle: collectionData.data.isBundle,
  //   createdAt: collectionData.data.createdAt,
  //   updatedAt: collectionData.data.updatedAt,
  //   avatar: collectionData.data.avatar,
  //   banner: {
  //     large: collectionData.data.banner.large,
  //     small: collectionData.data.banner.small,
  //   },
  //   attributes: collectionData.data.attributes.map((attribute) => {
  //     return {
  //       traitType: attribute.traitType,
  //       value: attribute.value,
  //       displayType: '',
  //     }
  //   }),
  // }
  // return data
}

export const tokensInCollectionData = async (collectionAddress: string): Promise<any> => {
  // mockup
  const isCapApeCollection = collectionAddress === plearnApeAddress
  return isCapApeCollection ? tokensInCollection : whiskeyTokensInCollection
}

/**
 * Fetch static data for all nfts in a collection using the API
 * @param collectionAddress
 * @param size
 * @param page
 * @returns
 */
export const getNftsFromCollectionApi = async (
  collectionAddress: string,
  size = 100,
  page = 1,
): Promise<ApiResponseCollectionTokens> => {
  // const isPBCollection = collectionAddress === pancakeBunniesAddress
  // const requestPath = `${API_NFT}/collections/${collectionAddress}/tokens${!isPBCollection ? `?page=${page}&size=${size}` : ``
  //   }`
  const requestPath = `${API_PLEARN_NFT}/collections/${collectionAddress}/tokens/action=mint` // For mockup
  const res = await fetch(requestPath)

  if (res.ok) {
    const data = await res.json()
    return data
  }
  console.error(`API: Failed to fetch NFT tokens for ${collectionAddress} collection`, res.statusText)
  return null

  // For mockup
  // return tokensInCollectionData(collectionAddress)
}

/**
 * Fetch a single NFT using the API
 * @param collectionAddress
 * @param tokenId
 * @returns NFT from API
 */
export const getNftApi = async (
  collectionAddress: string,
  tokenId: string,
): Promise<ApiResponseSpecificToken['data']> => {
  // const res = await fetch(`${API_NFT}/collections/${collectionAddress}/tokens/${tokenId}`)
  const res = await fetch(`${API_PLEARN_NFT}/collections/${collectionAddress}/tokens/${tokenId}/action=mint`)
  if (res.ok) {
    const json = await res.json()
    return json.data
  }

  console.error(`API: Can't fetch NFT token ${tokenId} in ${collectionAddress}`, res.status)
  return null

  // For mockup
  // const isCapApeCollection = collectionAddress === plearnApeAddress
  // return isCapApeCollection ? tokens[tokenId] : whiskeyTokens[tokenId]
  // return null
}

/**
 * Fetch a list of NFT from different collections
 * @param from Array of { collectionAddress: string; tokenId: string }
 * @returns Array of NFT from API
 */
export const getNftsFromDifferentCollectionsApi = async (
  from: { collectionAddress: string; tokenId: string }[],
): Promise<NftToken[]> => {
  const promises = from.map((nft) => getNftApi(nft.collectionAddress, nft.tokenId))
  const responses = await Promise.all(promises)
  // Sometimes API can't find some tokens (e.g. 404 response)
  // at least return the ones that returned successfully
  return responses
    .filter((resp) => resp)
    .map((res, index) => ({
      tokenId: res.tokenId,
      name: res.name,
      collectionName: res.collection.name,
      collectionAddress: from[index].collectionAddress,
      description: res.description,
      attributes: res.attributes,
      createdAt: res.createdAt,
      updatedAt: res.updatedAt,
      image: {
        original: res.image?.original,
        thumbnail: res.image?.thumbnail,
        mp4: res.image?.mp4,
        webm: res.image?.webm,
      },
    }))
}

/**
 * Filter NFTs from a collection
 * @param collectionAddress
 * @returns
 */
export const fetchNftsFiltered = async (
  collectionAddress: string,
  filters: Record<string, string | number>,
): Promise<ApiTokenFilterResponse> => {
  //   const res = await fetch(`${API_NFT}/collections/${collectionAddress}/filter?${stringify(filters)}`)
  const url = `${API_PLEARN_NFT}/collections/${collectionAddress}/mint/filter?${stringify(filters)}`
  console.debug({ url })
  const res = await fetch(url)

  if (res.ok) {
    const data = await res.json()
    return data
  }

  console.error(`API: Failed to fetch NFT collection ${collectionAddress}`, res.statusText)
  return null
}

/**
 * OTHER HELPERS
 */

export const getMetadataWithFallback = (apiMetadata: ApiResponseCollectionTokens['data'], bunnyId: string) => {
  // The fallback is just for the testnet where some bunnies don't exist
  return (
    apiMetadata[bunnyId] ?? {
      name: '',
      description: '',
      collection: { name: 'Pancake Bunnies' },
      image: {
        original: '',
        thumbnail: '',
      },
    }
  )
}

export const getPancakeBunniesAttributesField = (bunnyId: string) => {
  // Generating attributes field that is not returned by API
  // but can be "faked" since objects are keyed with bunny id
  return [
    {
      traitType: 'bunnyId',
      value: bunnyId,
      displayType: null,
    },
  ]
}

export const combineApiAndSgResponseToNftToken = (
  apiMetadata: ApiSingleTokenData,
  marketData: TokenMarketData,
  attributes: NftAttribute[],
) => {
  return {
    tokenId: marketData.tokenId,
    name: apiMetadata.name,
    description: apiMetadata.description,
    collectionName: apiMetadata.collection.name,
    collectionAddress: pancakeBunniesAddress,
    image: apiMetadata.image,
    marketData,
    attributes,
  }
}

export const fetchWalletTokenIdsForCollections = async (
  account: string,
  collections: ApiCollections,
): Promise<TokenIdWithCollectionAddress[]> => {
  const walletNftPromises = map(collections, async (collection): Promise<TokenIdWithCollectionAddress[]> => {
    const { address: collectionAddress } = collection
    const contract = getErc721Contract(collectionAddress)
    let balanceOfResponse

    try {
      balanceOfResponse = await contract.balanceOf(account)
    } catch (e) {
      console.error(e)
      return []
    }

    const balanceOf = balanceOfResponse.toNumber()

    // User has no NFTs for this collection
    if (balanceOfResponse.eq(0)) {
      return []
    }

    const getTokenId = async (index: number) => {
      try {
        const tokenIdBn: ethers.BigNumber = await contract.tokenOfOwnerByIndex(account, index)
        const tokenId = tokenIdBn.toString()
        return tokenId
      } catch (error) {
        console.error('getTokenIdAndData', error)
        return null
      }
    }

    const tokenIdPromises = []

    // For each index get the tokenId
    for (let i = 0; i < balanceOf; i++) {
      tokenIdPromises.push(getTokenId(i))
    }

    const tokenIds = await Promise.all(tokenIdPromises)
    const nftLocation = NftLocation.WALLET
    const tokensWithCollectionAddress = tokenIds.map((tokenId) => {
      return { tokenId, collectionAddress, nftLocation }
    })

    return tokensWithCollectionAddress
  })

  const walletNfts = await Promise.all(walletNftPromises)
  return walletNfts.flat()
}

/**
 * Helper to combine data from the collections' API and subgraph
 */
export const combineCollectionData = (
  collectionApiData: ApiCollection[],
  collectionSgData: CollectionMarketDataBaseFields[],
): Record<string, Collection> => {
  const collectionsMarketObj: Record<string, CollectionMarketDataBaseFields> = collectionSgData.reduce(
    (prev, current) => ({ ...prev, [current.id]: { ...current } }),
    {},
  )

  console.debug('combineCollectionData Mint+Market')

  return collectionApiData.reduce((accum, current) => {
    const collectionMarket = collectionsMarketObj[current.address]
    const collection: Collection = {
      ...current,
      ...collectionMarket,
    }

    return {
      ...accum,
      [current.address]: collection,
    }
  }, {})
}

/**
 * Evaluate whether a market NFT is in a users wallet, their profile picture, or on sale
 * @param tokenId string
 * @param tokenIdsInWallet array of tokenIds in wallet
 * @param tokenIdsForSale array of tokenIds on sale
 * @param profileNftId Optional tokenId of users' profile picture
 * @returns NftLocation enum value
 */
export const getNftLocationForMarketNft = (
  tokenId: string,
  tokenIdsInWallet: string[],
  tokenIdsForSale: string[],
  profileNftId?: string,
): NftLocation => {
  if (tokenId === profileNftId) {
    return NftLocation.PROFILE
  }
  if (tokenIdsForSale.includes(tokenId)) {
    return NftLocation.FORSALE
  }
  if (tokenIdsInWallet.includes(tokenId)) {
    return NftLocation.WALLET
  }
  console.error(`Cannot determine location for tokenID ${tokenId}, defaulting to NftLocation.WALLET`)
  return NftLocation.WALLET
}

/**
 * Construct complete TokenMarketData entities with a users' wallet NFT ids and market data for their wallet NFTs
 * @param walletNfts TokenIdWithCollectionAddress
 * @param marketDataForWalletNfts TokenMarketData[]
 * @returns TokenMarketData[]
 */
export const attachMarketDataToWalletNfts = (
  walletNfts: TokenIdWithCollectionAddress[],
  marketDataForWalletNfts: TokenMarketData[],
): TokenMarketData[] => {
  const walletNftsWithMarketData = walletNfts.map((walletNft) => {
    const marketData = marketDataForWalletNfts.find(
      (marketNft) => marketNft.tokenId === walletNft.tokenId && marketNft.collection.id === walletNft.collectionAddress,
    )
    return (
      marketData ?? {
        tokenId: walletNft.tokenId,
        collection: {
          id: walletNft.collectionAddress,
        },
        metadataUrl: null,
        updatedAt: null,
        currentOfferId: null,
        currentAskPrice: null,
        currentAskToken: null,
        currentSeller: null,
        latestTradedPrice: null,
        latestTradedToken: null,
        tradeVolumeBNB: null,
        totalTrades: null,
        isTradable: null,
        transactionHistory: null,
      }
    )
  })
  return walletNftsWithMarketData
}

/**
 * Attach TokenMarketData and location to NftToken
 * @param nftsWithMetadata NftToken[] with API metadata
 * @param nftsForSale  market data for nfts that are on sale (i.e. not in a user's wallet)
 * @param walletNfts makret data for nfts in a user's wallet
 * @param tokenIdsInWallet array of token ids in user's wallet
 * @param tokenIdsForSale array of token ids of nfts that are on sale
 * @param profileNftId profile picture token id
 * @returns NFT[]
 */
export const combineNftMarketAndMetadata = (
  nftsWithMetadata: NftToken[],
  nftsForSale: TokenMarketData[],
  walletNfts: TokenMarketData[],
  tokenIdsInWallet: string[],
  tokenIdsForSale: string[],
  profileNftId?: string,
): NftToken[] => {
  const completeNftData = nftsWithMetadata.map<NftToken>((nft) => {
    // Get metadata object
    const isOnSale = nftsForSale.filter((forSaleNft) => forSaleNft.tokenId === nft.tokenId).length > 0
    let marketData
    if (isOnSale) {
      marketData = nftsForSale.find((marketNft) => marketNft.tokenId === nft.tokenId)
    } else {
      marketData = walletNfts.find((marketNft) => marketNft.tokenId === nft.tokenId)
    }
    const location = getNftLocationForMarketNft(nft.tokenId, tokenIdsInWallet, tokenIdsForSale, profileNftId)
    return { ...nft, marketData, location }
  })
  return completeNftData
}

/**
 * Get in-wallet, on-sale & profile pic NFT metadata, complete with market data for a given account
 * @param account
 * @param collections
 * @param profileNftWithCollectionAddress
 * @returns Promise<NftToken[]>
 */
export const getCompleteAccountNftData = async (
  account: string,
  collections: ApiCollections,
  profileNftWithCollectionAddress?: TokenIdWithCollectionAddress,
): Promise<NftToken[]> => {
  const walletNftIdsWithCollectionAddress = await fetchWalletTokenIdsForCollections(account, collections)
  if (profileNftWithCollectionAddress?.tokenId) {
    walletNftIdsWithCollectionAddress.unshift(profileNftWithCollectionAddress)
  }

  const uniqueCollectionAddresses = uniq(
    walletNftIdsWithCollectionAddress.map((walletNftId) => walletNftId.collectionAddress),
  )

  const walletNftsByCollection = uniqueCollectionAddresses.map((collectionAddress) => {
    return {
      collectionAddress,
      idWithCollectionAddress: walletNftIdsWithCollectionAddress.filter(
        (walletNft) => walletNft.collectionAddress === collectionAddress,
      ),
    }
  })

  const walletNftsWithMarketData = attachMarketDataToWalletNfts(walletNftIdsWithCollectionAddress, [])

  const walletTokenIds = walletNftIdsWithCollectionAddress
    .filter((walletNft) => {
      // Profile Pic NFT is no longer wanted in this array, hence the filter
      return profileNftWithCollectionAddress?.tokenId !== walletNft.tokenId
    })
    .map((nft) => nft.tokenId)

  const metadataForAllNfts = await getNftsFromDifferentCollectionsApi([...walletNftIdsWithCollectionAddress])

  const completeNftData = combineNftMarketAndMetadata(
    metadataForAllNfts,
    [],
    walletNftsWithMarketData,
    walletTokenIds,
    [],
    profileNftWithCollectionAddress?.tokenId,
  )

  return completeNftData
}

/**
 * Fetch distribution information for a collection
 * @returns
 */
export const getCollectionDistributionApi = async <T>(collectionAddress: string): Promise<T> => {
  // const res = await fetch(`${API_NFT}/collections/${collectionAddress}/distribution`)
  const res = await fetch(`${API_PLEARN_NFT}/collections/${collectionAddress}/distribution`)
  if (res.ok) {
    const data = await res.json()
    return data
  }
  console.error(`API: Failed to fetch NFT collection ${collectionAddress} distribution`, res.statusText)
  return null
}
