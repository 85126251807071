import { useWeb3React } from '@web3-react/core'
import { DEFAULT_GAS_LIMIT } from 'config'
import { useMemberPool } from 'hooks/useContract'
import { useCallback } from 'react'
import { useAppDispatch } from 'state'
import { fetchMemberPoolUserDataAsync } from 'state/memberPool'
import getGasPrice from 'utils/getGasPrice'
import { calculateGasMargin } from 'utils'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

const memberPoolExtend = async (memberPoolContract, tierIndex) => {
  const gasEstimate = await memberPoolContract.estimateGas.deposit(tierIndex, '0')
  const gasLimit = calculateGasMargin(gasEstimate)
  const gasLimitOptions = {
    gasLimit,
  }

  const tx = await memberPoolContract.deposit(tierIndex, '0', {
    ...gasLimitOptions,
  })
  const receipt = await tx.wait()
  return receipt.status
}

const useExtendLockPeriod = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const memberPoolContract = useMemberPool()

  const handleExtend = useCallback(
    async (tierIndex: number) => {
      await memberPoolExtend(memberPoolContract, tierIndex)

      dispatch(fetchMemberPoolUserDataAsync({ account }))
    },
    [account, dispatch, memberPoolContract],
  )

  return { onExtend: handleExtend }
}

export default useExtendLockPeriod
