import { BigNumberish } from 'ethers'

// Collections -> Nfts -> Transactions
// Users -> Nft tokens IDs

// TODO: Handle the error state on the UI
export enum NFTMintInitializationState {
  UNINITIALIZED = 'UNINITIALIZED',
  INITIALIZED = 'INITIALIZED',
  ERROR = 'ERROR',
}

export enum UserNftInitializationState {
  UNINITIALIZED = 'UNINITIALIZED',
  INITIALIZING = 'INITIALIZING',
  INITIALIZED = 'INITIALIZED',
  ERROR = 'ERROR',
}

export enum NftFilterLoadingState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}

export interface State {
  initializationState: NFTMintInitializationState
  data: {
    collections: Record<string, Collection> // string is the address
    nfts: Record<string, NftToken[]> // string is the collection address
    tryVideoNftMedia: boolean
    filters: {
      loadingState: NftFilterLoadingState
      activeFilters: Record<string, NftAttribute> // string is the collection address
      showOnlyOnSale: boolean
      nftsStatusShow: string
      ordering: {
        field: string
        direction: 'asc' | 'desc'
      }
    }
    loadingState: {
      isUpdatingPancakeBunnies: boolean
      latestPancakeBunniesUpdateAt: number
    }
    users: Record<string, User> // string is the address
    user: UserNftsState
    roundData: RoundData
    nftCartItems: Record<string, NftToken[]>
  }
}

export interface UserNftsState {
  userNftsInitializationState: UserNftInitializationState
  nfts: NftToken[]
  activity: UserActivity
}

export enum RoundStatus {
  OPEN = 'open', // 0 กำลังเปิดขาย
  CLOSE = 'close', // 1 ปิด
}

export interface RoundData {
  status: RoundStatus
  mintPrice: string
  maxMintPerWallet: number
  roundConflict: number
  minTierRequired: number
  userMintedCount: number
  collectionData: CollectionData
  items: number[][][]
  dealToken: string
  isFetching: boolean
}

export interface CollectionData {
  maxSupply: number
  totalSupply: number
  minTokenId: number
  maxTokenId: number
  mintedTokenIds: number[]
}

export interface Transaction {
  id: string
  block: string
  timestamp: string
  askPrice: string
  netPrice: string
  buyer: { id: string }
  seller: { id: string }
  withBNB: boolean
  nft?: TokenMarketData
}

export enum AskOrderType {
  NEW = 'NEW',
  MODIFY = 'MODIFY',
  CANCEL = 'CANCEL',
}

export enum OfferSide {
  BUY = 'Buy',
  SELL = 'Sell',
}

export interface Offer {
  id: string
  block: string
  timestamp: string
  side: string
  askPrice: string
  currency: string
  nft?: TokenMarketData
  buyer?: User
  seller?: User
  status: string
}

export interface Image {
  original: string
  thumbnail: string
  mp4?: string
  webm?: string
  gif?: string
}

export enum NftLocation {
  FORSALE = 'For Sale',
  PROFILE = 'Profile Pic',
  WALLET = 'In Wallet',
}

// Market data regarding specific token ID, acquired via subgraph
export interface TokenMarketData {
  tokenId: string
  collection: {
    id: string
  }
  metadataUrl?: string
  updatedAt: string
  currentAskPrice?: string
  currentAskToken?: string
  currentSeller?: string
  latestTradedPrice?: string
  latestTradedToken?: string
  tradeVolumeBNB: string
  totalTrades: string
  isTradable: boolean
  transactionHistory?: Transaction[]
}

// Represents single NFT token, either Squad-like NFT or single PancakeBunny.
export interface NftToken {
  tokenId: string
  name: string
  description: string
  collectionName: string
  collectionAddress: string
  image: Image
  attributes?: NftAttribute[]
  bundleTokenId?: string
  bundleName?: string
  bundleImage?: Image
  bundleAttributes?: NftAttribute[]
  createdAt?: string // API createdAt
  updatedAt?: string // API updatedAt
  marketData?: TokenMarketData
  location?: NftLocation
  meta?: Record<string, string | number>
  isBundled?: boolean
  rarityScore?: number
  rank?: number
}

export interface TokenIdWithCollectionAddress {
  collectionAddress: string
  tokenId: string
  nftLocation?: NftLocation
}

export interface NftAttribute {
  traitType: string
  value: string | number
  displayType: string
}

// Internal type used to refer to a collection
// Most fields are populated from API (via ApiCollection type)
export interface Collection {
  id: string
  address: string
  name: string
  description?: string
  symbol: string
  active: boolean
  isBundle: boolean
  totalVolumeBNB: string
  numberTokensListed: string
  tradingFee: string
  creatorFee: string
  owner: string
  totalSupply: string
  verified: boolean
  avatar: string
  banner: {
    large: string
    small: string
  }
  attributes?: NftAttribute[]
}

export interface ApiCollections {
  [key: string]: Collection
}

export interface User {
  address: string
  numberTokensListed: BigNumberish
  numberTokensPurchased: BigNumberish
  numberTokensSold: BigNumberish
  nfts: Record<string, BigNumberish> // String is an address, BigNumberish is a tokenID
}

/**
 * API RESPONSES
 */

export interface ApiCollection {
  address: string
  owner: string
  name: string
  description: string
  symbol: string
  totalSupply: string
  verified: boolean
  isBundle: boolean
  createdAt: string
  updatedAt: string
  avatar: string
  banner: {
    large: string
    small: string
  }
  attributes?: NftAttribute[] // returned for specific collection but not for all collections
}

// Get all collections
// ${API_NFT}/collections/
export interface ApiCollectionsReponse {
  total: number
  data: ApiCollection[]
}

// Get single collection
// ${API_NFT}/collections/${collectionAddress}
export interface ApiSingleCollectionResponse {
  data: ApiCollection
}

// Get single collection
// ${API_NFT}/collections/${collectionAddress}
export interface ApiTokenFilterResponse {
  total: number
  data: Record<string, ApiSingleTokenData>
}

export interface ApiSingleTokenData {
  name: string
  description: string
  image: Image
  collection: {
    name: string
  }
  attributes?: NftAttribute[]
  tokenId?: string
  rank?: number
  score?: number
  bundles?: BundleTokenData[]
}

// Get tokens within collection
// ${API_NFT}/collections/${collectionAddress}/tokens
export interface ApiResponseCollectionTokens {
  total: number
  attributesDistribution: Record<string, number>
  data: Record<string, ApiSingleTokenData>
}

export interface BundleTokenData {
  name: string
  description: string
  image: Image
  collection: {
    name: string
  }
  attributes?: NftAttribute[]
  tokenId?: string
  rank?: number
  score?: number
}

// Get specific token data
// ${API_NFT}/collections/${collectionAddress}/tokens/${tokenId}
export interface ApiResponseSpecificToken {
  data: {
    tokenId: string
    name: string
    description: string
    image: Image
    createdAt: string
    updatedAt: string
    attributes: NftAttribute[]
    collection: {
      name: string
    }
    bundles?: BundleTokenData[]
  }
}

// ${API_NFT}/collections/${collectionAddress}/distribution
export interface ApiCollectionDistribution {
  total: number
  data: Record<string, Record<string, number>>
}

export interface ApiCollectionDistributionPB {
  total: number
  data: Record<string, number>
}

/**
 * SUBGRAPH RESPONSES
 */

export interface CollectionMarketDataBaseFields {
  id: string
  name: string
  symbol: string
  active: boolean
  totalTrades: string
  totalVolumeBNB: string
  numberTokensListed: string
  creatorAddress: string
  tradingFee: string
  creatorFee: string
  whitelistChecked?: string
}

export interface UserActivity {
  offerHistory: Offer[]
  buyTradeHistory: Transaction[]
  sellTradeHistory: Transaction[]
  initializationState: UserNftInitializationState
}
