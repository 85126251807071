import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Card, Flex, Heading, Image, Text } from '@plearn/uikit'
import { useWeb3React } from '@web3-react/core'
import FlexLayout from 'components/Layout/Flex'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { usePools, useFetchUserPools } from 'state/memberPool/hooks'
import {
  getFullDisplayBalance,
  formatNumber,
  getDecimalAmount,
  getBalanceAmount,
  getBalanceNumber,
} from 'utils/formatBalance'
import Balance from 'components/Balance'
import tiers from 'config/constants/tiers'
import Stake from './components/ActionPanel/Stake'
import Harvest from './components/ActionPanel/Harvest'
import TierBenefits from './components/TierBenefits'
import MyTierBenefits from './components/MyTierBenefits'
import RulesAndConditions from './components/Rules'

const CardLayout = styled(FlexLayout)`
  justify-content: center;
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
    align-items: flex-start;
    flex-grow: 1;
    flex-basis: 0;
  }
`

export const CardsContainer = styled(Flex)`
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 0;
  gap: 1em;

  & > * {
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  } ;
`

const MytierContainer = styled(Flex)`
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  gap: 15px;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    gap: 0px;
  } ;
`

const StyledTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  width: 100%;
`

const StyledCard = styled(Card)`
  background-color: unset;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  max-width: 540px;
`

const MemberPool: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { account } = useWeb3React()
  const { memberPool, userDataLoaded } = usePools()

  const pool = useMemo(() => {
    return memberPool
  }, [memberPool])

  const { stakingToken, earningPlnToken, earningPcc, isFinished, totalStaked, userData } = pool
  useFetchUserPools(account)

  const myTier = useMemo(() => {
    if (userData.tierIndex === 0) {
      return undefined
    }
    return tiers[userData.tierIndex] ?? undefined
  }, [userData.tierIndex])

  const totalStakedBalance = useMemo(() => {
    return getBalanceNumber(totalStaked, stakingToken.decimals)
  }, [totalStaked, stakingToken.decimals])

  return (
    <>
      <PageHeader background="none">
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
            <Heading as="h1" scale="xxl" color="primary" mt="34px" mb="14px">
              {t('Member Pool')}
            </Heading>
            <Heading scale="md" color="text" mb="7px">
              {t('Stake PLN, earn free PLN tokens and Phill coins.')}
            </Heading>
            {totalStaked.gt(0) && (
              <Balance
                fontSize="16px"
                value={totalStakedBalance}
                decimals={0}
                unit={` ${stakingToken.symbol}`}
                prefix="Total staked : "
              />
            )}
          </Flex>
        </Flex>
      </PageHeader>
      <Page>
        <>
          <ActionContainer>
            <Harvest pool={pool} userDataLoaded={userDataLoaded} />
            <Stake pool={pool} userDataLoaded={userDataLoaded} />
          </ActionContainer>
          {myTier !== undefined && (
            <MytierContainer mb={24} mt={69}>
              <Image src={myTier.tierImageSrc} width={443} height={280} alt="tier" marginRight={24} />
              <MyTierBenefits
                stakedBalance={getBalanceNumber(userData.stakedBalance)}
                tierIndex={userData.tierIndex}
                pool={pool}
              />
            </MytierContainer>
          )}

          <StyledTable>
            <TierBenefits tierIndex={userData.tierIndex} isMember={myTier !== undefined} pool={pool} />
          </StyledTable>
          <StyledTable>
            <RulesAndConditions tierIndex={userData.tierIndex} isMember={myTier !== undefined} pool={pool} />
          </StyledTable>
        </>
      </Page>
    </>
  )
}

export default MemberPool
