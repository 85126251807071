import { ethers } from 'ethers'
import getRpcUrl, { getPolygonNodeUrl, getEthereumNodeUrl } from 'utils/getRpcUrl'

const RPC_URL =
  process.env.REACT_APP_BASE_CHAIN === 'BSC'
    ? getRpcUrl()
    : process.env.REACT_APP_BASE_CHAIN === 'POLYGON'
    ? getPolygonNodeUrl()
    : getEthereumNodeUrl()

export const simpleRpcProvider = new ethers.providers.JsonRpcProvider(RPC_URL)

const BSC_RPC_URL = getRpcUrl()
const POLYGON_RPC_URL = getPolygonNodeUrl()
const ETHEREUM_RPC_URL = getEthereumNodeUrl()

export const simpleBSCRpcProvider = new ethers.providers.JsonRpcProvider(BSC_RPC_URL)
export const simplePolygonRpcProvider = new ethers.providers.JsonRpcProvider(POLYGON_RPC_URL)
export const simpleEthereumRpcProvider = new ethers.providers.JsonRpcProvider(ETHEREUM_RPC_URL)

export default null
