import { Tier } from './types'

const tiers: Tier[] = [
  {
    id: 0,
    name: 'No Tier',
    lockDayDuration: 0,
    minAmount: 0,
    maxAmount: 0,
    plnBaseApr: 0,
    plnLockApr: 0,
    pccBaseApr: 0,
    pccLockApr: 0,
    tierImageSrc: '',
    tierIcon: '',
  },
  {
    id: 1,
    name: 'Silver',
    lockDayDuration: 30,
    minAmount: 1000,
    maxAmount: 9999,
    plnBaseApr: 0.5,
    plnLockApr: 0,
    pccBaseApr: 5,
    pccLockApr: 0,
    tierImageSrc: '/images/tier/New_Tier_Silver.png',
    tierIcon: '/images/tier/icon/silver_icon.png',
  },
  {
    id: 2,
    name: 'Gold',
    lockDayDuration: 90,
    minAmount: 10000,
    maxAmount: 49999,
    plnBaseApr: 0.5,
    plnLockApr: 2.0,
    pccBaseApr: 5,
    pccLockApr: 2.5,
    tierImageSrc: '/images/tier/New_Tier_Gold.png',
    tierIcon: '/images/tier/icon/gold_icon.png',
  },
  {
    id: 3,
    name: 'Platinum',
    lockDayDuration: 180,
    minAmount: 50000,
    maxAmount: 99999,
    plnBaseApr: 0.5,
    plnLockApr: 3.0,
    pccBaseApr: 5,
    pccLockApr: 8.5,
    tierImageSrc: '/images/tier/New_Tier_Platinum.png',
    tierIcon: '/images/tier/icon/platinum_icon.png',
  },
  {
    id: 4,
    name: 'Diamond',
    lockDayDuration: 360,
    minAmount: 100000,
    maxAmount: 700000000,
    plnBaseApr: 0.5,
    plnLockApr: 5.0,
    pccBaseApr: 5,
    pccLockApr: 17.5,
    tierImageSrc: '/images/tier/New_Tier_Diamond.png',
    tierIcon: '/images/tier/icon/diamond_icon.png',
  },
]

export default tiers
