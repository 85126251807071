import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { LuckyDrawTicket, LuckyDrawStatus, NUM_ROUNDS_TO_FETCH_FROM_NODES } from 'config/constants/luckydraw'
import luckyAbi from 'config/abi/lucky.json'
import { getLuckyAddressV2 } from 'utils/addressHelpers'
import { multicallv2 } from 'utils/multicall'
import { LuckyDrawRound, LuckyDrawRoundUserTickets, LuckyDrawNodeResponse, TicketNumberWithOwner } from 'state/types'
import { getLuckyContract, getLuckyContractV2 } from 'utils/contractHelpers'
import { useMemo } from 'react'
import { ethersToSerializedBigNumber } from 'utils/bigNumber'
import { LUCKY_ROUND_V2 } from 'config/constants/endpoints'

// const luckyContract = getLuckyContractV2()

const processGetLuckyInfoSuccessResponse = (response, luckyId: string): LuckyDrawNodeResponse => {
  const {
    status,
    startTime,
    endTime,
    priceTicketInPlearn,
    discountDivisor,
    maxTicketsPerRound,
    maxTicketsPerUser,
    countLuckyNumbersPerBracket,
    // countReservePerBracket,
    firstTicketId,
    lastTicketId,
    amountCollectedInPlearn,
    // enableReserve,
    luckyNumbers,
    // reserveNumbers
  } = response

  const statusKey = Object.keys(LuckyDrawStatus)[status]
  // const serializedCakePerBracket = []
  // const serializedCountWinnersPerBracket = []
  // const serializedRewardsBreakdown = []
  // const serializedLuckyNumbers = luckyNumbers.map((number) => ethersToBigNumber(number))

  // อันใหนที่เป็น BigNumber ต้องแปลงให้เป็น string ก่อน
  return {
    luckyId,
    isLoading: false,
    status: LuckyDrawStatus[statusKey],
    startTime: startTime?.toString(),
    endTime: endTime?.toString(),
    priceTicketInPlearn: ethersToSerializedBigNumber(priceTicketInPlearn),
    firstTicketId: ethersToSerializedBigNumber(firstTicketId),
    lastTicketId: ethersToSerializedBigNumber(lastTicketId),
    amountCollectedInPlearn: ethersToSerializedBigNumber(amountCollectedInPlearn),
    maxTicketsPerRound: ethersToSerializedBigNumber(maxTicketsPerRound),
    maxTicketsPerUser: ethersToSerializedBigNumber(maxTicketsPerUser),
    luckyNumbers,
    countLuckyNumbersPerBracket,
    // vvv ข้างล่างเป็นของเดิมจาก Pancake Lottery
    // treasuryFee: '0',
    // countReservePerBracket: countReservePerBracket?.toString(),
    // cakePerBracket: serializedCakePerBracket,
    // countWinnersPerBracket: serializedCountWinnersPerBracket,
    // rewardsBreakdown: serializedRewardsBreakdown,
  }
}

const processGetLuckyInfoErrorResponse = (luckyId: string): LuckyDrawNodeResponse => {
  return {
    luckyId,
    isLoading: true,
    status: LuckyDrawStatus.PENDING,
    startTime: '',
    endTime: '',
    priceTicketInPlearn: '',
    firstTicketId: '',
    lastTicketId: '',
    amountCollectedInPlearn: '',
    luckyNumbers: [],
    maxTicketsPerRound: '',
    maxTicketsPerUser: '',
    countLuckyNumbersPerBracket: [],
    // cakePerBracket: [],
    // countWinnersPerBracket: [],
    // rewardsBreakdown: [],
  }
}
export const fetchTicketId = async (luckyId: string, ticketNumber: string): Promise<string> => {
  const luckyContract = Number(luckyId) < Number(LUCKY_ROUND_V2) ? getLuckyContract() : getLuckyContractV2()
  const _ticketNumber = new BigNumber(ticketNumber)
  const startNumber = await luckyContract.startNumber()
  if (_ticketNumber < startNumber) {
    return ''
  }

  const { firstTicketId, lastTicketId } = await luckyContract.getLuckyInfo(luckyId)
  const ticketId = _ticketNumber.minus(startNumber).plus(firstTicketId)
  if (ticketId < firstTicketId || ticketId > lastTicketId) {
    return ''
  }
  return ticketId.toString()
}

export const fetchLuckyDraw = async (luckyId: string): Promise<LuckyDrawNodeResponse> => {
  try {
    const luckyContract = Number(luckyId) < Number(LUCKY_ROUND_V2) ? getLuckyContract() : getLuckyContractV2()
    const luckyDrawData = await luckyContract.getLuckyInfo(luckyId)
    const processedLuckyDrawData = processGetLuckyInfoSuccessResponse(luckyDrawData, luckyId)
    return processedLuckyDrawData
  } catch (error) {
    console.error(error)
    return processGetLuckyInfoErrorResponse(luckyId)
  }
}

// getLuckyTickets จะได้ ticket numbers ที่ได้รางวัล กับ wallet address ของ ticket's owners
export const fetchLuckyTicketsWithOwner = async (luckyId: string): Promise<TicketNumberWithOwner[]> => {
  try {
    const luckyContract = Number(luckyId) < Number(LUCKY_ROUND_V2) ? getLuckyContract() : getLuckyContractV2()
    const luckyTicketsRawData = await luckyContract.getLuckyTickets(luckyId)
    const mergedTicketAndWalletAddress = luckyTicketsRawData.map((ticket) => {
      return { ticketNumber: ticket.number, ticketOwner: ticket.owner }
    })
    return mergedTicketAndWalletAddress
  } catch (error) {
    return []
  }
}

export const fetchMultipleLucky = async (lucklyIds: string[]): Promise<LuckyDrawNodeResponse[]> => {
  const calls = lucklyIds.map((id) => ({
    name: 'getLuckyInfo',
    address: getLuckyAddressV2(),
    params: [id],
  }))
  try {
    const multicallRes = await multicallv2(luckyAbi, calls, { requireSuccess: false })
    const processedResponses = multicallRes.map((res, index) =>
      processGetLuckyInfoSuccessResponse(res[0], lucklyIds[index]),
    )
    return processedResponses
  } catch (error) {
    console.error(error)
    return calls.map((call, index) => processGetLuckyInfoErrorResponse(lucklyIds[index]))
  }
}

export const fetchCurrentLuckyIdAndMaxBuy = async () => {
  // console.debug('fetchCurrentLuckyIdAndMaxBuy')
  try {
    const calls = ['getCurrentLuckyId', 'maxNumberTicketsPerBuyOrClaim'].map((method) => ({
      address: getLuckyAddressV2(),
      name: method,
    }))
    const [[currentLuckyId], [maxNumberTicketsPerBuyOrClaim]] = (await multicallv2(
      luckyAbi,
      calls,
    )) as ethers.BigNumber[][]
    return {
      currentLuckyId: currentLuckyId ? currentLuckyId.toString() : null,
      maxNumberTicketsPerBuyOrClaim: maxNumberTicketsPerBuyOrClaim ? maxNumberTicketsPerBuyOrClaim.toString() : null,
    }
  } catch (error) {
    return {
      currentLuckyId: null,
      maxNumberTicketsPerBuyOrClaim: null,
    }
  }
}

export const getRoundIdsArray = (currentLuckyId: string): string[] => {
  const currentIdAsInt = parseInt(currentLuckyId, 10)
  const roundIds = []
  for (let i = 0; i < NUM_ROUNDS_TO_FETCH_FROM_NODES; i++) {
    const roundId = currentIdAsInt - i
    if (roundId > 0) roundIds.push(currentIdAsInt - i)
  }
  return roundIds.map((roundId) => roundId.toString())
}

export const useProcessLotteryResponse = (
  luckyDrawData: LuckyDrawNodeResponse & {
    userTickets?: LuckyDrawRoundUserTickets
  },
): LuckyDrawRound => {
  const {
    priceTicketInPlearn: priceTicketInPlearnAsString,
    amountCollectedInPlearn: amountCollectedInPlearnAsString,
    maxTicketsPerRound: maxTicketsPerRoundAsString,
    maxTicketsPerUser: maxTicketsPerUserAsString,
  } = luckyDrawData

  const priceTicketInPlearn = useMemo(() => {
    return new BigNumber(priceTicketInPlearnAsString)
  }, [priceTicketInPlearnAsString])

  const amountCollectedInPlearn = useMemo(() => {
    return new BigNumber(amountCollectedInPlearnAsString)
  }, [amountCollectedInPlearnAsString])

  const maxTicketsPerRound = useMemo(() => {
    return new BigNumber(maxTicketsPerRoundAsString)
  }, [maxTicketsPerRoundAsString])

  const maxTicketsPerUser = useMemo(() => {
    return new BigNumber(maxTicketsPerUserAsString)
  }, [maxTicketsPerUserAsString])

  return {
    isLoading: luckyDrawData.isLoading,
    luckyId: luckyDrawData.luckyId,
    userTickets: luckyDrawData.userTickets,
    status: luckyDrawData.status,
    startTime: luckyDrawData.startTime,
    endTime: luckyDrawData.endTime,
    priceTicketInPlearn,
    maxTicketsPerRound,
    maxTicketsPerUser,
    amountCollectedInPlearn,
    firstTicketId: luckyDrawData.firstTicketId,
    lastTicketId: luckyDrawData.lastTicketId,
    luckyNumbers: luckyDrawData.luckyNumbers,
    countLuckyNumbersPerBracket: luckyDrawData.countLuckyNumbersPerBracket,
  }
}

export const hasRoundBeenClaimed = (tickets: LuckyDrawTicket[]): boolean => {
  const claimedTickets = tickets.filter((ticket) => ticket.isLucky)
  return claimedTickets.length > 0
}
