import { Flex, Text, Button } from '@plearn/uikit'
import React, { useState } from 'react'
import styled from 'styled-components'
import { TableWrapper } from '../shared'

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  overflow-x: auto;
  padding: 0 24px;
`

interface TierGridProps {
  isCurrentTier: boolean
}

const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: 120px;
  grid-template-rows: repeat(6, auto);
  grid-gap: 1em;
  padding: 1em;
  border-radius: 12px;
  color: white;
`
const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const TierGrid = styled.div<TierGridProps>`
  display: grid;
  grid-template-columns: 120px;
  grid-template-rows: repeat(6, auto);
  grid-gap: 1em;
  /* align-items: center; */
  justify-content: center;
  padding: 1em;
  border-radius: 12px;
  color: white;
  border: ${(props) => (props.isCurrentTier ? '1px solid var(--Coffee, #a8884a)' : 'none')};
  background: ${(props) => (props.isCurrentTier ? 'var(--Dark-Grey, #433a30)' : 'none')};
`

const TierColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Wrapper = styled.div`
  text-decoration: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

export const BulletList = styled.ul<{ color: string }>`
  list-style-type: none;
  margin-left: 8px;
  padding: 0;
  li {
    margin-top: 2px;
    padding: 0;
  }
  li::before {
    content: '•';
    font-size: 28px;
    vertical-align: sub;
    margin-right: 8px;
    color: ${({ color }) => color};
  }
  li::marker {
    font-size: 14px;
  }
`

const StyledText = styled(Text)`
  /* line-height: 20px; */
  /* margin-bottom: 3px; */
  margin-left: 25px;
  margin-right: 25px;
  /* margin-right: auto; */
  /* max-width: 1000px; */
`

// Define a styled component specifically for section 2.1
const StyledSubsection = styled(StyledText)`
  padding-left: 10px;
  margin-bottom: 5px;
`
export const RulesConditionENDetails: React.FC = () => {
  return (
    <TableWrapper>
      <StyledText>
        1. Users can become members with two conditions: Minimum number of PLN required to be staked and Minimum locked
        staking duration.
      </StyledText>
      <StyledText>
        2. Users are allowed to add PLN into the Member Pool with the following details:
        <StyledSubsection>
          2.1. Add PLN into member pool - Current Tier
          <StyledSubsection>
            2.1.1. Users are allowed to add additional PLN during the locked staking period (not yet finished) into the
            member pool and the locked staking duration will not be affected or reset
          </StyledSubsection>
          <StyledSubsection>
            2.1.2. Add additional PLN into the member pool after the locked staking period has finished, the locked
            staking duration will be reset
          </StyledSubsection>
        </StyledSubsection>
        <StyledSubsection>
          2.2. Upgrade Tier
          <StyledSubsection>
            2.2.1. Users are allowed to upgrade their current tier to another by adding PLN to match the Minimum PLN and
            Locked Staking Duration will not reset but locked duration will be added.
          </StyledSubsection>
          <StyledSubsection>
            2.2.2. If the user upgrades their tier after the locked staking period is finished. The locked staking
            duration will be reset
          </StyledSubsection>
        </StyledSubsection>
      </StyledText>

      <StyledText>
        3. The counting of the locking period starts at 19:00 (GMT+07:00) on the day after the Locked Staking of PLN.
        For example:
        <StyledSubsection>
          3.1 If PLN coins are deposited in the silver tier before 19:00 (GMT+07:00) on January 1, 2024, they will
          unlock on January 31, 2024, at 19:00 (GMT+07:00).
        </StyledSubsection>
        <StyledSubsection>
          3.2 If PLN coins are deposited in the silver tier after 19:00 (GMT+07:00) on January 1, 2024, they will unlock
          on February 1, 2024, at 19:00 (GMT+07:00).
        </StyledSubsection>
      </StyledText>
      <StyledText>
        4. Maintaining Tier
        <StyledSubsection>
          4.1. After the locked staking duration has finished, users can continue locking stakes or unstake PLN from the
          Member pool. if the user unstakes PLN from the Member Pool below the Minimum PLN specified in that tier, The
          user is tier will be downgraded to the tier where the amount of PLN staked is more than the Minimum PLN in
          that tier.
        </StyledSubsection>
        <StyledSubsection>
          4.2. After the locked staking duration has finished, PLN Yield APR will be 0.5% and PCC Yield will be 5% and
          users will still receive the same tier/benefits as before.
        </StyledSubsection>
      </StyledText>
      <StyledText>
        5. Reward Distribution
        <StyledSubsection>
          5.1. The staking rewards are distributed daily from 19:00 (GMT+07:00) on the day after the funds are deposited
          into the staking pool
        </StyledSubsection>
        <StyledSubsection>
          5.2. Rewards are calculated based on the number of days staked using a calculation based on a year being 365
          days.
        </StyledSubsection>
        <StyledSubsection>5.3. Rewards are distributed daily according to the daily APR rate.</StyledSubsection>
      </StyledText>
      <StyledText>
        6. PCC Yield calculated based on 100 PLN per year. For example, PCC Yield = 22.5% and 100,000 PLN the result
        will be 100,000 x 22.5% = 22,500 PCC as rewarded
      </StyledText>
      <Flex height="20px" />
    </TableWrapper>
  )
}

const RulesConditionTHDetails: React.FC = () => {
  return (
    <TableWrapper>
      <StyledText>
        1. ผู้ใช้งานสามารถเลือกเป็นระดับสมาชิกได้โดยต้องมี 2 เงื่อนไข คือ จำนวนเหรียญ PLN ขั้นต่ำ และ
        ระยะเวลาขั้นต่ำในการ Locked Staking
      </StyledText>
      <StyledText>
        2. ผู้ใช้งานสามารถฝากเหรียญ PLN เพิ่มใน Member Pool ได้โดยมีเงื่อนไขดังนี้
        <StyledSubsection>
          2.1. การฝากเพิ่มใน Tier เดิม
          <StyledSubsection>
            2.1.1. การฝากเพิ่มในขณะที่ยังไม่หมดระยะเวลาล็อก ระยะเวลาล็อกจะไม่ถูกนับใหม่
          </StyledSubsection>
          <StyledSubsection>
            2.1.2. การฝากเพิ่มหลังจากระยะเวลาล็อกของการฝากเดิมครบ ระบบจะเริ่มนับระยะเวลาล็อกใหม่
          </StyledSubsection>
        </StyledSubsection>
        <StyledSubsection>
          2.2. การเพิ่มระดับ Tier (Upgrade Tier)
          <StyledSubsection>
            2.2.1. การฝากเหรียญเพิ่ม เพื่อเพิ่มระดับ จะไม่นับเวลาล็อคใหม่ เช่น หากฝากเงินใน Tier Silver 30 วัน และทำการ
            Upgrade ไป Tier Gold 90 วัน ภายในระยะเวลาล็อก, ระยะเวลาล็อกจะนับต่อไปจนครบ 90 วัน โดยไม่เริ่มนับใหม่
          </StyledSubsection>
          <StyledSubsection>
            2.2.2. หากทำการ Upgrade Tier หลังจากระยะเวลาล็อกของ Tier นั้นหมดลง ระบบจะเริ่มนับระยะเวลาล็อกใหม่
          </StyledSubsection>
        </StyledSubsection>
      </StyledText>

      <StyledText>
        3. การนับระยะเวลาล็อกจะเริ่มนับตั้งแต่เวลา 19:00 (GMT+07:00) ของวันถัดไปหลังจากทำการ Locked Staking PLN ตัวอย่าง
        <StyledSubsection>
          3.1 หากฝากเหรียญที่ tier silver ในวันที่ 1 มกราคม 2024 ก่อนเวลา 19:00 (GMT+07:00) จะปลดล็อกวันที่ 31 มกราคม
          2024 เวลา 19:00 (GMT+07:00)
        </StyledSubsection>
        <StyledSubsection>
          3.2 หากฝากเหรียญที่ tier silver ในวันที่ 1 มกราคม 2024 หลังเวลา 19:00 (GMT+07:00) จะปลดล็อกวันที่ 1 กุมภาพันธ์
          2024 เวลา 19:00 (GMT+07:00)
        </StyledSubsection>
      </StyledText>
      <StyledText>
        4. การปรับเปลี่ยน Tier
        <StyledSubsection>
          4.1. หลังจากระยะเวลาการล็อคได้สิ้นสุดลง ผู้ใช้งานสามารถทำการล็อค PLN ต่อได้ และผู้ใช้งานสามารถถอนเหรียญ PLN
          ออกจาก Member Pool ได้ แต่ถ้าหากถอน PLN ต่ำกว่า Minimum PLN ที่กำหนดใน Tier นั้น ๆ จะถูกปรับลด Tier ไปยัง Tier
          ที่จำนวน PLN ที่ถูกฝากมากกว่าเท่ากับ Minimum PLN ใน tier นั้นๆ
        </StyledSubsection>
        <StyledSubsection>
          4.2. หลังจากระยะเวลาการล็อคได้สิ้นสุดลง, PLN Yield APR จะเป็น 0.5% และ PCC Yield จะเป็น 5% แต่จะยังได้รับ
          Tier/Benefits ตามเดิม
        </StyledSubsection>
      </StyledText>
      <StyledText>
        5. การแจก Reward
        <StyledSubsection>
          5.1. Rewards เริ่มต้นจะได้รับตั้งแต่เวลา 19:00 (GMT+07:00) ของวันถัดไปหลังจากทำการฝากเหรียญ
        </StyledSubsection>
        <StyledSubsection>
          5.2. Rewards จะคำนวณตามจำนวนวันที่ฝากโดยใช้การคำนวณอ้างอิงว่าหนึ่งปีมี 365 วัน
        </StyledSubsection>
        <StyledSubsection>5.3. รางวัลจะได้รับทุกวันตามอัตรา APR รายวัน</StyledSubsection>
      </StyledText>
      <StyledText>
        6. PCC Yield เป็นการคำนวณจำนวน PCC ที่ได้รับต่อการฝาก 100 PLN ต่อปีในแต่ละระดับสมาชิก ยกตัวอย่างเช่น PCC Yield =
        22.5% หากจำนวนที่ 100,000 PLN จะได้ดังนี้ 100,000 x 22.5% = 22,500 PCC ที่จะได้รับ
      </StyledText>
      <Flex height="20px" />
      {/* <RulesConditionENDetails /> */}
    </TableWrapper>
  )
}

const RulesConditionDetails: React.FC = () => {
  const [isDetailEn, setIsDetailEn] = useState(false)
  const toggleSwitchDetailsEN = () => {
    setIsDetailEn(!isDetailEn)
  }
  return (
    <TableWrapper>
      <Flex justifyContent="flex-end" mt="5px">
        <Button
          height="auto"
          scale="sm"
          onClick={toggleSwitchDetailsEN}
          variant="text"
          style={{ textDecoration: 'underline' }}
        >
          {isDetailEn ? 'ไทย' : 'English'}
        </Button>
      </Flex>

      {isDetailEn ? <RulesConditionENDetails /> : <RulesConditionTHDetails />}
    </TableWrapper>
  )
}

export default RulesConditionDetails
