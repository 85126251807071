import React from 'react'
import { Flex, Text } from '@plearn/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import { getPoolBlockInfo } from 'views/Pools/helpers'
import { DeserializedPool } from 'state/types'
import { useBlock } from 'state/block/hooks'
import BaseCell, { CellContent } from './BaseCell'

interface TotalRewardsCellProps {
  pool: DeserializedPool
}

const StyledCell = styled(BaseCell)`
  flex: 2 0 100px;
`

const TotalRewardsCell: React.FC<TotalRewardsCellProps> = ({ pool }) => {
  const { t } = useTranslation()
  const { currentBlock } = useBlock()
  const { sousId, earningToken, totalRewards, endBlock } = pool

  const { blocksToDisplay } =
    getPoolBlockInfo(pool, currentBlock)

  const isManualCakePool = sousId === 0

  const totalRewardsBalance = blocksToDisplay * Number(pool.tokenPerBlock)

  // const totalRewardsBalance = useMemo(() => {
  //   return getBalanceNumber(totalRewards, earningToken.decimals)
  // }, [totalRewards, earningToken.decimals])

  return (
    <StyledCell role="cell">
      <CellContent>
        <Text fontSize="12px" color="textSubtle" textAlign="left">
          {t('Total PLN rewards')}
        </Text>
        {totalRewards && totalRewards.gte(0) ? (
          <Flex height="20px" alignItems="center">
            <Balance fontSize="16px" value={totalRewardsBalance} decimals={0} unit={`/403,200 ${earningToken.symbol}`} />
          </Flex>
        ) : (
          <Flex height="20px" alignItems="center">
            <Balance fontSize="16px" value={0} decimals={0} unit={`/403,200 ${earningToken.symbol}`} />
          </Flex>
        )}
      </CellContent>
    </StyledCell>
  )
}

export default TotalRewardsCell
