/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LuckyDrawTicket, LuckyDrawStatus } from 'config/constants/luckydraw'
import { LotteryState, LuckyDrawRoundGraphEntity, LotteryUserGraphEntity, LuckyDrawNodeResponse } from 'state/types'
import { fetchLuckyDraw, fetchCurrentLuckyIdAndMaxBuy } from './helpers'
import getLotteriesData from './getLotteriesData'
import getUserLuckyData, { getGraphLotteryUser } from './getUserLotteryData'

interface PublicLuckyDrawData {
  currentLuckyId: string
  maxNumberTicketsPerBuyOrClaim: string
}

const initialState: LotteryState = {
  currentLuckyId: null,
  isTransitioning: false,
  maxNumberTicketsPerBuyOrClaim: null,
  currentRound: {
    isLoading: true,
    luckyId: null,
    status: LuckyDrawStatus.PENDING,
    startTime: '',
    endTime: '',
    priceTicketInPlearn: '',
    countLuckyNumbersPerBracket: [],
    maxTicketsPerRound: '',
    maxTicketsPerUser: '',
    firstTicketId: '',
    lastTicketId: '',
    amountCollectedInPlearn: '',
    luckyNumbers: [],
    // cakePerBracket: [],
    // countWinnersPerBracket: [],
    // rewardsBreakdown: [],
    userTickets: {
      isLoading: true,
      tickets: [],
    },
    brackets: null,
  },
  lotteriesData: null,
  userLotteryData: { account: '', totalPlearn: '', totalTickets: '', rounds: [] },
}

export const fetchCurrentLucky = createAsyncThunk<LuckyDrawNodeResponse, { currentLuckyId: string }>(
  'lottery/fetchCurrentLucky',
  async ({ currentLuckyId }) => {
    const currentLuckyInfo = await fetchLuckyDraw(currentLuckyId)
    return currentLuckyInfo
  },
)

export const fetchCurrentLuckyId = createAsyncThunk<PublicLuckyDrawData>('lottery/fetchCurrentLuckyId', async () => {
  // จะไปเรียก smart contract 2 function, จะได้ response แค่ Id ของ round ล่าสุดกับ Max buy
  const currentIdAndMaxBuy = await fetchCurrentLuckyIdAndMaxBuy()
  return currentIdAndMaxBuy
})

export const fetchUserTicketsAndLotteries = createAsyncThunk<
  { userTickets: LuckyDrawTicket[]; userLotteries: LotteryUserGraphEntity },
  { account: string; currentLuckyId: string }
>('lottery/fetchUserTicketsAndLotteries', async ({ account, currentLuckyId }) => {
  // console.debug('fetchUserTicketsAndLotteries', account, currentLuckyId)
  // เช็คว่า account นี้ มีส่วนร่วมใน luckyId นี้หรือไม่
  const userLuckyRes = await getUserLuckyData(account, currentLuckyId)
  const userLuckyInCurrentRound = userLuckyRes.rounds?.find((round) => round.luckyId === currentLuckyId)
  const userTickets = userLuckyInCurrentRound?.tickets

  // User has not bought tickets for the current lottery, or there has been an error
  if (!userTickets || userTickets.length === 0) {
    return { userTickets: null, userLotteries: userLuckyRes }
  }

  return { userTickets, userLotteries: userLuckyRes }
})

export const fetchPublicLuckiesFromGraph = createAsyncThunk<LuckyDrawRoundGraphEntity[], { currentLuckyId: string }>(
  'lottery/fetchPublicLuckies',
  async ({ currentLuckyId }) => {
    const lotteries = await getLotteriesData(currentLuckyId)
    return lotteries
  },
)

export const fetchUserLuckiesFromGraph = createAsyncThunk<
  LotteryUserGraphEntity,
  { account: string; currentLuckyId: string }
>('lottery/fetchUserLuckies', async ({ account, currentLuckyId }) => {
  const userLuckies = await getUserLuckyData(account, currentLuckyId)
  return userLuckies
})

export const fetchAdditionalUserLotteries = createAsyncThunk<
  LotteryUserGraphEntity,
  { account: string; skip?: number }
>('lottery/fetchAdditionalUserLotteries', async ({ account, skip }) => {
  const additionalUserLotteries = await getGraphLotteryUser(account, undefined, skip)
  return additionalUserLotteries
})

export const setLotteryIsTransitioning = createAsyncThunk<{ isTransitioning: boolean }, { isTransitioning: boolean }>(
  `lottery/setIsTransitioning`,
  async ({ isTransitioning }) => {
    return { isTransitioning }
  },
)

export const LotterySlice = createSlice({
  name: 'Lottery',
  initialState,
  reducers: {
    setLotteryPublicData: (state, action) => {
      state = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentLucky.fulfilled, (state, action: PayloadAction<LuckyDrawNodeResponse>) => {
      state.currentRound = { ...state.currentRound, ...action.payload }
    })
    builder.addCase(fetchCurrentLuckyId.fulfilled, (state, action: PayloadAction<PublicLuckyDrawData>) => {
      state.currentLuckyId = action.payload.currentLuckyId
      state.maxNumberTicketsPerBuyOrClaim = action.payload.maxNumberTicketsPerBuyOrClaim
    })
    builder.addCase(
      fetchUserTicketsAndLotteries.fulfilled,
      (state, action: PayloadAction<{ userTickets: LuckyDrawTicket[]; userLotteries: LotteryUserGraphEntity }>) => {
        // console.debug('...payload...', action.payload)
        state.currentRound.userTickets.isLoading = false
        state.currentRound.userTickets.tickets = action.payload.userTickets
        state.userLotteryData = action.payload.userLotteries
      },
    )
    builder.addCase(
      fetchPublicLuckiesFromGraph.fulfilled,
      (state, action: PayloadAction<LuckyDrawRoundGraphEntity[]>) => {
        state.lotteriesData = action.payload
      },
    )
    builder.addCase(fetchUserLuckiesFromGraph.fulfilled, (state, action: PayloadAction<LotteryUserGraphEntity>) => {
      state.userLotteryData = action.payload
    })
    builder.addCase(fetchAdditionalUserLotteries.fulfilled, (state, action: PayloadAction<LotteryUserGraphEntity>) => {
      console.debug('***fetchAdditionalUserLotteries')
      const mergedRounds = [...state.userLotteryData.rounds, ...action.payload.rounds]
      state.userLotteryData.rounds = mergedRounds
    })
    builder.addCase(
      setLotteryIsTransitioning.fulfilled,
      (state, action: PayloadAction<{ isTransitioning: boolean }>) => {
        state.isTransitioning = action.payload.isTransitioning
      },
    )
  },
})

// Actions
export const { setLotteryPublicData } = LotterySlice.actions

export default LotterySlice.reducer
