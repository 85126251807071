import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import poolConfig from 'config/constants/memberPool'
import { MemberPoolState, SerializedMemberPool } from 'state/types'
import {
  fetchMemberPoolDepositEnabled,
  fetchMemberPoolTotalStaking,
  fetchMemberPoolUserAllowances,
  fetchMemberPoolUserTokenBalances,
  fetchUserInfo,
} from './fetchMemberPoolUser'

const initialState: MemberPoolState = {
  data: poolConfig,
  userDataLoaded: false,
}

interface MemberPoolUserDataResponse {
  depositEnabled: boolean
  totalStaked: string
  userData: {
    allowance: string
    stakingTokenBalance: string
    stakedBalance: string
    pendingPlnReward: string
    pendingPccReward: string
    endLockTime: string
    depositStartDay: string
    tierIndex: string
  }
}

export const fetchMemberPoolPublicDataAsync = createAsyncThunk<SerializedMemberPool>(
  'memberPool/fetchMemberPoolPublicDataAsync',
  async () => {
    return poolConfig
  },
)

export const fetchMemberPoolUserDataAsync = createAsyncThunk<MemberPoolUserDataResponse, { account: string }>(
  'memberPool/fetchMemberPoolUserDataAsync',
  async ({ account }) => {
    const userMemberPoolAllowance = await fetchMemberPoolUserAllowances(account)
    const userMemberPoolTokenBalance = await fetchMemberPoolUserTokenBalances(account)
    const userInfo = await fetchUserInfo(account)
    const totalStaked = await fetchMemberPoolTotalStaking()
    const depositEnabled = await fetchMemberPoolDepositEnabled()

    return {
      depositEnabled,
      totalStaked,
      userData: {
        allowance: userMemberPoolAllowance,
        stakingTokenBalance: userMemberPoolTokenBalance,
        stakedBalance: userInfo.stakedBalance,
        pendingPlnReward: userInfo.plnEarning,
        pendingPccReward: userInfo.pccEarning,
        endLockTime: userInfo.endLockTime,
        depositStartDay: userInfo.depositStartDay,
        tierIndex: userInfo.tierIndex,
      },
    }
  },
)

export const memberPoolSlice = createSlice({
  name: 'MemberPool',
  initialState,
  reducers: {
    setMemberPoolUserData: (state, action) => {
      const userData = action.payload
      state.data = userData
      state.userDataLoaded = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMemberPoolUserDataAsync.fulfilled, (state, action) => {
      const { depositEnabled, totalStaked, userData } = action.payload
      state.data.depositEnabled = depositEnabled
      state.data.totalStaked = totalStaked
      state.data.userData = userData
      state.userDataLoaded = true
    })
  },
})

// Actions
export const { setMemberPoolUserData } = memberPoolSlice.actions

export default memberPoolSlice.reducer
