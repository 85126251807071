import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMatchBreakpoints, Text, Heading, WalletFilledIcon, Flex, ChevronDownIcon } from '@plearn/uikit'
import { useTranslation } from 'contexts/Localization'
import useDelayedUnmount from 'hooks/useDelayedUnmount'
import { AssetsData } from 'state/myInvestments/types'
import { AutoRow } from 'components/Layout/Row'
import { formatAmount } from 'views/Info/utils/formatInfoNumbers'
import { DeserializedMemberPool } from 'state/types'
import TierBenefitsTable from './TierBenefitsTable'

const Wrapper = styled.div`
  padding: 24px 0px;
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 8px;
  background-color: rgba(122, 103, 64, 0.2);
  border-radius: 10px 10px 0px 0px;

  ${({ theme }) => theme.mediaQueries.xl} {
    padding-right: 32px;
  }
`

const CellInner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  background-color: ${({ theme }) => theme.card.background};
  border-radius: 10px 10px 0px 0px;
`

const ArrowIcon = styled(ChevronDownIcon)<{ toggled: boolean }>`
  transform: ${({ toggled }) => (toggled ? 'rotate(180deg)' : 'rotate(0)')};
  height: 24px;
`

const StyledTr = styled.tr`
  cursor: pointer;
`

interface TierBenefitsProps {
  tierIndex: number
  isMember: boolean
  pool: DeserializedMemberPool
  onUpgrade?: () => void
}

const TierBenefits: React.FunctionComponent<TierBenefitsProps> = ({ tierIndex, isMember, pool }) => {
  const isExpand = true
  const [actionPanelExpanded, setActionPanelExpanded] = useState(isExpand)
  const shouldRenderChild = useDelayedUnmount(actionPanelExpanded, 300)
  const { t } = useTranslation()

  const toggleActionPanel = () => {
    setActionPanelExpanded(!actionPanelExpanded)
  }

  useEffect(() => {
    setActionPanelExpanded(isExpand)
  }, [isExpand])

  const { isDesktop, isMobile } = useMatchBreakpoints()

  const isSmallerScreen = !isDesktop

  const handleRenderRow = () => {
    return (
      <StyledTr onClick={toggleActionPanel}>
        <td key="Member Pool">
          <CellInner>
            <Wrapper>
              <AutoRow style={{ justifyContent: 'space-between' }}>
                <Flex>
                  <Text bold fontSize="20px" display="inline" ml="24px">
                    {t('Member Pool')}
                  </Text>
                </Flex>
                <Flex>
                  <ArrowIcon color="primary" toggled={actionPanelExpanded} />
                </Flex>
              </AutoRow>
            </Wrapper>
          </CellInner>
        </td>
      </StyledTr>
    )
  }

  return (
    <>
      {handleRenderRow()}
      {shouldRenderChild && (
        <tr>
          <TierBenefitsTable currentTierIndex={tierIndex} isMember={isMember} pool={pool} />
        </tr>
      )}
    </>
  )
}

export default TierBenefits
