import sample from 'lodash/sample'

if (
  process.env.REACT_APP_BASE_ENVIRONMENT.toLowerCase() === 'testnet' &&
  (!process.env.REACT_APP_NODE_1 || !process.env.REACT_APP_NODE_2 || !process.env.REACT_APP_NODE_3)
) {
  throw Error('One base RPC URL is undefined for Testnet environment')
}

// Array of available nodes to connect to
export const nodes = [process.env.REACT_APP_NODE_1, process.env.REACT_APP_NODE_2, process.env.REACT_APP_NODE_3]

const getNodeUrl = () => {
  // Use custom node if available (both for development and production)
  // However on the testnet it wouldn't work, so if on testnet - comment out the REACT_APP_NODE_PRODUCTION from env file
  if (process.env.REACT_APP_BASE_ENVIRONMENT.toLowerCase() === 'mainnet') {
    console.debug('Using production (mainnet) node.')
    return process.env.REACT_APP_NODE_PRODUCTION
  }
  const node = sample(nodes)
  console.debug('Using testnet node:', node)
  return node
}

export const polygonNodes = [
  process.env.REACT_APP_POLYGON_NODE_1,
  process.env.REACT_APP_POLYGON_NODE_2,
  process.env.REACT_APP_POLYGON_NODE_3,
]

export const getPolygonNodeUrl = () => {
  // Use custom node if available (both for development and production)
  // However on the testnet it wouldn't work, so if on testnet - comment out the REACT_APP_NODE_PRODUCTION from env file
  if (process.env.REACT_APP_BASE_ENVIRONMENT.toLowerCase() === 'mainnet') {
    console.debug('Using production (mainnet) node.')
    return process.env.REACT_APP_POLYGON_NODE_PRODUCTION
  }
  const node = sample(polygonNodes)
  console.debug('Using testnet node:', node)
  return node
}

export const ethereumNodes = [
  process.env.REACT_APP_ETHEREUM_NODE_1,
  process.env.REACT_APP_ETHEREUM_NODE_2,
  process.env.REACT_APP_ETHEREUM_NODE_3,
]

export const getEthereumNodeUrl = () => {
  // Use custom node if available (both for development and production)
  // However on the testnet it wouldn't work, so if on testnet - comment out the REACT_APP_NODE_PRODUCTION from env file
  if (process.env.REACT_APP_BASE_ENVIRONMENT.toLowerCase() === 'mainnet') {
    console.debug('Using production (mainnet) node.')
    return process.env.REACT_APP_ETHEREUM_NODE_PRODUCTION
  }
  const node = sample(ethereumNodes)
  console.debug('Using testnet node:', node)
  return node
}

export default getNodeUrl
