import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import tokens from 'config/constants/tokens'
import { getDefinixBusdSixLpAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'

const getTotalBalanceLp = async ({ lpAddress, pair1, pair2}) => {
    let pair1Amount = 0
    let pair2Amount = 0
    try {
      const calls = [
        {
          address: pair1,
          name: 'balanceOf',
          params: [lpAddress],
        },
        {
          address: pair2,
          name: 'balanceOf',
          params: [lpAddress],
        },
        {
          address: pair1,
          name: 'decimals',
        },
        {
          address: pair2,
          name: 'decimals',
        },
      ]
  
      const [pair1BalanceLP, pair2BalanceLP, pair1Decimals, pair2Decimals] = await multicall(erc20, calls)
  
      pair1Amount = new BigNumber(pair1BalanceLP).div(new BigNumber(10).pow(pair1Decimals)).toNumber()
      pair2Amount = new BigNumber(pair2BalanceLP).div(new BigNumber(10).pow(pair2Decimals)).toNumber()
    } catch (error) {
      console.log(error)
    }
    return [pair1Amount, pair2Amount]
  }

  const fetchDefinixSixPrice = async () => {
    const fetchPromise = []
    fetchPromise.push(
      getTotalBalanceLp({
        lpAddress: getDefinixBusdSixLpAddress(),
        pair1: tokens.six.address,
        pair2: tokens.busd.address,
      }),
    )
    const [[totalSixInSixBusdPair, totalBusdInSixBusdPair]] = await Promise.all(fetchPromise)
    const SixBusdRatio = totalBusdInSixBusdPair / totalSixInSixBusdPair || 0
    return SixBusdRatio
  }

  export default fetchDefinixSixPrice