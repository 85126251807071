import collections from 'config/constants/nftsCollections'
import { CollectionKey } from 'config/constants/nftsCollections/types'
import { getAddress } from 'utils/addressHelpers'
import { ChainName } from 'config/constants/types'

// export const NFT_MINT_PAGE_ROUND_ID = 1
export const NFT_MINT_PAGE_ROUND_ID = (collectionAddress: string) => {
  if (collectionAddress === getAddress(collections[CollectionKey.APE].address)) {
    return Number(collections[CollectionKey.APE].roundId) ?? -1
  }

  if (collectionAddress === getAddress(collections[CollectionKey.WHISKEY].address)) {
    return Number(collections[CollectionKey.WHISKEY].roundId) ?? -1
  }

  return -1
}
export const APE_WHISKY_SET_BUNDLE = [
  365, 364, 363, 362, 361, 323, 322, 321, 320, 319, 140, 133, 126, 119, 112, 105, 98, 91, 84, 77, 70, 63, 56, 49, 42,
  35, 28, 21, 14, 7, 139, 136, 132, 129, 125, 122, 118, 115, 111, 108, 104, 101, 97, 94, 90, 87, 83, 80, 76, 73, 69, 66,
  62, 59, 55, 52, 48, 45, 41, 38, 34, 31, 27, 24, 20, 17, 13, 10, 6, 3, 358, 356, 355, 354, 353, 352, 350, 347, 346,
  344, 343, 342, 340, 338, 337, 334, 333, 330, 329, 328, 318, 317, 316, 314, 313, 311, 309, 307, 304, 303, 299, 298,
  297, 296, 295, 294, 292, 291, 290, 289, 287, 286, 284, 281, 280, 279, 278, 276, 275, 274, 272, 271, 269, 267, 266,
  264, 262, 259, 257, 256, 254, 253, 251, 250, 245, 244, 241, 240, 239, 237, 234, 233, 232, 231, 230, 229, 225, 222,
  221, 219, 218, 217, 213, 212, 210, 209, 208, 207, 206, 204, 203, 202, 201, 200, 199, 196, 191, 189, 188, 186, 184,
  183, 182, 179, 178, 177, 176, 171, 170, 167, 163, 162, 161, 160, 159, 158, 157, 156, 152, 151, 150, 149, 148, 147,
  145, 135, 134, 131, 130, 124, 123, 120, 116, 114, 113, 110, 109, 107, 106, 103, 102, 96, 95, 93, 89, 88, 86, 78, 68,
  64, 58, 57, 54, 53, 50, 44, 43, 39, 37, 36, 33, 32, 30, 26, 25, 23, 19, 18, 9, 4,
]

export const APE_AIRDROP = [
  192, 223, 99, 121, 360, 242, 248, 138, 82, 47, 216, 71, 359, 166, 214, 228, 235, 187, 236, 270, 190, 326, 247, 185,
  75, 2, 51, 341, 348, 198, 255, 181, 194, 258, 306, 40, 273, 173, 46, 205, 246, 174, 72, 224, 277, 315, 79, 153, 308,
  325, 169, 168, 249, 5, 60, 100, 243, 327, 172, 332, 127, 288, 155, 61, 137, 293, 339, 29, 335, 16, 305, 336, 283, 260,
  117, 302, 74, 282, 22, 197, 331, 81, 261, 128, 141, 65, 154, 226, 15, 142, 300, 175, 146, 351, 165, 252, 92, 12, 285,
  164, 263, 8, 310, 324, 345, 227, 357, 144, 220, 1, 85, 349, 211,
]
export const NFT_PHASE1_TIMESTAMP = process.env.REACT_APP_NFT_PHASE1_TIMESTAMP
export const NFT_PHASE1_OPEN = new Date() > new Date(Number(NFT_PHASE1_TIMESTAMP))
export const nftsBaseUrl = '/nfts'
export const pancakeBunniesAddress = getAddress(collections[CollectionKey.PANCAKE].address)
export const pancakeSquadAddress = getAddress(collections[CollectionKey.SQUAD].address)
export const plearnApeAddress = getAddress(collections[CollectionKey.APE].address, ChainName.BSC)
export const plearnWhiskeyAddress = getAddress(collections[CollectionKey.WHISKEY].address, ChainName.BSC)
export const plearnWhiskeyRoundId = collections[CollectionKey.WHISKEY].roundId
