import { AutoRenewIcon, Button, Flex, Image, Modal, Text, useMatchBreakpoints } from '@plearn/uikit'
import BigNumber from 'bignumber.js'
import { AutoRow } from 'components/Layout/Row'
import tiers from 'config/constants/tiers'
import { useTranslation } from 'contexts/Localization'
import { format } from 'date-fns'
import useTheme from 'hooks/useTheme'
import useToast from 'hooks/useToast'
import React, { useMemo, useState } from 'react'
import { DeserializedMemberPool } from 'state/types'
import { formatNumber, getBalanceNumber } from 'utils/formatBalance'
import useExtendLockPeriod from '../../hooks/useExtendLockPeriod'

interface ExtendLockPeriodModalProps {
  pool: DeserializedMemberPool
  onDismiss?: () => void
}

const ExtendLockPeriodModal: React.FC<ExtendLockPeriodModalProps> = ({ pool, onDismiss }) => {
  const { stakingToken, userData, earningPlnToken } = pool
  const { t } = useTranslation()
  const { theme } = useTheme()

  const { onExtend } = useExtendLockPeriod()

  const { toastSuccess, toastError } = useToast()
  const { isMobile } = useMatchBreakpoints()
  const [pendingTx, setPendingTx] = useState(false)

  const myTier = useMemo(() => {
    if (userData.tierIndex === 0) {
      return undefined
    }
    return tiers[userData.tierIndex] ?? undefined
  }, [userData.tierIndex])

  // End On
  const currentUnixTime = Math.floor(new Date().getTime() / 1000)
  const currentDay = Math.floor((currentUnixTime + 43200) / 86400)
  const endLockDay = Math.floor((userData.endLockTime - 43200) / 86400)
  const endDay = currentDay + myTier.lockDayDuration
  const endOn = endDay * 86400 + 43200

  const endOnDate = currentDay >= endLockDay ? endOn : userData.endLockTime
  const timestampAsMs = endOnDate * 1000
  const localeTimestamp = format(timestampAsMs ?? 0, 'dd MMMM yyyy HH:mm')

  const handleConfirmClick = async () => {
    setPendingTx(true)
    try {
      await onExtend(myTier.id)
      toastSuccess(
        `${t('Extended')}!`,
        t('Your lock period has been extended to %endOn%', {
          endOn: localeTimestamp,
        }),
      )
      setPendingTx(false)
      onDismiss()
    } catch (e) {
      toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      setPendingTx(false)
    }
  }

  return (
    <Modal
      minWidth="346px"
      title="Extend lock period"
      onDismiss={onDismiss}
      headerBackground={theme.colors.gradients.cardHeader}
    >
      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Flex alignItems="center" minWidth="70px">
          <Image src={`/images/tokens/${stakingToken.address}.png`} width={24} height={24} alt={stakingToken.symbol} />
          <Text ml="4px" bold>
            {stakingToken.symbol}
          </Text>
        </Flex>
      </Flex>

      <Text bold>Position overview :</Text>
      <AutoRow mt="3px" style={{ justifyContent: 'space-between' }}>
        <Text color="textSubtle" fontSize="14px">
          Staked
        </Text>
        <Text color="textSubtle" fontSize="14px">
          {formatNumber(getBalanceNumber(userData.stakedBalance, stakingToken.decimals))}
          PLN
        </Text>
      </AutoRow>

      <AutoRow style={{ justifyContent: 'space-between' }}>
        <Text color="textSubtle" fontSize="14px">
          Lock duration
        </Text>
        <Text color="textSubtle" fontSize="14px">
          {myTier.lockDayDuration} days
        </Text>
      </AutoRow>
      <AutoRow style={{ justifyContent: 'space-between' }}>
        <Text color="textSubtle" fontSize="14px">
          APR
        </Text>
        <Text color="textSubtle" fontSize="14px">
          {myTier.plnBaseApr + myTier.plnLockApr}%
        </Text>
      </AutoRow>
      <AutoRow style={{ justifyContent: 'space-between' }}>
        <Text color="textSubtle" fontSize="14px">
          PCC per 100 PLN
        </Text>
        <Text color="textSubtle" fontSize="14px">
          {myTier.pccBaseApr + myTier.pccLockApr} PCC
        </Text>
      </AutoRow>
      <AutoRow style={{ justifyContent: 'space-between' }}>
        <Text color="textSubtle" fontSize="14px">
          Ends on
        </Text>
        <Text color="textSubtle" fontSize="14px">
          {localeTimestamp}
        </Text>
      </AutoRow>

      <Button
        isLoading={pendingTx}
        endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
        onClick={handleConfirmClick}
        mt="24px"
      >
        {pendingTx ? t('Extending') : t('Extend')}
      </Button>
    </Modal>
  )
}

export default ExtendLockPeriodModal
