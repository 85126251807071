import React from 'react'
import { Flex, Progress, Text } from '@plearn/uikit'
import { formatAmount } from 'views/Info/utils/formatInfoNumbers'
import { getNextTier } from 'views/MemberPool/utils/getNextTier'
import { formatNumber } from 'utils/formatBalance'

const RankProgressBar = ({ tierData, stakedBalance }) => {
  // console.debug({ rankingData, tiers })
  if (!tierData) {
    return (
      <div>
        <Text as="span" color="primary">
          Start invest
        </Text>
        <Text as="span" color="textSubtle">
          {' '}
          to become an investor
        </Text>
        <Progress primaryStep={0} variant="round" useDark showBorder />
      </div>
    )
  }

  if (tierData && tierData.tier === null) {
    return (
      <div>
        <Text as="span" color="primary">
          Invest more
        </Text>
        <Text as="span" color="textSubtle">
          {' '}
          to become an investor
        </Text>
        <Progress primaryStep={0} variant="round" useDark showBorder />
        {/* <Text as="span" color="white">
          Investor
        </Text> */}
      </div>
    )
  }

  const nextTier = getNextTier(tierData.id ?? 0)
  //   const nextTier = tiers[1]
  // if (!nextTier) return null

  const currentAmount = stakedBalance
  const nextTierAmount = nextTier ? nextTier.minAmount : 0
  //   const nextTierAmount = nextTier.minAmount - currentAmount
  const rawProgress = (currentAmount / nextTierAmount) * 100
  const progress = rawProgress <= 100 ? rawProgress : 100

  // console.debug({nextTier})

  return (
    <div style={{ width: '100%' }}>
      <Text as="span" color="textSubtle">
        {formatNumber(stakedBalance)}
      </Text>

      <Text as="span" color="textSubtle">
        /
      </Text>
      {tierData?.name === 'Diamond' && <Text display="inline">{formatNumber(stakedBalance)}</Text>}
      {nextTier && (
        <>
          <Text as="span" color="textSubtle">
            {formatNumber(Number(nextTier.minAmount))} PLN
          </Text>
        </>
      )}
      <Flex height="5px" />
      <Progress scale="sm" primaryStep={progress} variant="round" useDark />
    </div>
  )
}

export default React.memo(RankProgressBar)
