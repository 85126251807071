import { Collections, CollectionKey } from './types'

const collections: Collections = {
  [CollectionKey.PANCAKE]: {
    name: 'Pancake Bunnies',
    slug: 'pancake-bunnies',
    address: {
      56: '',
      97: '',
    },
  },
  [CollectionKey.SQUAD]: {
    name: 'Pancake Squad',
    description: "PancakeSwap's first official generative NFT collection.. Join the squad.",
    slug: 'pancake-squad',
    address: {
      56: '',
      97: '',
    },
  },
  [CollectionKey.APE]: {
    name: 'Plearn Caps Apes',
    description: '',
    slug: 'plearn-apes',
    address: {
      56: '0x8E87400f0B9C7700987279F9cE503dc03d913600',
      97: '0x2581453FAcf2A3497a80Fc065d05Bfac4B7C78A0',
    },
    roundId: '3',
  },
  [CollectionKey.WHISKEY]: {
    name: 'Plearn Whiskey',
    description: '',
    slug: 'plearn-whiskey',
    address: {
      56: '0x051d162895bCAA8246f60F22f4F103b07cE12AEe',
      97: '0x878AB6FeeB3ff9091D01287E33C3E45a1e72AC0e',
    },
    roundId: '1',
  },
}

export default collections
