import { getPlearnNFT1HandlerContract, getPlearnNFTContract } from 'utils/contractHelpers'
import { RoundStatus } from './types'

export const fetchNftRoundData = async (roundId: number, collectionAddress: string, account?: string) => {
  try {
    const { getRound, getUserMintedCount, dealToken } = getPlearnNFT1HandlerContract()
    const nftHandlerContractResponse = await getRound(roundId)
    const userMintedCount = account ? await getUserMintedCount(roundId, account) : 0
    const tokenForPurchase = await dealToken()
    const nft = nftHandlerContractResponse.nfts.filter(
      (item) => item.collection.toLowerCase() === collectionAddress.toLowerCase(),
    )[0]
    const { reservedRangeId } = nft
    const items = nftHandlerContractResponse.items.map((bundle) => {
      return bundle.map((tokenIds, j) => {
        const { baseTokenId } = nftHandlerContractResponse.nfts[j]
        return tokenIds.map((tokenId) => Number(tokenId) + Number(baseTokenId))
      })
    })
    const { getMintedTokens, maxSupply, getMintReserve, totalSupply } = getPlearnNFTContract(collectionAddress)
    const nftMaxSupply = await maxSupply()
    const nftTotalSupply = await totalSupply()
    const getAllTokens = await getMintedTokens(0, Number(nftMaxSupply))
    const { minTokenId, maxTokenId } = await getMintReserve(Number(reservedRangeId))
    return {
      status: RoundStatus[Object.keys(RoundStatus)[Number(nftHandlerContractResponse.status)]],
      mintPrice: nftHandlerContractResponse.mintPrice.toString(),
      maxMintPerWallet: Number(nftHandlerContractResponse.maxMintPerWallet),
      roundConflict: Number(nftHandlerContractResponse.roundConflict),
      minTierRequired: Number(nftHandlerContractResponse.minTierRequired),
      userMintedCount: Number(userMintedCount),
      collectionData: {
        maxSupply: Number(nftMaxSupply),
        totalSupply: Number(nftTotalSupply),
        minTokenId: Number(minTokenId),
        maxTokenId: Number(maxTokenId),
        mintedTokenIds: getAllTokens[0].map(Number),
      },
      items,
      dealToken: tokenForPurchase,
      isFetching: false,
    }
  } catch (error) {
    return {
      status: RoundStatus.CLOSE,
      mintPrice: 0,
      maxMintPerWallet: 0,
      roundConflict: 0,
      minTierRequired: 0,
      userMintedCount: 0,
      collectionData: {
        maxSupply: 0,
        totalSupply: 0,
        minTokenId: -1,
        maxTokenId: -1,
        mintedTokenIds: [],
      },
      items: [],
      dealToken: '-',
      isFetching: false,
    }
  }
}
