import React, { useMemo } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Button, useModal, IconButton, AddIcon, MinusIcon, Skeleton, useTooltip, Flex, Text } from '@plearn/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useWeb3React } from '@web3-react/core'
import { useCakeVault } from 'state/pools/hooks'
import { DeserializedMemberPool } from 'state/types'
import Balance from 'components/Balance'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber, formatNumber, getDecimalAmount } from 'utils/formatBalance'
import { BIG_ZERO } from 'utils/bigNumber'
import { useERC20 } from 'hooks/useContract'
import { convertSharesToCake } from 'views/Pools/helpers'
import NotEnoughTokensModal from 'views/Pools/components/PoolCard/Modals/NotEnoughTokensModal'
import { useApprovePool } from 'views/MemberPool/hooks/useApprove'
import { usePricePlearnBusd } from 'state/farms/hooks'
import tiers from 'config/constants/tiers'
import { format } from 'date-fns'
import { ActionContainer, ActionTitles, ActionContent } from './styles'
import EnableModal from '../Modals/EnableModal'
import StakeModal from '../Modals/StakeModal'
import UnstakeModal from '../Modals/UnstakeModal'

const IconButtonWrapper = styled.div`
  display: flex;
`

interface StackedActionProps {
  pool: DeserializedMemberPool
  userDataLoaded: boolean
}

const Staked: React.FunctionComponent<StackedActionProps> = ({ pool, userDataLoaded }) => {
  const { stakingToken, earningPlnToken, earningPcc, isFinished, userData, depositEnabled } = pool
  const { t } = useTranslation()
  const { account } = useWeb3React()

  const myTier = userData.tierIndex > 0 ? tiers[userData.tierIndex] ?? undefined : undefined

  // Unlock day left
  const currentTimestamp = new Date().getTime()
  const currentUnixTime = Math.floor(currentTimestamp / 1000)
  const currentDay = Math.floor((currentUnixTime + 43200) / 86400)
  const endLockDay = Math.floor((userData.endLockTime - 43200) / 86400)
  const unlockDayLeft = currentDay > endLockDay ? 0 : endLockDay - currentDay
  const endLockTimestampAsMs = userData.endLockTime * 1000
  const localeTimestamp = format(endLockTimestampAsMs ?? 0, 'dd MMMM yyyy HH:mm')

  const stakingTokenContract = useERC20(stakingToken.address || '')
  const { handleApprove: handlePoolApprove, requestedApproval: requestedPoolApproval } = useApprovePool(
    stakingTokenContract,
    earningPlnToken.symbol,
  )

  const plnPrice = usePricePlearnBusd()
  const stakingTokenPrice = plnPrice.toNumber()
  const stakingLimit = myTier ? getDecimalAmount(new BigNumber(myTier.maxAmount.toString())) : BIG_ZERO

  const handleApprove = handlePoolApprove
  const requestedApproval = requestedPoolApproval

  const allowance = userData?.allowance ? new BigNumber(userData.allowance) : BIG_ZERO
  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const isHasStake = stakedBalance.gt(0)

  const stakingTokenBalance = userData?.stakingTokenBalance ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO

  const stakedTokenBalance = getBalanceNumber(stakedBalance, stakingToken.decimals)
  const stakedTokenDollarBalance = getBalanceNumber(
    stakedBalance.multipliedBy(stakingTokenPrice),
    stakingToken.decimals,
  )

  const eligibleTier = useMemo(() => {
    const balance = userData?.stakingTokenBalance ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO
    const tokenBalance = getBalanceNumber(balance, stakingToken.decimals)

    const eligibleTiers = tiers.filter((tier) => tokenBalance >= tier.minAmount && tokenBalance <= tier.maxAmount)
    if (eligibleTiers.length === 0) {
      return null
    }

    return eligibleTiers.sort((a, b) => b.minAmount - a.minAmount)[0]
  }, [stakingToken.decimals, userData.stakingTokenBalance])

  const needsApproval = !allowance.gt(0)

  const [onPresentTokenRequired] = useModal(<NotEnoughTokensModal tokenSymbol={stakingToken.symbol} />)

  const [onPresentNeedsApproval] = useModal(<EnableModal pool={pool} />)

  const [onPresentStake] = useModal(
    <StakeModal pool={pool} stakingTokenBalance={stakingTokenBalance} stakingTokenPrice={stakingTokenPrice} />,
  )
  const [onPresentSuggestStake] = useModal(
    <StakeModal
      pool={pool}
      stakingTokenBalance={stakingTokenBalance}
      stakingTokenPrice={stakingTokenPrice}
      isUpgrade
      tierIndexForUpgrade={eligibleTier?.id ?? 0}
    />,
  )

  const [onPresentUnstake] = useModal(
    <UnstakeModal stakingTokenBalance={stakingTokenBalance} pool={pool} stakingTokenPrice={stakingTokenPrice} />,
  )

  const onStake = () => {
    if (needsApproval) {
      onPresentNeedsApproval()
    } else if (!isHasStake && eligibleTier !== null) {
      onPresentSuggestStake()
    } else {
      onPresentStake()
    }
  }

  const onUnstake = () => {
    onPresentUnstake()
  }

  const { targetRef, tooltip, tooltipVisible } = useTooltip(t("You've already staked the maximum amount"), {
    placement: 'bottom',
  })

  const {
    targetRef: cannotWithdrawYetTargetRef,
    tooltip: cannotWithdrawYetTooltip,
    tooltipVisible: cannotWithdrawYetTooltipVisible,
  } = useTooltip(
    <Text color="black">
      {endLockTimestampAsMs > currentTimestamp
        ? `Ends on ${localeTimestamp}`
        : `${unlockDayLeft} ${unlockDayLeft > 1 ? 'days' : 'day'} left to unstake PLN token.`}
    </Text>,
    {
      placement: 'bottom',
    },
  )

  const {
    targetRef: depositDisabledTargetRef,
    tooltip: depositDisabledTooltip,
    tooltipVisible: depositDisabledTooltipVisible,
  } = useTooltip(t('Deposit is disabled'), {
    placement: 'bottom',
  })

  const reachStakingLimit = stakingLimit.gt(0) && userData.stakedBalance.gte(stakingLimit)

  if (!account) {
    return (
      <ActionContainer>
        <ActionTitles>
          <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
            {t('Start staking')}
          </Text>
        </ActionTitles>
        <ActionContent>
          <ConnectWalletButton width="100%" />
        </ActionContent>
      </ActionContainer>
    )
  }

  if (!userDataLoaded) {
    return (
      <ActionContainer>
        <ActionTitles>
          <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
            {t('Start staking')}
          </Text>
        </ActionTitles>
        <ActionContent>
          <Skeleton width={180} height="32px" marginTop={14} />
        </ActionContent>
      </ActionContainer>
    )
  }

  // console.debug(isFoundingInvestorPool, isFoundingInvestor)

  // Wallet connected, user data loaded and approved
  if (isHasStake) {
    return (
      <ActionContainer>
        <ActionTitles>
          <Text fontSize="12px" bold color="primary" as="span" textTransform="uppercase">
            {stakingToken.symbol}{' '}
          </Text>
          <Text fontSize="12px" bold color="primary" as="span" textTransform="uppercase">
            {t('Staked')}
          </Text>
        </ActionTitles>
        <ActionContent>
          <Flex flex="1" pt="16px" flexDirection="column" alignSelf="flex-start">
            <Balance lineHeight="1" bold fontSize="20px" decimals={5} value={stakedTokenBalance} />
            <Balance
              fontSize="12px"
              display="inline"
              color="textSubtle"
              decimals={2}
              value={stakedTokenDollarBalance}
              unit=" USD"
              prefix="~"
            />
          </Flex>
          <IconButtonWrapper>
            {unlockDayLeft > 0 || endLockTimestampAsMs > currentTimestamp ? (
              <span ref={cannotWithdrawYetTargetRef}>
                <IconButton variant="secondary" disabled mr="6px">
                  <MinusIcon color="primary" width="14px" />
                </IconButton>
              </span>
            ) : (
              <IconButton variant="secondary" onClick={onUnstake} mr="6px">
                <MinusIcon color="primary" width="14px" />
              </IconButton>
            )}

            {reachStakingLimit ? (
              <span ref={targetRef}>
                <IconButton variant="secondary" disabled>
                  <AddIcon color="textDisabled" width="24px" height="24px" />
                </IconButton>
              </span>
            ) : (
              <>
                {!depositEnabled ? (
                  <span ref={depositDisabledTargetRef}>
                    <IconButton
                      variant="secondary"
                      onClick={stakingTokenBalance.gt(0) ? onStake : onPresentTokenRequired}
                      disabled={isFinished || !depositEnabled}
                    >
                      <AddIcon color="primary" width="14px" />
                    </IconButton>
                  </span>
                ) : (
                  <IconButton
                    variant="secondary"
                    onClick={stakingTokenBalance.gt(0) ? onStake : onPresentTokenRequired}
                    disabled={isFinished}
                  >
                    <AddIcon color="primary" width="14px" />
                  </IconButton>
                )}
              </>
            )}
          </IconButtonWrapper>
          {tooltipVisible && tooltip}
          {cannotWithdrawYetTooltipVisible && cannotWithdrawYetTooltip}
          {depositDisabledTooltipVisible && depositDisabledTooltip}
        </ActionContent>
      </ActionContainer>
    )
  }

  if (needsApproval) {
    return (
      <ActionContainer>
        <ActionTitles>
          <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
            {t('Enable pool')}
          </Text>
        </ActionTitles>
        <ActionContent>
          <Button width="100%" disabled={requestedApproval} onClick={handleApprove} variant="secondary">
            {t('Enable')}
          </Button>
        </ActionContent>
      </ActionContainer>
    )
  }

  // const stakeButtonText = isFoundingInvestor ? 'Stake1' : 'Stake2'

  return (
    <Flex flexGrow={1} flexBasis={0} flexDirection="column">
      <ActionContainer>
        <ActionTitles>
          <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
            {t('Stake')}{' '}
          </Text>
          <Text fontSize="12px" bold color="primary" as="span" textTransform="uppercase">
            {stakingToken.symbol}
          </Text>
        </ActionTitles>
        <ActionContent>
          <Button
            width="100%"
            onClick={stakingTokenBalance.gt(0) ? onStake : onPresentTokenRequired}
            variant="secondary"
            disabled={isFinished || !depositEnabled}
          >
            {t('Stake')}
          </Button>
        </ActionContent>
      </ActionContainer>
      {!isHasStake && eligibleTier !== null && (
        <Text fontSize="14px" bold color="primary" as="span" ml="10px">
          {!depositEnabled
            ? 'Deposit is not enabled yet'
            : `Let’s stake ${eligibleTier.minAmount.toLocaleString()} PLN to reach a ${eligibleTier.name} tier`}
        </Text>
      )}
    </Flex>
    // <ActionContainer>
    //   <ActionTitles>
    //     <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
    //       {t('Stake')}{' '}
    //     </Text>
    //     <Text fontSize="12px" bold color="primary" as="span" textTransform="uppercase">
    //       {stakingToken.symbol}
    //     </Text>
    //   </ActionTitles>
    //   <ActionContent>
    //     <Button
    //       width="100%"
    //       onClick={stakingTokenBalance.gt(0) ? onStake : onPresentTokenRequired}
    //       variant="secondary"
    //       disabled={isFinished}
    //     >
    //       {t('Stake')}
    //     </Button>
    //   </ActionContent>
    // </ActionContainer>
  )
}

export default Staked
