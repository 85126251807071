import React, { useState } from 'react'
import styled from 'styled-components'
import { useMatchBreakpoints, Text, Flex, CardRibbon } from '@plearn/uikit'
import { DeserializedPool } from 'state/types'
import { SIX_POOL_ID, LOCKED_POOL_ID, FOUNDING_POOL_ID } from 'config/constants/pools'
import useDelayedUnmount from 'hooks/useDelayedUnmount'
import NameCell from './Cells/NameCell'
import EarningsCell from './Cells/EarningsCell'
import AprCell from './Cells/AprCell'
import TotalStakedCell from './Cells/TotalStakedCell'
import TotalRewardsCell from './Cells/TotalRewardsCell'
import EndsInCell from './Cells/EndsInCell'
import ExpandActionCell from './Cells/ExpandActionCell'
import ActionPanel from './ActionPanel/ActionPanel'
import ActionPanelLockedPool from './ActionPanel/ActionPanelLockedPool'
import AutoEarningsCell from './Cells/AutoEarningsCell'
import AutoAprCell from './Cells/AutoAprCell'

interface PoolRowProps {
  pool: DeserializedPool
  account: string
  userDataLoaded: boolean
}

const StyledRow = styled.div`
  background-color: transparent;
  display: flex;
  cursor: pointer;
`

const StyledTag = styled.div`
  margin-top: 0px;
  margin-left: 16px;
  margin-bottom: -15px;
  width: 38px;
  height: 28px;
  background: url(/images/pool-tag.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  p {
    color: #271e16;
    font-size: 10px;
    text-align: center;
    margin-top: 7px;
  }
`

const PoolRow: React.FC<PoolRowProps> = ({ pool, account, userDataLoaded }) => {
  const { isXs, isSm, isMd, isLg, isXl, isXxl, isTablet, isDesktop, isMobile } = useMatchBreakpoints()
  const isLargerScreen = isLg || isXl || isXxl
  const [expanded, setExpanded] = useState(false)
  const shouldRenderActionPanel = useDelayedUnmount(expanded, 300)
  const isSixPool = pool.sousId === SIX_POOL_ID
  const isLockedPool = LOCKED_POOL_ID.includes(pool.sousId)
  const isFoundingInvestorPool = FOUNDING_POOL_ID.includes(pool.sousId)

  const toggleExpanded = () => {
    setExpanded((prev) => !prev)
  }

  return (
    <Flex flexDirection="column">
      {isLockedPool && (
        <StyledTag style={{ marginLeft: isMobile ? 12 : 32 }}>
          <p>{pool.lockPeriod}</p>
        </StyledTag>
      )}

      {isFoundingInvestorPool && (
        <StyledTag style={{ marginLeft: isMobile ? 12 : 32 }}>
          <p>{pool.foundingInvestorPoolRound}</p>
        </StyledTag>
      )}

      <StyledRow role="row" onClick={toggleExpanded}>
        <NameCell pool={pool} />
        {pool.isAutoVault ? (
          <AutoEarningsCell pool={pool} account={account} userDataLoaded={userDataLoaded} />
        ) : (
          <EarningsCell pool={pool} account={account} userDataLoaded={userDataLoaded} />
        )}

        {isSixPool ? (
          isLargerScreen && <TotalRewardsCell pool={pool} />
        ) : pool.isAutoVault ? (
          <AutoAprCell pool={pool} />
        ) : (
          <>{isFoundingInvestorPool && <AprCell pool={pool} />}</>
        )}

        {isLargerScreen && <TotalStakedCell pool={pool} />}
        {isDesktop && <EndsInCell pool={pool} />}
        <ExpandActionCell expanded={expanded} isFullLayout={isTablet || isDesktop} />
      </StyledRow>
      {shouldRenderActionPanel &&
        (isLockedPool ? (
          <ActionPanelLockedPool
            account={account}
            pool={pool}
            userDataLoaded={userDataLoaded}
            expanded={expanded}
            breakpoints={{ isXs, isSm, isMd, isLg, isXl, isXxl }}
          />
        ) : (
          <ActionPanel
            account={account}
            pool={pool}
            userDataLoaded={userDataLoaded}
            expanded={expanded}
            breakpoints={{ isXs, isSm, isMd, isLg, isXl, isXxl }}
          />
        ))}
    </Flex>
  )
}

export default PoolRow
