import React from 'react'
import { Button, useWalletModal } from '@plearn/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import { Plus } from 'react-feather'

const ConnectWalletButton = ({showPlusIcon = false, ...props}) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  return (
    <Button onClick={onPresentConnectModal} {...props}>
      {showPlusIcon && <Plus style={{ marginRight: 8 }} />}
      {t('Connect Wallet')}
    </Button>
  )
}

export default ConnectWalletButton
