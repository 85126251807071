import React, { useState } from 'react'
import { Modal, Text, Button, Flex, AutoRenewIcon } from '@plearn/uikit'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useToast from 'hooks/useToast'
import { useERC20, useSousChef, useMemberPool } from 'hooks/useContract'
import { DeserializedMemberPool } from 'state/types'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useAppDispatch } from 'state'
import { updateUserAllowance } from 'state/actions'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { ToastDescriptionWithTx } from 'components/Toast'

interface EnableModalProps {
  pool: DeserializedMemberPool
  onDismiss?: () => void
}

const EnableModal: React.FC<EnableModalProps> = ({ pool, onDismiss }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { stakingToken, earningPlnToken } = pool

  const stakingTokenContract = useERC20(stakingToken.address || '')
  const [requestedApproval, setRequestedApproval] = useState(false)
  const { toastSuccess, toastError } = useToast()
  const { callWithGasPrice } = useCallWithGasPrice()
  //   const dispatch = useAppDispatch()
  //   const { account } = useWeb3React()
  const memberPoolContract = useMemberPool()

  const handleApprove = async () => {
    try {
      setRequestedApproval(true)
      const tx = await callWithGasPrice(stakingTokenContract, 'approve', [
        memberPoolContract.address,
        ethers.constants.MaxUint256,
      ])
      const receipt = await tx.wait()

      //   dispatch(updateUserAllowance(sousId, account))
      if (receipt.status) {
        toastSuccess(
          t('Contract Enabled'),
          <ToastDescriptionWithTx txHash={receipt.transactionHash}>
            {t('You can now stake in the %symbol% pool!', { symbol: earningPlnToken.symbol })}
          </ToastDescriptionWithTx>,
        )
        setRequestedApproval(false)
        onDismiss()
      } else {
        // user rejected tx or didn't go thru
        toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
        setRequestedApproval(false)
      }
    } catch (e) {
      console.error(e)
      toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      setRequestedApproval(false)
    }
  }

  return (
    <Modal title={`${t('Enable')}`} onDismiss={onDismiss} headerBackground={theme.colors.gradients.cardHeader}>
      <Flex justifyContent="space-between" alignItems="center" mb="24px">
        <Text>{t('To stake to the pool, you need to enable contract to access your fund.')}</Text>
      </Flex>

      <Button
        isLoading={requestedApproval}
        endIcon={requestedApproval ? <AutoRenewIcon spin color="currentColor" /> : null}
        disabled={requestedApproval}
        onClick={handleApprove}
      >
        {t('Enable')}
      </Button>
    </Modal>
  )
}

export default EnableModal
