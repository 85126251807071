// helper functions
async function handleResponse(response: Response) {
  const text = await response.text()
  const data = text && JSON.parse(text)
  if (!response.ok) {
    const error = (data && data.message) || response.statusText
    return Promise.reject(error)
  }
  return data
}

async function get(url: RequestInfo) {
  const requestOptions = {
    method: 'GET',
  }
  const response = await fetch(url, requestOptions)
  return handleResponse(response)
}

async function post(url: RequestInfo, authorization: string, body: any) {
  console.debug(url)
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authorization,
    },
    body: JSON.stringify(body),
  }
  console.debug(requestOptions)
  const response = await fetch(url, requestOptions)
  return handleResponse(response)
}

async function postWithoutCors(url: RequestInfo, authorization: string, body: any) {
  console.debug(authorization)
  const requestOptions = {
    method: 'POST',
    mode: 'no-cors' as RequestMode,
    headers: {
      Accept: 'application/json',
      Authorization: authorization,
    },

    body: JSON.stringify(body),
  }

  console.debug(requestOptions)
  const response = await fetch(url, requestOptions)
  return handleResponse(response)
}

async function put(url: RequestInfo, body: any) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  }
  const response = await fetch(url, requestOptions)
  return handleResponse(response)
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url: RequestInfo) {
  const requestOptions = {
    method: 'DELETE',
  }
  const response = await fetch(url, requestOptions)
  return handleResponse(response)
}

export default {
  get,
  post,
  postWithoutCors,
  put,
  delete: _delete,
}
