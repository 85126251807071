export const plearnThemeColors = {
  failure: '#FF4444',
  primary: '#F0B986',
  primaryBright: '#53DEE9',
  primaryDark: '#E0B070',
  primaryLight: '#DAD4C6',
  secondary: '#464441',
  success: '#31D0AA',
  tertiary: '#271E16',
  warning: '#FFB237',
  binance: '#F0B90B',
  overlay: '#666666',
  gold: '#FFC700',
  silver: '#B2B2B2',
  bronze: '#E7974D',
  background: '#08060B',
  backgroundAlt: '#271E16',
  cardBorder: '#271E16',
  dropdownDeep: '#464441',
  textSubtle: '#9F9F9F'
}
