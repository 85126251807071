import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import {
  useMatchBreakpoints,
  Text,
  Heading,
  WalletFilledIcon,
  Flex,
  ChevronDownIcon,
  Button,
  useModal,
  Image,
} from '@plearn/uikit'
import { useTranslation } from 'contexts/Localization'
import useDelayedUnmount from 'hooks/useDelayedUnmount'
import { AssetsData } from 'state/myInvestments/types'
import { AutoRow } from 'components/Layout/Row'
import { formatAmount } from 'views/Info/utils/formatInfoNumbers'
import { DeserializedMemberPool } from 'state/types'
import { getNextTier } from 'views/MemberPool/utils/getNextTier'
import { format } from 'date-fns'
import tiers from 'config/constants/tiers'
import RankProgressBar from './RankProgressBar'
import ExtendLockPeriodModal from '../Modals/ExtendLockPeriodModal'

const Wrapper = styled.div`
  padding: 9px 0px;
  display: flex;
  width: 100%;
  height: 64px;
  align-items: center;
  padding-right: 8px;
  background-color: rgba(122, 103, 64, 0.2);
  border-radius: 16px 16px 0px 0px;

  ${({ theme }) => theme.mediaQueries.xl} {
    padding-right: 32px;
  }
`

const CellInner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 64px;

  background-color: ${({ theme }) => theme.card.background};
  border-radius: 16px 16px 0px 0px;
`

const ArrowIcon = styled(ChevronDownIcon)<{ toggled: boolean }>`
  transform: ${({ toggled }) => (toggled ? 'rotate(180deg)' : 'rotate(0)')};
  height: 24px;
`

export const TableWrapper = styled(Flex)`
  width: 100%;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;

  flex-direction: column;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  overflow-x: auto;
  max-width: calc(100vw - 48px);
  height: 250px;
  ${({ theme }) => theme.mediaQueries.md} {
    height: 216px;
  }
  border-radius: 0px 0px 16px 16px;
`

const StyledTr = styled.tr``

interface MyTierBenefitssProps {
  stakedBalance: number
  tierIndex: number
  pool: DeserializedMemberPool
}

const MyTierBenefits: React.FunctionComponent<MyTierBenefitssProps> = ({ stakedBalance, tierIndex, pool }) => {
  const { t } = useTranslation()
  const { stakingToken, earningPlnToken, earningPcc, isFinished, userData } = pool

  const { isDesktop, isMobile } = useMatchBreakpoints()

  const isSmallerScreen = !isDesktop

  const myTier = useMemo(() => {
    if (tierIndex === 0) {
      return undefined
    }
    return tiers[tierIndex] ?? undefined
  }, [tierIndex])

  const nextTier = useMemo(() => {
    return getNextTier(myTier.id ?? 0)
  }, [myTier.id])

  // Unlock day left
  const currentTimestamp = new Date().getTime()
  const currentUnixTime = Math.floor(currentTimestamp / 1000)
  const currentDay = Math.floor((currentUnixTime + 43200) / 86400)
  const endLockDay = Math.floor((userData.endLockTime - 43200) / 86400)
  const unlockDayLeft = currentDay > endLockDay ? 0 : endLockDay - currentDay
  const endLockTimestampAsMs = userData.endLockTime * 1000
  const localeTimestamp = format(endLockTimestampAsMs ?? 0, 'dd MMMM yyyy HH:mm')

  const [onPresentExtend] = useModal(<ExtendLockPeriodModal pool={pool} />)

  const handleRenderRow = () => {
    return (
      <StyledTr style={{ width: '100%' }}>
        <CellInner>
          <Wrapper>
            <AutoRow style={{ justifyContent: 'space-between' }}>
              <Flex width="100%" ml="24px" alignItems="center" style={{ gap: '8px' }}>
                <Image src={myTier.tierIcon} width={20} height={20} alt="tier-icon" />
                <Text bold fontSize="20px" display="inline">
                  {myTier.name}
                </Text>
              </Flex>
            </AutoRow>
          </Wrapper>
        </CellInner>
      </StyledTr>
    )
  }

  return (
    <>
      <Flex flexDirection="column" style={{ width: '100%' }}>
        {handleRenderRow()}
        <TableWrapper>
          <Flex width="100%" flexDirection="row" alignItems="flex-end" style={{ gap: '3px' }}>
            <RankProgressBar tierData={myTier} stakedBalance={stakedBalance} />
            {nextTier && (
              <>
                <Image src={nextTier.tierIcon} width={13} height={13} ml="3px" alt={`tier-icon-${nextTier.name}`} />
                <Flex justifyContent="flex-end" height={20}>
                  <Text>{nextTier.name}</Text>
                </Flex>
              </>
            )}
          </Flex>
          {/* <RankProgressBar tierData={myTier} stakedBalance={stakedBalance} /> */}

          <AutoRow mt="7px" style={{ justifyContent: 'space-between' }}>
            <Text>Minimum Lock Duration</Text>
            <Text>{myTier.lockDayDuration} days</Text>
          </AutoRow>
          <AutoRow style={{ justifyContent: 'space-between' }}>
            <Text>Total APR</Text>
            <Text>{myTier.plnBaseApr + myTier.plnLockApr}%</Text>
          </AutoRow>
          <AutoRow style={{ justifyContent: 'space-between' }}>
            <Text>Total PCC Yield</Text>
            <Text>{myTier.pccBaseApr + myTier.pccLockApr}%</Text>
          </AutoRow>
          <AutoRow mb="10px" style={{ justifyContent: 'space-between', gap: '10px' }}>
            {unlockDayLeft > 0 ? (
              <Text color="primary">{`${unlockDayLeft} ${
                unlockDayLeft > 1 ? 'days' : 'day'
              } left to unstake PLN token.`}</Text>
            ) : (
              <>
                {endLockTimestampAsMs > currentTimestamp ? (
                  <Text color="primary">Ends on {localeTimestamp}</Text>
                ) : (
                  <Text color="primary">PLN is Now Unlocked</Text>
                )}
              </>
            )}
            <Button
              scale="sm"
              onClick={onPresentExtend}
              disabled={unlockDayLeft > 0 || endLockTimestampAsMs > currentTimestamp}
            >
              Extend Lock Period
            </Button>
          </AutoRow>
        </TableWrapper>
      </Flex>
    </>
  )
}

export default MyTierBenefits
