import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Flex, TooltipText, IconButton, useModal, CalculateIcon, Skeleton, useTooltip, Text, Box } from '@plearn/uikit'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import { getBalanceNumber } from 'utils/formatBalance'
import { DeserializedPool } from 'state/types'
import { useBlock } from 'state/block/hooks'
import { getAprData, getPoolBlockInfo } from 'views/Pools/helpers'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'

const InlineText = styled(Text)`
  display: inline;
`

interface TotalRewardsRowProps {
  pool: DeserializedPool
}

const TotalRewardsRow: React.FC<TotalRewardsRowProps> = ({ pool }) => {
    const { t } = useTranslation()
    const { currentBlock } = useBlock()
  const { sousId, earningToken, totalRewards, endBlock } = pool
  const { blocksToDisplay } =
    getPoolBlockInfo(pool, currentBlock)

    const totalRewardsBalance = blocksToDisplay * Number(pool.tokenPerBlock)

  // const totalRewardsBalance = useMemo(() => {
  //   return getBalanceNumber(totalRewards, earningToken.decimals)
  // }, [totalRewards, earningToken.decimals])

  return (
    <Flex alignItems="center" justifyContent="space-between">
        <Box display="inline">
        <InlineText color="textSubtle" textTransform="uppercase" bold fontSize="12px">
                {t('Total')}
              </InlineText>
              <InlineText color="primary" textTransform="uppercase" bold fontSize="12px">
                {` ${earningToken.symbol} `}
              </InlineText>
              <InlineText color="textSubtle" textTransform="uppercase" bold fontSize="12px">
                {t('rewards:')}
              </InlineText>
            </Box>
      {/* <TooltipText>{ `${t('Total PLN rewards')}:`}</TooltipText> */}
      {totalRewards && totalRewards.gte(0) ? (
          <Balance fontSize="14px" value={totalRewardsBalance} decimals={0} unit={`/403,200 ${earningToken.symbol}`} />
        ) : (
          <Skeleton width="82px" height="32px" />
        )}
    </Flex>
  )
}

export default TotalRewardsRow
