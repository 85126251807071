import store from 'state'
import { BASE_ENVIRONMENT } from 'config'
import { GAS_PRICE_GWEI } from 'state/user/hooks/helpers'

/**
 * Function to return gasPrice outwith a react component
 */
const getGasPrice = (): string => {
  // const chainId = process.env.REACT_APP_CHAIN_ID
  const state = store.getState()
  const userGas = state.user.gasPrice || GAS_PRICE_GWEI.default
  
  return BASE_ENVIRONMENT.toLowerCase() === "mainnet" ? userGas : GAS_PRICE_GWEI.testnet
}

export default getGasPrice
