import { request, gql } from 'graphql-request'
import { LuckyDrawTicket, TICKET_LIMIT_PER_ROUND } from 'config/constants/luckydraw'
import { UserTicketsResponse } from 'state/types'
import { getLuckyContract, getLuckyContractV2 } from 'utils/contractHelpers'
import BigNumber from 'bignumber.js'
import { LUCKY_ROUND_V2, GRAPH_API_LUCKY } from 'config/constants/endpoints'

// const luckyContract = getLuckyContractV2()

// const getTicketIdFromTicketNumber = (ticketNumber: number, startNumber: number, firstTicketId: number): string => {
//   const ticketId = (ticketNumber - startNumber + firstTicketId).toString()
//   return ticketId
// }

// const processRawUserTicketsResponseFromNode = (
//   luckyId: string,
//   ticketIds: string[],
//   userTickets: UserTicketsResponse,
// ): LuckyDrawTicket[] => {
//   const [ticketNumbers, ticketStatuses, brackets] = userTickets

//   if (ticketNumbers?.length > 0) {
//     return ticketIds.map((ticketId, index) => ({
//       id: ticketId,
//       number: ticketNumbers[index].toString(),
//       name: null, // userTickets ที่ได้จากการ getUserInfo จะไม่มี name กับ isHaveFollow
//       isHaveFollower: null, // userTickets ที่ได้จากการ getUserInfo จะไม่มี name กับ isHaveFollow
//       isLucky: ticketStatuses[index],
//       bracket: brackets[index],
//       roundId: luckyId,
//     }))
//   }
//   return []
// }

// type UserTicketWhere = { lucky: string, owner: string }
// Third Step, this function is called by fetchUserTicketsForOneRound
const getUserTicketsPerRound = async ({
  account,
  luckyId
}: {
  account: string
  luckyId: string
}): Promise<LuckyDrawTicket[]> => {
  try {
    // const luckyContract = Number(luckyId) < Number(LUCKY_ROUND_V2) ? getLuckyContract() : getLuckyContractV2()
    // const ticketStartNumber = (await luckyContract.startNumber()) as number // per round, ie. 100000
    // const { firstTicketId } = await luckyContract.getLuckyInfo(luckyId)

    // const _start = 0
    // const _size = TICKET_LIMIT_PER_ROUND
    // const userTickets = await luckyContract.getUserInfo(account, luckyId, _start, _size)
    // const [ticketNumbers] = userTickets
    // const ticketIds = ticketNumbers.map((ticketNumber) =>
    //   getTicketIdFromTicketNumber(ticketNumber, ticketStartNumber, firstTicketId.toNumber()),
    // )
    // return processRawUserTicketsResponseFromNode(luckyId, ticketIds, userTickets)
    const where = {
      lucky: luckyId,
      owner: account
    }
    const response = await request(
      GRAPH_API_LUCKY,
      gql`
        query getUserTicketsPerRound($where: Ticket_filter){
          tickets(where: $where, first :1000){
            id
            number
            isLucky
            bracket
          }
        }
      `,
      { where }
    )
    return response.tickets.map((ticket) => ({
      id: ticket.id,
      number: ticket.number,
      name: null, // userTickets ที่ได้จากการ getUserInfo จะไม่มี name กับ isHaveFollow
      isHaveFollower: null, // userTickets ที่ได้จากการ getUserInfo จะไม่มี name กับ isHaveFollow
      isLucky: ticket.isLucky ? ticket.isLucky : false,
      bracket: ticket.bracket ? ticket.bracket : 0,
      roundId: luckyId,
    }))

  } catch (error) {
    console.error('getUserInfo', error)
    return []
  }
}

export const fetchUserTicketsForOneRound = async (
  account: string,
  luckyId: string
): Promise<LuckyDrawTicket[]> => {
  const userTicketsPerRound = await getUserTicketsPerRound({
    account,
    luckyId
  })
  return userTicketsPerRound
}

// First Step
export const fetchUserTicketsForMultipleRounds = async (
  idsToCheck: string[],
  account: string,
): Promise<{ roundId: string; userTickets: LuckyDrawTicket[] }[]> => {

  const ticketsForMultipleRounds = []
  // const ticketStartNumber = (await luckyContract.startNumber()) as number // per round, ie. 100000
  for (let i = 0; i < idsToCheck.length; i += 1) {
    const roundId = idsToCheck[i]
    // eslint-disable-next-line no-await-in-loop
    const userTicketsForRound = await fetchUserTicketsForOneRound(account, roundId)
    ticketsForMultipleRounds.push({
      roundId,
      userTickets: userTicketsForRound,
    })
  }
  return ticketsForMultipleRounds
}
