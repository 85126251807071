import { ChainId, Token } from '@plearn/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken, ChainName } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  wbnb: new Token(
    MAINNET,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new Token(MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'BNB', 'BNB', 'https://www.binance.com/'),
  plearn: new Token(
    MAINNET,
    '0xBe0D3526fc797583Dada3F30BC390013062A048B',
    18,
    'PLN',
    'Plearn Token',
    'https://app.plearnclub.com',
  ),
  six: new Token(MAINNET, '0x070a9867ea49ce7afc4505817204860e823489fe', 18, 'SIX', 'Six Token', 'https://six.network/'),
  busd: new Token(
    MAINNET,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  earn: new Token(
    MAINNET,
    '0x579F7E6BE0a3f1bAFA4389482D97Bd6b21Bc0967',
    18,
    'EARN',
    'PlearnEarn Token',
    'https://app.plearnclub.com/',
  ),
  pcc: new Token(
    MAINNET,
    '0x84De52562B3f296CDBca31B351C7eAf92C30dd34',
    18,
    'PCC',
    'Phill Coin',
    'https://app.plearnclub.com/',
  ),
}

export const testnetTokens = {
  bnb: new Token(TESTNET, '0x6d696098d9F8B8533e1Abe94ab6372e987c4A07d', 18, 'BNB', 'BNB', 'https://www.binance.com/'),
  wbnb: new Token(
    TESTNET,
    '0x6d696098d9F8B8533e1Abe94ab6372e987c4A07d',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  busd: new Token(
    TESTNET,
    '0x6A1527497717e4E07B33D0727f893c53956CDC3b',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  usdt: new Token(
    TESTNET,
    '0xFcf2B8e399D54730a2EfCECb1B46cD8666677f37',
    18,
    'USDT',
    'Tether USD',
    'https://tether.to/',
  ),
  earn: new Token(
    TESTNET,
    '0xc0E7C501BF36fd5848919A6255dd093D3884df76',
    18,
    'EARN',
    'PlearnEarn Token',
    'https://app.plearnclub.com/',
  ),
  six: new Token(TESTNET, '0x0E31b682d52eA55A3EC1AE73f103Fa0690f0A6cf', 18, 'SIX', 'Six Token', 'https://six.network/'),
  plearn: new Token(
    TESTNET,
    '0x745A409996E30d1F609fEebeEF9aDc9a39E11f5c',
    18,
    'PLN',
    'Plearn Token',
    'https://app.plearnclub.com/',
  ),
  pcc: new Token(
    TESTNET,
    '0x9A5c779562aD3BD09C1684094c5734704dD2d10D',
    18,
    'PCC',
    'Phill Coin',
    'https://app.plearnclub.com/',
  ),
}

// Polygon
export const mainnetPolygonTokens = {
  wmatic: new Token(
    MAINNET,
    '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    18,
    'WMATIC',
    'Wrapped MATIC',
    'https://polygon.technology/',
  ),
  matic: new Token(
    MAINNET,
    '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    18,
    'MATIC',
    'MATIC',
    'https://polygon.technology/',
  ),
  usdc: new Token(
    MAINNET,
    '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    6,
    'USDC',
    'Binance-Peg USD Coin',
    'https://www.centre.io/usdc',
  ),
  plearn: new Token(
    MAINNET,
    '0x9f545eaf5fFc3A69B9b5Ffd53f7B39A549fB28cd',
    18,
    'PLN',
    'Plearn Token',
    'https://app.plearnclub.com',
  ),
  earn: new Token(
    MAINNET,
    '0x3BcD025ed8b4582DE43E604bd240f89fAf3f7F2b',
    18,
    'EARN',
    'PlearnEarn Token',
    'https://app.plearnclub.com/',
  ),
}

export const testnetPolygonTokens = {
  wmatic: new Token(
    TESTNET,
    '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    18,
    'WMATIC',
    'Wrapped MATIC',
    'https://polygon.technology/',
  ),
  matic: new Token(
    TESTNET,
    '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    18,
    'MATIC',
    'MATIC',
    'https://polygon.technology/',
  ),
  usdc: new Token(
    TESTNET,
    '0xD4B95406A058DF8183b6575D2E08eC600e219046',
    18,
    'USDC',
    'Binance-Peg USD Coin',
    'https://www.centre.io/usdc',
  ),
  earn: new Token(
    TESTNET,
    '0x151bA42C108FaF81a07B45bd58ECf6D75FE836E8',
    18,
    'EARN',
    'PlearnEarn Token',
    'https://app.plearnclub.com/',
  ),
  plearn: new Token(
    TESTNET,
    '0x8471Ee8b8a31eb231953Ab1c47350f43Dd874B7B',
    18,
    'PLN',
    'Plearn Token',
    'https://app.plearnclub.com/',
  ),
}

// Ethereum
export const mainnetETHTokens = {
  weth: new Token(
    MAINNET,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io',
  ),
  eth: new Token(MAINNET, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 18, 'ETH', 'Ether', 'https://weth.io'),
  usdc: new Token(
    MAINNET,
    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    6,
    'USDC',
    'Binance-Peg USD Coin',
    'https://www.centre.io/usdc',
  ),
  plearn: new Token(
    MAINNET,
    '0xBe0D3526fc797583Dada3F30BC390013062A048B',
    18,
    'PLN',
    'Plearn Token',
    'https://app.plearnclub.com/',
  ),
  earn: new Token(
    MAINNET,
    '0x4bEaD680DEC82B9CF4AdE72DE7049E5185770fDF',
    18,
    'EARN',
    'PlearnEarn Token',
    'https://app.plearnclub.com/',
  ),
}

export const testnetETHTokens = {
  weth: new Token(
    TESTNET,
    '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io',
  ),
  eth: new Token(TESTNET, '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6', 18, 'ETH', 'Ether', 'https://weth.io'),
  usdc: new Token(
    TESTNET,
    '0x6f8E3AD384437f965820390f2905ff9F7e307426',
    6,
    'USDC',
    'Binance-Peg USD Coin',
    'https://www.centre.io/usdc',
  ),
  earn: new Token(
    TESTNET,
    '0x151bA42C108FaF81a07B45bd58ECf6D75FE836E8',
    18,
    'EARN',
    'PlearnEarn Token',
    'https://app.plearnclub.com/',
  ),
  plearn: new Token(
    TESTNET,
    '0x69cB19A456858364b5e411b7517D35748e32B48F',
    18,
    'PLN',
    'Plearn Token',
    'https://app.plearnclub.com/',
  ),
}

export const bscTokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  const tokenList = process.env.REACT_APP_BASE_ENVIRONMENT === 'MAINNET' ? mainnetTokens : testnetTokens
  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (process.env.REACT_APP_BASE_ENVIRONMENT === 'TESTNET') {
    return Object.keys(tokenList).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const polygonTokens = (): TokenList => {
  const tokenList = process.env.REACT_APP_BASE_ENVIRONMENT === 'MAINNET' ? mainnetPolygonTokens : testnetPolygonTokens
  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (process.env.REACT_APP_BASE_ENVIRONMENT === 'TESTNET') {
    return Object.keys(tokenList).reduce((accum, key) => {
      return { ...accum, [key]: testnetPolygonTokens[key] || mainnetPolygonTokens[key] }
    }, {})
  }

  return mainnetPolygonTokens
}

export const ethTokens = (): TokenList => {
  const tokenList = process.env.REACT_APP_BASE_ENVIRONMENT === 'MAINNET' ? mainnetETHTokens : testnetETHTokens
  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (process.env.REACT_APP_BASE_ENVIRONMENT === 'TESTNET') {
    return Object.keys(tokenList).reduce((accum, key) => {
      return { ...accum, [key]: testnetETHTokens[key] || mainnetETHTokens[key] }
    }, {})
  }

  return mainnetETHTokens
}

export const serializeTokens = (chainName?: string): SerializedTokenList => {
  let unserializedTokens = bscTokens()

  if (chainName) {
    switch (chainName) {
      case ChainName.BSC: {
        unserializedTokens = bscTokens()
        break
      }
      case ChainName.POLYGON: {
        unserializedTokens = polygonTokens()
        break
      }
      case ChainName.ETHEREUM: {
        unserializedTokens = ethTokens()
        break
      }
      default:
        unserializedTokens = bscTokens()
        break
    }
  } else {
    switch (process.env.REACT_APP_BASE_CHAIN) {
      case 'BSC': {
        unserializedTokens = bscTokens()
        break
      }
      case 'POLYGON': {
        unserializedTokens = polygonTokens()
        break
      }
      case 'ETHEREUM': {
        unserializedTokens = ethTokens()
        break
      }
      default:
        unserializedTokens = bscTokens()
        break
    }
  }

  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default bscTokens()
