import { ChainId } from '@plearn/sdk'
import { BASE_ENVIRONMENT } from 'config'
import addresses from 'config/constants/contracts'
import { Address, ChainName } from 'config/constants/types'

export const getAddress = (address: Address, chainName?: string): string => {
  let _chainId = process.env.REACT_APP_CHAIN_ID

  if (chainName) {
    switch (chainName) {
      case ChainName.BSC: {
        _chainId = process.env.REACT_APP_CHAIN_ID
        break
      }
      case ChainName.POLYGON: {
        _chainId = process.env.REACT_APP_POLYGON_CHAIN_ID
        break
      }
      case ChainName.ETHEREUM: {
        _chainId = process.env.REACT_APP_ETH_CHAIN_ID
        break
      }
      default:
        _chainId = process.env.REACT_APP_CHAIN_ID
        break
    }
  } else {
    switch (process.env.REACT_APP_BASE_CHAIN) {
      case 'BSC': {
        _chainId = process.env.REACT_APP_CHAIN_ID
        break
      }
      case 'POLYGON': {
        _chainId = process.env.REACT_APP_POLYGON_CHAIN_ID
        break
      }
      case 'ETHEREUM': {
        _chainId = process.env.REACT_APP_ETH_CHAIN_ID
        break
      }
      default:
        _chainId = process.env.REACT_APP_CHAIN_ID
        break
    }
  }

  return address[_chainId] ? address[_chainId] : address[ChainId[BASE_ENVIRONMENT]]
}

export const getMasterChefAddress = (chainName?: string) => {
  return getAddress(addresses.masterChef, chainName)
}
export const getMemberPoolAddress = (chainName?: string) => {
  return getAddress(addresses.memberPool, chainName)
}
export const getPancakeBusdBnbLpAddress = () => {
  return getAddress(addresses.pancakeBusdBnbLP)
}
export const getDefinixBusdSixLpAddress = () => {
  return getAddress(addresses.definixBusdSixLP)
}
export const getMulticallAddress = (chainName?: string) => {
  return getAddress(addresses.multiCall, chainName)
}
export const getLuckyAddress = () => {
  return getAddress(addresses.lucky)
}
export const getLuckyAddressV2 = () => {
  return getAddress(addresses.luckyV2)
}
export const getConfirmBracketAddress = () => {
  return getAddress(addresses.confirmBracket)
}
export const getPlearnProfileAddress = (chainName?: string) => {
  return getAddress(addresses.plearnProfile, chainName)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}
export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getNftSaleAddress = () => {
  return getAddress(addresses.nftSale)
}
export const getPancakeSquadAddress = () => {
  return getAddress(addresses.pancakeSquad)
}
export const getPlearnNFT1HandlerAddress = () => {
  return getAddress(addresses.plearnNFT1Handler)
}
export const getPlearnNFTMintAddress = () => {
  return getAddress(addresses.plearnNFTMint)
}
export const getPlearnProfileNFTAddress = () => {
  return getAddress(addresses.plearnProfileNFT)
}
export const getPlearnWhiskeyNFTAddress = () => {
  return getAddress(addresses.plearnWhiskeyNFT)
}
