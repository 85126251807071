import { ChainId } from '@plearn/sdk'
import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'
import MAINNET_DEFAULT_TOKEN_LIST from './constants/tokenLists/pancake-mainnet.tokenlist.json'
import TESTNET_DEFAULT_TOKEN_LIST from './constants/tokenLists/pancake-testnet.tokenlist.json'
import MAINNET_POLYGON_DEFAULT_TOKEN_LIST from './constants/tokenLists/polygon-mainnet.tokenlist.json'
import TESTNET_POLYGON_DEFAULT_TOKEN_LIST from './constants/tokenLists/polygon-testnet.tokenlist.json'
import MAINNET_ETHEREUM_DEFAULT_TOKEN_LIST from './constants/tokenLists/ethereum-mainnet.tokenlist.json'
import TESTNET_ETHEREUM_DEFAULT_TOKEN_LIST from './constants/tokenLists/ethereum-testnet.tokenlist.json'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

// export const BASE_BSC_SCAN_URLS = {
//   [ChainId.MAINNET]: 'https://bscscan.com',
//   [ChainId.TESTNET]: 'https://testnet.bscscan.com',
// }

// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 0
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const BASE_ENVIRONMENT = process.env.REACT_APP_BASE_ENVIRONMENT
export const BASE_BSC_SCAN_URL =
  process.env.REACT_APP_BASE_CHAIN === 'BSC'
    ? process.env.REACT_APP_BASE_BSC_SCAN_URLS
    : process.env.REACT_APP_BASE_CHAIN === 'POLYGON'
    ? process.env.REACT_APP_BASE_POLYGON_SCAN_URLS
    : process.env.REACT_APP_BASE_ETHEREUM_SCAN_URLS // BASE_BSC_SCAN_URLS[ChainId.MAINNET]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 200000
export const DEFAULT_GAS_LIMIT_HIGH_YIELD_POOL = 350000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
// In reality its 10000 because of fast refresh, a bit less here to cover for possible long request times
export const PANCAKE_BUNNIES_UPDATE_FREQUENCY = 8000
export const DEFAULT_TOKEN_LIST =
  process.env.REACT_APP_BASE_ENVIRONMENT.toLowerCase() === 'mainnet'
    ? process.env.REACT_APP_BASE_CHAIN === 'BSC'
      ? MAINNET_DEFAULT_TOKEN_LIST
      : process.env.REACT_APP_BASE_CHAIN === 'POLYGON'
      ? MAINNET_POLYGON_DEFAULT_TOKEN_LIST
      : MAINNET_ETHEREUM_DEFAULT_TOKEN_LIST
    : process.env.REACT_APP_BASE_CHAIN === 'BSC'
    ? TESTNET_DEFAULT_TOKEN_LIST
    : process.env.REACT_APP_BASE_CHAIN === 'POLYGON'
    ? TESTNET_POLYGON_DEFAULT_TOKEN_LIST
    : TESTNET_ETHEREUM_DEFAULT_TOKEN_LIST
