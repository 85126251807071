import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'PLN',
    lpAddresses: {
      97: '0x745A409996E30d1F609fEebeEF9aDc9a39E11f5c',
      56: '0xBe0D3526fc797583Dada3F30BC390013062A048B',
    },
    token: serializedTokens.earn,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'PLN-BUSD LP',
    lpAddresses: {
      97: '0x84E5D54Ba2854cdc06b75A20eE5aa3b3925fcdec',
      56: '0x692E3596508e8ee07d55371E01D6a5fD7Cc94d8c',
    },
    token: serializedTokens.plearn,
    quoteToken: serializedTokens.busd,
  },
  {
    pid: 2,
    lpSymbol: 'PLN-BNB LP',
    lpAddresses: {
      97: '0x4f38FaFC62c480DE688fF31DB70d279AF18EaED4',
      56: '0xA089B2Ae1864B1248740789b2ac5D58125a24560',
    },
    token: serializedTokens.plearn,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 3,
    lpSymbol: 'PLN-SIX LP',
    lpAddresses: {
      97: '0x90e3Ff1A48CDCEfF6Ab5DF892D213E4448256e69',
      56: '0x6a5A241Fe4670c45d91A8a97Fc0F1266Bc1FcEb2',
    },
    token: serializedTokens.plearn,
    quoteToken: serializedTokens.six,
  },
  // Locked Farm
  {
    pid: 4,
    lpSymbol: 'PLN-BUSD LP',
    lpAddresses: {
      97: '0x84E5D54Ba2854cdc06b75A20eE5aa3b3925fcdec',
      56: '0x692E3596508e8ee07d55371E01D6a5fD7Cc94d8c',
    },
    token: serializedTokens.plearn,
    quoteToken: serializedTokens.busd,
    contractAddress: {
      97: '0xE4C6C2b25d28B117ddaA872A54D66617FE01a14C',
      56: '0xf5d8959e06f9eb18c4c6c473f539dd8a66dee271',
    },
    isLocked: true,
    lockPeriod: '3 days',
    poolWeightLockedFarm: '0',
    multiplierLockedFarm: '0',
    pendingWithdrawalContractAddress: {
      97: '0xc0D5e746dC4841D974D889C80e1B62A2476dB2F5',
      56: '0x477Ba86fFB00d3C71561492b6824DFb5DA1a5405',
    },
  },
  {
    pid: 5,
    lpSymbol: 'PLN-BNB LP',
    lpAddresses: {
      97: '0x4f38FaFC62c480DE688fF31DB70d279AF18EaED4',
      56: '0xA089B2Ae1864B1248740789b2ac5D58125a24560',
    },
    token: serializedTokens.plearn,
    quoteToken: serializedTokens.wbnb,
    contractAddress: {
      97: '0xfbDcc63b13Bc97651c655b8A2216e0ed95251087',
      56: '0x8d89fd31582f97ca8cb597261e8f655c41bf4dad',
    },
    isLocked: true,
    lockPeriod: '3 days',
    poolWeightLockedFarm: '0',
    multiplierLockedFarm: '0',
    pendingWithdrawalContractAddress: {
      97: '0xe47d6eD7715f4FB15B82052EA130CBC748602d19',
      56: '0x16CB21dDF4835EFCc1925ec1842dFfDE04e2b71C',
    },
  },
  {
    pid: 6,
    lpSymbol: 'PLN-SIX LP',
    lpAddresses: {
      97: '0x90e3Ff1A48CDCEfF6Ab5DF892D213E4448256e69',
      56: '0x6a5A241Fe4670c45d91A8a97Fc0F1266Bc1FcEb2',
    },
    token: serializedTokens.plearn,
    quoteToken: serializedTokens.six,
    contractAddress: {
      97: '0x0283e0c14E4735d61112C19263ccE837ADaf559F',
      56: '0x228fbF77Ad7562ec80438a5B63b4E3a65d9a27A4',
    },
    isLocked: true,
    lockPeriod: '3 days',
    poolWeightLockedFarm: '0',
    multiplierLockedFarm: '0',
    pendingWithdrawalContractAddress: {
      97: '0x15a276fe19C325E43f79eabF6138c7b59E825158',
      56: '0xad26006c3de913045b2e7b58fb26bfd275cb5e3a',
    },
  },
]

export default farms
