import { Flex, Skeleton, Text, useMatchBreakpoints, Button, useModal, Image } from '@plearn/uikit'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import Balance from 'components/Balance'
import { useTranslation } from 'contexts/Localization'
import React, { useEffect, useState, useMemo } from 'react'
import { AssetsData } from 'state/myInvestments/types'
import styled from 'styled-components'
import { ChainLogo, CurrencyLogo } from 'views/Info/components/CurrencyLogo'
import { formatAmount } from 'views/Info/utils/formatInfoNumbers'
import { BIG_ZERO } from 'utils/bigNumber'
import tiers from 'config/constants/tiers'
import { Tier } from 'config/constants/types'
import { DeserializedMemberPool } from 'state/types'
import { usePricePlearnBusd } from 'state/farms/hooks'
import { Break, TableWrapper, BorderBottomView, BorderBottomViewAndAlignLeft } from '../shared'
import EnableModal from '../Modals/EnableModal'
import StakeModal from '../Modals/StakeModal'

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  overflow-x: auto;
  padding: 0 24px;
`

interface TierGridProps {
  isCurrentTier: boolean
}

const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: 120px;
  grid-template-rows: repeat(6, auto);
  grid-gap: 1em;
  padding: 1em;
  border-radius: 12px;
  color: white;
`
const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const TierGrid = styled.div<TierGridProps>`
  display: grid;
  grid-template-columns: 120px;
  grid-template-rows: repeat(6, auto);
  grid-gap: 1em;
  /* align-items: center; */
  justify-content: center;
  padding: 1em;
  border-radius: 12px;
  color: white;
  border: ${(props) => (props.isCurrentTier ? '1px solid var(--Coffee, #a8884a)' : 'none')};
  background: ${(props) => (props.isCurrentTier ? 'var(--Dark-Grey, #433a30)' : 'none')};
`

const TierColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Wrapper = styled.div`
  text-decoration: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const TableLoader: React.FC = () => {
  const loadingRow = (
    <ResponsiveGrid>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </ResponsiveGrid>
  )
  return (
    <>
      {loadingRow}
      {loadingRow}
      {loadingRow}
    </>
  )
}

interface DataRowProps {
  tierInfo: Tier
  currentTierIndex: number
  isMember: boolean
  needsApproval: boolean
  depositEnabled: boolean
  onUpgrade?: (tierIndex: number) => void
}

const DataRow: React.FC<DataRowProps> = ({
  tierInfo,
  currentTierIndex,
  isMember,
  needsApproval,
  depositEnabled,
  onUpgrade,
}) => {
  return (
    <TierGrid isCurrentTier={tierInfo.id === currentTierIndex && isMember}>
      <TierColumn>
        <Flex width="100%" alignItems="center" justifyContent="center" style={{ gap: '6px' }}>
          <Image src={tierInfo.tierIcon} width={16} height={16} alt="tier-icon" />
          <Text>{tierInfo.name}</Text>
        </Flex>
        {/* <Text>{tierInfo.name}</Text> */}
      </TierColumn>
      <TierColumn>
        <Flex flexDirection="column" alignItems="center" height="30px">
          <Balance fontSize="16px" value={tierInfo.minAmount} decimals={0} unit={` PLN`} />
          {tierInfo.id === 4 && (
            <Text fontSize="7px" color="primary">
              *no maximum
            </Text>
          )}
        </Flex>
      </TierColumn>
      <TierColumn>
        <Text>{tierInfo.lockDayDuration} days</Text>
      </TierColumn>

      <TierColumn>
        <Text>{}</Text>
      </TierColumn>
      <TierColumn>
        <BorderBottomView>
          <Text color="primary">PLN</Text>
        </BorderBottomView>
      </TierColumn>
      <TierColumn>
        <Text>{tierInfo.plnBaseApr}%</Text>
      </TierColumn>
      <TierColumn>
        {tierInfo.plnLockApr === 0 ? <Text>-</Text> : <Text color="#6FE4A9">+{tierInfo.plnLockApr}%</Text>}
      </TierColumn>
      <TierColumn>
        <Text>{tierInfo.plnBaseApr + tierInfo.plnLockApr}%</Text>
      </TierColumn>

      <TierColumn>
        <Text>{}</Text>
      </TierColumn>
      <TierColumn>
        <BorderBottomView>
          <Text color="primary">PCC</Text>
        </BorderBottomView>
      </TierColumn>

      <TierColumn>
        <Text>{tierInfo.pccBaseApr}%</Text>
      </TierColumn>
      <TierColumn>
        {tierInfo.pccLockApr === 0 ? <Text>-</Text> : <Text color="#6FE4A9">+{tierInfo.pccLockApr}%</Text>}
      </TierColumn>
      <TierColumn>
        <Text>{tierInfo.pccBaseApr + tierInfo.pccLockApr}%</Text>
      </TierColumn>
      <TierColumn>
        <Text>{}</Text>
      </TierColumn>
      <TierColumn>
        {currentTierIndex === tierInfo.id && isMember ? (
          <Flex height="40px" alignItems="center">
            <Text color="primary" style={{ opacity: 0.4 }}>
              Current tier
            </Text>
          </Flex>
        ) : (
          <>
            {(currentTierIndex < tierInfo.id || !isMember) && !needsApproval && depositEnabled ? (
              <Button
                key={`upgrade-button-${tierInfo.id}`}
                variant="secondary"
                height="40px"
                disabled={false}
                onClick={() => onUpgrade && onUpgrade(tierInfo.id)}
              >
                Upgrade
              </Button>
            ) : (
              <Flex height="40px" />
            )}
          </>
        )}
      </TierColumn>
    </TierGrid>
  )
}

const titleList = [
  'Tier',
  'Minimum PLN',
  'Lock Duration',
  '',
  'PLN',
  'Base APR',
  'Lock APR',
  'Total APR',
  '',
  'Phill Coin (PCC)',
  'PCC Yield',
  'Lock PCC Yield',
  'Total PCC Yield',
  '',
  'Footer',
]

interface TierBenefitsProps {
  currentTierIndex: number
  isMember: boolean
  pool: DeserializedMemberPool
}

const TierBenefitsTable: React.FC<TierBenefitsProps> = ({ currentTierIndex, isMember, pool }) => {
  const { stakingToken, earningPlnToken, earningPcc, isFinished, userData, depositEnabled } = pool
  const [tierIndexSelected, setTierIndexSelected] = useState(null)
  const [tierIndexUpdate, setTierIndexUpdate] = useState(false)
  const stakingTokenBalance = userData?.stakingTokenBalance ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO
  const plnPrice = usePricePlearnBusd()
  const stakingTokenPrice = plnPrice.toNumber()

  const allowance = userData?.allowance ? new BigNumber(userData.allowance) : BIG_ZERO
  const needsApproval = !allowance.gt(0)

  const [onPresentStake] = useModal(
    <StakeModal
      pool={pool}
      stakingTokenBalance={stakingTokenBalance}
      stakingTokenPrice={stakingTokenPrice}
      isUpgrade
      tierIndexForUpgrade={tierIndexSelected}
    />,
  )

  useEffect(() => {
    if (tierIndexSelected != null && tierIndexUpdate) {
      onPresentStake()
      setTierIndexUpdate(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tierIndexSelected, tierIndexUpdate])

  const onUpgrade = (tierIndex: number) => {
    setTierIndexSelected(tierIndex)
    setTierIndexUpdate(true)
  }
  return (
    <TableWrapper>
      <ResponsiveGrid>
        <TitleGrid>
          {titleList.map((title, index) => (
            <React.Fragment key={title}>
              <TitleColumn>
                {title === 'PLN' || title === 'Phill Coin (PCC)' ? (
                  <BorderBottomViewAndAlignLeft>
                    <Text color="primary">{title}</Text>
                  </BorderBottomViewAndAlignLeft>
                ) : (
                  <>
                    {title === 'Footer' ? (
                      <Flex height="40px">
                        <Text>{}</Text>
                      </Flex>
                    ) : (
                      <>
                        {title === 'Minimum PLN' ? (
                          <Flex height="30px">
                            <Text>{title}</Text>
                          </Flex>
                        ) : (
                          <Text>{title}</Text>
                        )}
                      </>
                    )}
                  </>
                )}
              </TitleColumn>
            </React.Fragment>
          ))}
        </TitleGrid>
        <>
          {tiers.map((data, i) => (
            <React.Fragment key={data.name}>
              {data.id > 0 && (
                <DataRow
                  tierInfo={data}
                  currentTierIndex={currentTierIndex}
                  isMember={isMember}
                  needsApproval={needsApproval}
                  depositEnabled={depositEnabled}
                  onUpgrade={(tierIndex) => onUpgrade(tierIndex)}
                />
              )}
            </React.Fragment>
          ))}
          <Break />
        </>
      </ResponsiveGrid>
    </TableWrapper>
  )
}

export default TierBenefitsTable
