import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import useRefresh from 'hooks/useRefresh'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { BIG_ZERO } from 'utils/bigNumber'
import { deserializeToken } from 'state/user/hooks/helpers'
import { fetchMemberPoolPublicDataAsync, fetchMemberPoolUserDataAsync } from '.'
import { DeserializedMemberPool, SerializedMemberPool, State } from '../types'

export const deserializeMemberPool = (pool: SerializedMemberPool): DeserializedMemberPool => {
  const { depositEnabled, totalStaked, userData, stakingToken, earningPlnToken, earningPcc, ...rest } = pool
  return {
    ...rest,
    depositEnabled,
    stakingToken: deserializeToken(stakingToken),
    earningPlnToken: deserializeToken(earningPlnToken),
    earningPcc: deserializeToken(earningPcc),
    totalStaked: totalStaked ? new BigNumber(totalStaked) : BIG_ZERO,
    userData: {
      allowance: userData ? new BigNumber(userData.allowance) : BIG_ZERO,
      stakingTokenBalance: userData ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO,
      stakedBalance: userData ? new BigNumber(userData.stakedBalance) : BIG_ZERO,
      pendingPlnReward: userData ? new BigNumber(userData.pendingPlnReward) : BIG_ZERO,
      pendingPccReward: userData ? new BigNumber(userData.pendingPccReward) : BIG_ZERO,
      tierIndex: userData ? Number(userData.tierIndex) : -1,
      endLockTime: userData ? Number(userData.endLockTime) : 0,
      depositStartDay: userData ? Number(userData.depositStartDay) : 0,
    },
  }
}

export const useFetchUserPools = (account) => {
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchMemberPoolUserDataAsync({ account }))
    }
  }, [account, dispatch, fastRefresh])
}

export const usePollMemberPoolData = () => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchMemberPoolPublicDataAsync())
  }, [dispatch, fastRefresh])
}

// export const usePools = (): { memberPool: DeserializedMemberPool; userDataLoaded: boolean } => {
//   const { data, userDataLoaded } = useSelector((state: State) => ({
//     data: state.memberPool.data,
//     userDataLoaded: state.memberPool.userDataLoaded,
//   }))
//   return { memberPool: deserializeMemberPool(data), userDataLoaded }
// }

export const usePools = (): { memberPool: DeserializedMemberPool; userDataLoaded: boolean } => {
  const memberPoolState = useSelector((state: State) => state.memberPool)

  // ตรวจสอบก่อนว่า memberPoolState ไม่ใช่ undefined
  if (!memberPoolState) {
    // จัดการกับกรณีที่ memberPoolState เป็น undefined
    console.error('memberPoolState is undefined')
    return { memberPool: undefined, userDataLoaded: false }
  }

  const { data, userDataLoaded } = memberPoolState
  return { memberPool: deserializeMemberPool(data), userDataLoaded }
}

export const usePollMemberPoolWithUserData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    if (account) {
      dispatch(fetchMemberPoolUserDataAsync({ account }))
    }
  }, [includeArchive, dispatch, slowRefresh, account])
}
