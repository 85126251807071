import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { DEFAULT_GAS_LIMIT } from 'config'
import { useMemberPool } from 'hooks/useContract'
import { useCallback } from 'react'
import { useAppDispatch } from 'state'
import { fetchMemberPoolUserDataAsync } from 'state/memberPool'
import { BIG_TEN } from 'utils/bigNumber'
import getGasPrice from 'utils/getGasPrice'
import { calculateGasMargin } from 'utils'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

const memberPoolStake = async (memberPoolContract, tierIndex, amount, decimals = 18) => {
  const gasEstimate = await memberPoolContract.estimateGas.deposit(
    tierIndex,
    new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString(),
  )
  const gasLimit = calculateGasMargin(gasEstimate)
  const gasLimitOptions = {
    gasLimit,
  }

  const tx = await memberPoolContract.deposit(
    tierIndex,
    new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString(),
    {
      ...gasLimitOptions,
    },
  )
  const receipt = await tx.wait()
  return receipt.status
}

const useStakePool = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const memberPoolContract = useMemberPool()

  const handleStake = useCallback(
    async (amount: string, decimals: number, tierIndex: number) => {
      await memberPoolStake(memberPoolContract, tierIndex, amount, decimals)

      dispatch(fetchMemberPoolUserDataAsync({ account }))
    },
    [account, dispatch, memberPoolContract],
  )

  return { onStake: handleStake }
}

export default useStakePool
