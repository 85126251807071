import collections from 'config/constants/nftsCollections'
import { CollectionKey } from 'config/constants/nftsCollections/types'
import { getAddress } from 'utils/addressHelpers'

export const nftsBaseUrl = '/marketplace'
export const NFT_PHASE1_TIMESTAMP = process.env.REACT_APP_NFT_PHASE1_TIMESTAMP
export const NFT_PHASE1_OPEN = new Date() > new Date(Number(NFT_PHASE1_TIMESTAMP))
export const pancakeBunniesAddress = getAddress(collections[CollectionKey.PANCAKE].address)
export const pancakeSquadAddress = getAddress(collections[CollectionKey.SQUAD].address)
export const plearnApeAddress = getAddress(collections[CollectionKey.APE].address)
export const plearnWhiskeyAddress = getAddress(collections[CollectionKey.WHISKEY].address)
