import React from 'react'
import { Button, Text, useModal, Flex, Skeleton, Heading } from '@plearn/uikit'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { PoolCategory } from 'config/constants/types'
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import { BIG_ZERO } from 'utils/bigNumber'
import { DeserializedMemberPool } from 'state/types'
import { usePricePlearnBusd } from 'state/farms/hooks'

import { ActionContainer, ActionTitles, ActionContent } from './styles'
import CollectModal from '../Modals/CollectModal'

interface HarvestActionProps {
  pool: DeserializedMemberPool
  userDataLoaded: boolean
}

const HarvestAction: React.FunctionComponent<HarvestActionProps> = ({ pool, userDataLoaded }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { stakingToken, earningPlnToken, earningPcc, isFinished, userData } = pool

  const plnPrice = usePricePlearnBusd()
  const earningTokenPrice = plnPrice.toNumber()

  const earnings = userData?.pendingPlnReward ? new BigNumber(userData.pendingPlnReward) : BIG_ZERO
  const earningTokenBalance = getBalanceNumber(earnings, earningPlnToken.decimals)
  const earningTokenDollarBalance = getBalanceNumber(earnings.multipliedBy(earningTokenPrice), earningPlnToken.decimals)
  const hasEarnings = earnings.gt(0)
  const fullBalance = getFullDisplayBalance(earnings, earningPlnToken.decimals)
  const formattedBalance = formatNumber(earningTokenBalance, 3, 3)
  const isCompoundPool = true

  const earningsPcc = userData?.pendingPccReward ? new BigNumber(userData.pendingPccReward) : BIG_ZERO
  const earningPccBalance = getBalanceNumber(earningsPcc, earningPlnToken.decimals)

  const [onPresentCollect] = useModal(
    <CollectModal
      formattedBalance={formattedBalance}
      fullBalance={fullBalance}
      earningPlnToken={earningPlnToken}
      earningsDollarValue={earningTokenDollarBalance}
      earningPccCoin={earningPcc}
      isCompoundPool={false}
    />,
  )

  const actionTitle = (
    <>
      <Text fontSize="12px" bold color="primary" as="span" textTransform="uppercase">
        {earningPlnToken.symbol}, PCC{' '}
      </Text>
      <Text fontSize="12px" bold color="textSubtle" as="span" textTransform="uppercase">
        {t('Earned')}
      </Text>
    </>
  )

  if (!account) {
    return (
      <ActionContainer>
        <ActionTitles>{actionTitle}</ActionTitles>
        <ActionContent>
          <Heading>0</Heading>
          <Button disabled>{isCompoundPool ? t('Collect') : t('Collect')}</Button>
        </ActionContent>
      </ActionContainer>
    )
  }

  if (!userDataLoaded) {
    return (
      <ActionContainer>
        <ActionTitles>{actionTitle}</ActionTitles>
        <ActionContent>
          <Skeleton width={180} height="32px" marginTop={14} />
        </ActionContent>
      </ActionContainer>
    )
  }

  return (
    <ActionContainer>
      <ActionTitles>{actionTitle}</ActionTitles>
      <ActionContent>
        <Flex flex="1" pt="16px" flexDirection="column" alignSelf="flex-start">
          <>
            {hasEarnings ? (
              <>
                <Balance lineHeight="1" bold fontSize="18px" decimals={5} value={earningTokenBalance} unit=" PLN" />
                {/* <Balance lineHeight="1" bold fontSize="20px" decimals={5} value={earningTokenBalance} prefix="PLN: " /> */}
                {earningTokenPrice > 0 && (
                  <Balance
                    display="inline"
                    fontSize="12px"
                    color="textSubtle"
                    decimals={2}
                    prefix="~"
                    value={earningTokenDollarBalance}
                    unit=" USD"
                  />
                )}
                <Balance
                  lineHeight="1"
                  bold
                  fontSize="18px"
                  decimals={5}
                  value={earningPccBalance}
                  unit=" PCC"
                  mt="7px"
                />
              </>
            ) : (
              <>
                <Heading color="textDisabled">0</Heading>
                <Text fontSize="12px" color="textDisabled">
                  0 USD
                </Text>
              </>
            )}
          </>
        </Flex>
        <Button disabled={!hasEarnings} onClick={onPresentCollect}>
          {isCompoundPool ? t('Collect') : t('Harvest')}
        </Button>
      </ActionContent>
    </ActionContainer>
  )
}

export default HarvestAction
