import BigNumber from 'bignumber.js'
import memberPoolConfig from 'config/constants/memberPool'
import { BIG_ZERO } from 'utils/bigNumber'
import { getMemberPoolAddress } from 'utils/addressHelpers'
import { getBep20Contract, getMemberPoolContract } from 'utils/contractHelpers'

export const fetchMemberPoolUserAllowances = async (account: string) => {
  const memberPoolAddress = getMemberPoolAddress()
  const stakingTokenContract = getBep20Contract(memberPoolConfig.stakingToken.address)
  const allowance = await stakingTokenContract.allowance(account, memberPoolAddress)

  return new BigNumber(allowance._hex).toJSON()
}

export const fetchMemberPoolUserTokenBalances = async (account: string) => {
  const stakingTokenContract = getBep20Contract(memberPoolConfig.stakingToken.address)
  const tokenBalances = await stakingTokenContract.balanceOf(account)
  return new BigNumber(tokenBalances._hex).toJSON()
}

export const fetchMemberPoolUserStakedBalances = async (account: string) => {
  const memberPoolContract = getMemberPoolContract()
  const stakedBalance = await memberPoolContract.userInfo(account)
  return new BigNumber(stakedBalance[0]._hex).toJSON()
}

export const fetchMemberPoolUserEarnings = async (account: string) => {
  const memberPoolContract = getMemberPoolContract()
  const userInfo = await memberPoolContract.getUserInfo(account)
  const plnEarning = new BigNumber(userInfo[2]._hex).toJSON()
  const pccEarning = new BigNumber(userInfo[3]._hex).toJSON()

  return {
    plnEarning,
    pccEarning,
  }
}

export const fetchMemberPoolDepositEnabled = async () => {
  const memberPoolContract = getMemberPoolContract()
  const getDepositEndDay = await memberPoolContract.depositEndDay()
  const depositEndDay = new BigNumber(getDepositEndDay)

  return depositEndDay.gt(BIG_ZERO)
}

export const fetchUserInfo = async (account: string) => {
  const memberPoolContract = getMemberPoolContract()

  const [info, currenDay, accruedInterest, pccAccruedInterest, endLockTime] = await memberPoolContract.getUserInfo(
    account,
  )
  const stakedBalance = new BigNumber(info[0]._hex).toJSON()
  const plnEarning = new BigNumber(accruedInterest._hex).toJSON()
  const pccEarning = new BigNumber(pccAccruedInterest._hex).toJSON()
  const tierIndex = new BigNumber(info.tierIndex._hex).toJSON()
  const _endLockTime = new BigNumber(endLockTime).toJSON()
  const depositStartDay = new BigNumber(info[1]).toJSON()
  return {
    tierIndex,
    endLockTime: _endLockTime,
    stakedBalance,
    plnEarning,
    pccEarning,
    depositStartDay,
  }
}

export const fetchMemberPoolTotalStaking = async () => {
  const memberPoolAddress = getMemberPoolAddress()
  const stakingTokenContract = getBep20Contract(memberPoolConfig.stakingToken.address)
  const totalStaked = await stakingTokenContract.balanceOf(memberPoolAddress)

  return new BigNumber(totalStaked._hex).toJSON()
}
