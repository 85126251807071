import { request, gql } from 'graphql-request'
import { BASE_API } from 'config/constants/endpoints'
import { CurrentRankResponse, RankTier } from './types'
import { getGraphPlearnPoolRanking } from './getRankingData'

// const getGraphCurrentUserRank = async (id: string): Promise<CurrentRankResponse> => {
//   try {
//     const response = await request(
//       API_PLEARN_POOLRANKING,
//       gql`
//         query getCurrentUserRank($id: String) {
//           ranking(id: $id) {
//             user
//             totalStaked
//             rank
//             tier {
//               name
//             }
//           }
//           tiers(orderBy: minAmount) {
//             name
//             minAmount
//           }
//         }
//       `,
//       { id },
//     )
//     return {
//       ranking: response.ranking,
//       tiers: response.tiers,
//     }
//   } catch (error) {
//     console.error(error)
//     return null
//   }
// }

const getGraphCurrentUserRank = async (account: string): Promise<CurrentRankResponse> => {
  try {
    const tiersResponse = await fetch(`${BASE_API}/tiers`)
    const tiers: RankTier[] = await tiersResponse.json()
    tiers.sort((a, b) => (a.minimum < b.minimum ? -1 : 1))
    const { rankings } = await getGraphPlearnPoolRanking()
    const ranking = rankings.find((rank) => rank.user === account)

    return {
      ranking,
      tiers,
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

const getCurrentUserRank = async (account: string): Promise<CurrentRankResponse> => {
  const graphResponse = await getGraphCurrentUserRank(account)
  return graphResponse
}

export default getCurrentUserRank
