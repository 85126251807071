import React from 'react'
import styled from 'styled-components'
import { Button } from '@plearn/uikit'

interface TierButtonProps {
  onClick: () => void
  isSelected: boolean
  isDisabled: boolean
}

const StyledButton = styled(Button)<{ isSelected: boolean; isDisabled: boolean }>`
  // Use the prop here
  /* flex-grow: 1; */
  border-radius: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.primary};

  ${({ isSelected, theme }) =>
    isSelected &&
    `
      background-color: ${theme.colors.primary};
      color: ${theme.colors.backgroundAlt};
    `}

  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
      opacity: 0.2;
      pointer-events: none; 
    `}
  &:hover {
    ${({ isDisabled, theme }) =>
      !isDisabled &&
      `
        background-color: ${theme.colors.primary};
        color: ${theme.colors.backgroundAlt};
      `}
  }
`

const TierButton: React.FC<TierButtonProps> = ({ children, onClick, isSelected, isDisabled }) => {
  return (
    <StyledButton
      scale="sm"
      //   mx="2px"
      //   p="4px 16px"
      mb="4px"
      mt="4px"
      mr="4px"
      variant="tertiary"
      onClick={onClick}
      isSelected={isSelected}
      isDisabled={isDisabled}
    >
      {children}
    </StyledButton>
  )
}

export default TierButton
