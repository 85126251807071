import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { calculateCountdown } from './calculateCountdown'

const FlipStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;

  .countdown-col {
    margin: 0 12px;

    &:last-child strong:after {
      display: none;
    }
  }

  strong,
  span {
    display: block;
    text-align: center;
  }

  strong {
    background: ${({ theme }) => theme.colors.cardBorder};
    padding: 1rem 0.5rem;
    color: ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.radii.default};
    margin-bottom: 0.5rem;
    font-weight: 100;
    font-size: 24px;
    position: relative;
    width: 48px;
    text-align: center;

    &:before {
      content: '';
      width: 100%;
      height: 50%;
      background: #474747;
      border-radius: 15px 15px 0px 0px;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      content: ':';
      position: absolute;
      top: calc(50% - 12px);
      right: -14px;
      font-size: 24px;
      color: initial;
      font-weight: initial;
    }
  }

  span {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.textSubtle};
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    strong {
      background: #474747; // ${({ theme }) => theme.colors.cardBorder};
      padding: 1rem;
      margin-bottom: 1rem;
      font-size: 64px;
      width: 116px;
    }

    span {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.textSubtle};
    }
  }
`

const FlipStyledSmallBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .countdown-col {
    margin: 0 12px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    &:last-child strong:after {
      display: none;
    }
  }

  strong,
  span {
    display: block;
    text-align: center;
  }

  strong {
    background: #474747; // ${({ theme }) => theme.colors.cardBorder};
    border: 1px solid ${({ theme }) => theme.colors.cardBorder};
    padding: 10px;
    color: ${({ theme }) => theme.colors.primary};
    border-radius: 12px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 24px;
    position: relative;
    width: 54px;
    text-align: center;

    /* &:before {
      content: '';
      width: 100%;
      height: 50%;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 15px 15px 0px 0px;
      position: absolute;
      top: 0;
      left: 0;
    } */

    &:after {
      content: ':';
      position: absolute;
      top: calc(50% - 12px);
      right: -14px;
      font-size: 24px;
      color: ${({ theme }) => theme.colors.textSubtle};
      font-weight: initial;
    }
  }

  span {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.textSubtle};
  }
`
const Flip = ({ date, small = false, smallBg = false, color = '#ffffff' }) => {
  const [timer, setTime] = useState({
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
  })

  const addLeadingZeros = (value) => {
    let val = String(value)
    while (val.length < 2) {
      val = `0${val}`
    }
    return val
  }

  const FlipColumn = () => (
    <>
      <div className="countdown-col">
        <span>{timer.days === 1 ? 'Day' : 'Days'}</span>
        <strong>{addLeadingZeros(timer.days)}</strong>
      </div>

      <div className="countdown-col">
        <span>{timer.hours === 1 ? 'Hour' : 'Hours'}</span>
        <strong>{addLeadingZeros(timer.hours)}</strong>
      </div>

      <div className="countdown-col">
        <span>{timer.min === 1 ? 'Minute' : 'Minutes'}</span>
        <strong>{addLeadingZeros(timer.min)}</strong>
      </div>

      <div className="countdown-col">
        <span>{timer.sec === 1 ? 'Second' : 'Seconds'}</span>
        <strong>{addLeadingZeros(timer.sec)}</strong>
      </div>
    </>
  )

  useEffect(() => {
    const interval = setInterval(() => {
      const d = calculateCountdown(date)

      if (d) {
        setTime(d)
      } else {
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [date])

  if (small) {
    return (
      <strong style={{ color, display: 'inline-block', width: '200px' }}>
        {`
    ${addLeadingZeros(timer.days)} :

    ${addLeadingZeros(timer.hours)} :

    ${addLeadingZeros(timer.min)} :

    ${addLeadingZeros(timer.sec)}
    `}
      </strong>
    )
  }

  if (smallBg) {
    return (
      <FlipStyledSmallBg>
        <FlipColumn />
      </FlipStyledSmallBg>
    )
  }

  return (
    <FlipStyled>
      <FlipColumn />
    </FlipStyled>
  )
}

export default Flip
