import { request, gql } from 'graphql-request'
import { GRAPH_API_LUCKY } from 'config/constants/endpoints'
import { LuckyDrawStatus } from 'config/constants/luckydraw'
import { LuckyDrawRoundGraphEntity, LuckyDrawNodeResponse } from 'state/types'
// import { BigNumber } from 'ethers'
// import { ethersToSerializedBigNumber } from 'utils/bigNumber'
import { getRoundIdsArray, fetchMultipleLucky } from './helpers'

export const MAX_LUCKIES_REQUEST_SIZE = 100

/* eslint-disable camelcase */
type LuckiesWhere = { id_in?: string[] }

const applyNodeDataToLuckiesGraphResponse = (
  nodeData: LuckyDrawNodeResponse[],
  graphResponse: LuckyDrawRoundGraphEntity[],
): LuckyDrawRoundGraphEntity[] => {
  //   If no graph response - return node data
  if (graphResponse.length === 0) {
    console.debug('no graph response - return node data!')
    return nodeData.map((nodeRound) => {
      return {
        endTime: nodeRound.endTime,
        luckyNumbers: nodeRound.luckyNumbers,
        startTime: nodeRound.startTime,
        status: nodeRound.status,
        id: nodeRound.luckyId.toString(),
        ticketPrice: nodeRound.priceTicketInPlearn,
        totalTickets: '',
        totalUsers: '',
        randomTx: null,
        burnTx: null,
        brackets: [
          {
            index: 1,
            yatch: '-',
            port: '-',
            startTime: 0,
            endTime: 0,
            total: 0,
          },
          {
            index: 2,
            yatch: '-',
            port: '-',
            startTime: 0,
            endTime: 0,
            total: 0
          },
        ],
      }
    })
  }

  // Populate all nodeRound data with supplementary graphResponse round data when available
  const nodeRoundsWithGraphData = nodeData
    .filter((nodeRoundData) => nodeRoundData.status !== LuckyDrawStatus.PENDING)
    .map((nodeRoundData) => {
      const graphRoundData = graphResponse.find((graphResponseRound) => graphResponseRound.id === nodeRoundData.luckyId)
      return {
        endTime: nodeRoundData.endTime,
        // luckyNumbers: nodeRoundData.luckyNumbers.map((number) => ethersToSerializedBigNumber(BigNumber.from(number))),
        luckyNumbers: nodeRoundData.luckyNumbers,
        startTime: nodeRoundData.startTime,
        status: nodeRoundData.status,
        id: nodeRoundData.luckyId,
        ticketPrice: graphRoundData?.ticketPrice,
        totalTickets: graphRoundData?.totalTickets,
        totalUsers: graphRoundData?.totalUsers,
        brackets: graphRoundData?.brackets,
        randomTx: graphRoundData?.randomTx,
        burnTx: graphRoundData?.burnTx,
      }
    })

  // Return the rounds with combined node + subgraph data, plus all remaining subgraph rounds.
  const [lastCombinedDataRound] = nodeRoundsWithGraphData.slice(-1)
  const lastCombinedDataRoundIndex = graphResponse
    .map((graphRound) => graphRound?.id)
    .indexOf(lastCombinedDataRound?.id)

  const remainingSubgraphRounds = graphResponse ? graphResponse.splice(lastCombinedDataRoundIndex + 1) : []
  const mergedResponse = [...nodeRoundsWithGraphData, ...remainingSubgraphRounds]
  return mergedResponse
}

export const getGraphLuckies = async (
  first = MAX_LUCKIES_REQUEST_SIZE,
  skip = 0,
  where: LuckiesWhere = {},
): Promise<LuckyDrawRoundGraphEntity[]> => {
  // console.debug(`Graph URL: ${GRAPH_API_LUCKY}`)
  try {
    const response = await request(
      GRAPH_API_LUCKY,
      gql`
        query getLuckies($first: Int!, $skip: Int!, $where: Lucky_filter) {
          luckies(first: $first, skip: $skip, where: $where, orderDirection: desc, orderBy: block) {
            id
            status
            startTime
            endTime
            totalUsers
            totalTickets
            totalPlearn
            luckyNumbers
            ticketPrice
            burnTx
            randomTx
            brackets {
              index
              yatch
              port
              startTime
              endTime
              total
            }
          }
        }
      `,
      { skip, first, where },
    )
    return response.luckies
  } catch (error) {
    console.error(error)
    return []
  }
}

const getLuckiesData = async (currentLotteryId: string): Promise<LuckyDrawRoundGraphEntity[]> => {
  const idsForNodesCall = getRoundIdsArray(currentLotteryId)
  const nodeData = await fetchMultipleLucky(idsForNodesCall)
  const graphResponse = await getGraphLuckies()
  const mergedData = applyNodeDataToLuckiesGraphResponse(nodeData, graphResponse)
  return mergedData
}

export default getLuckiesData
