import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import sousChefABI from 'config/abi/sousChef.json'
import masterchefABI from 'config/abi/masterchef.json'
import pendingWithdrawalABI from 'config/abi/pendingWithdrawal.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import { SerializedFarmConfig } from 'config/constants/types'
import { LockedBalances, LockData } from 'state/types'

export const fetchFarmUserAllowances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const masterChefFarms = farmsToFetch.filter((farm) => !farm.isLocked)
  const lockedFarms = farmsToFetch.filter((farm) => farm.isLocked)

  const calls = masterChefFarms.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return { address: lpContractAddress, name: 'allowance', params: [account, masterChefAddress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })


  const lockedFarmCalls = lockedFarms.map((farm) => ({
    address: getAddress(farm.lpAddresses),
    name: 'allowance',
    params: [account, getAddress(farm.contractAddress)],
  }))

  const rawLpAllowancesLockedFarm = await multicall(erc20ABI, lockedFarmCalls)
  const parsedLpAllowancesLockedFarm  = rawLpAllowancesLockedFarm.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  const lpAllowances = parsedLpAllowances.concat(...parsedLpAllowancesLockedFarm)


  return lpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const masterChefFarms = farmsToFetch.filter((farm) => !farm.isLocked)
  const lockedFarms = farmsToFetch.filter((farm) => farm.isLocked)

  const calls = masterChefFarms.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(masterchefABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })


  const lockFarmsCalls = lockedFarms.map((farm) => ({
    address: getAddress(farm.contractAddress),
    name: 'userInfo',
    params: [account],
  }))
  const userInfo = await multicall(sousChefABI, lockFarmsCalls)

  const parsedStakedBalancesLockedFarm = userInfo.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  const stakedBalances = parsedStakedBalances.concat(...parsedStakedBalancesLockedFarm)
  return stakedBalances
}

export const fetchFarmUserEarnings = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const masterChefFarms = farmsToFetch.filter((farm) => !farm.isLocked)
  const lockedFarms = farmsToFetch.filter((farm) => farm.isLocked)

  const calls = masterChefFarms.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'pendingPlearn',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })

  const lockedFarmsCalls = lockedFarms.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingReward',
    params: [account],
  }))
  const rawEarningsLockedFarms = await multicall(sousChefABI, lockedFarmsCalls)
  const parsedEarningsLockedFarms = rawEarningsLockedFarms.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })

  const earnings = parsedEarnings.concat(...parsedEarningsLockedFarms)

  return earnings
}

// High-yield Farm

export const fetchUserLockedBalances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const lockedFarms = farmsToFetch.filter((farm) => farm.isLocked)
  const pendingWithdrawalCalls = lockedFarms.map((p) => ({
    address: getAddress(p.pendingWithdrawalContractAddress),
    name: 'lockedBalances',
    params: [account],
  }))

  const res = await multicall(pendingWithdrawalABI, pendingWithdrawalCalls)

  const lockedBalances = res.map((p, index) => {

    const lockData: [LockData] = res[index].lockData.map((data) => {
      const lock: LockData = {
        amount: new BigNumber(data.amount.toString()).toJSON(),
        unlockTime: new BigNumber(data.unlockTime.toString()).toJSON(),
      }
      return lock
    })
    
    const initialState: LockedBalances = {
      total: new BigNumber(res[index].total.toString()).toJSON(),
      unlockable: new BigNumber(res[index].unlockable.toString()).toJSON(),
      locked: new BigNumber(res[index].locked.toString()).toJSON(),
      lockData: lockData === undefined ? undefined : lockData,
    }
    
    return initialState
  })

  const lockedFarmsLockedBalances = lockedFarms.reduce(
    (acc, farm, index) => ({
      ...acc,
      [farm.pid]: lockedBalances[index],
    }),
    {},
  )

  return { ...lockedFarmsLockedBalances }
}
