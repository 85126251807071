import { useWeb3React } from '@web3-react/core'
import { DEFAULT_GAS_LIMIT } from 'config'
import { useMemberPool } from 'hooks/useContract'
import { useCallback } from 'react'
import { useAppDispatch } from 'state'
import { fetchMemberPoolUserDataAsync } from 'state/memberPool'
import getGasPrice from 'utils/getGasPrice'
import { calculateGasMargin } from 'utils'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

const harvestMemberPool = async (memberPoolContract) => {
  const gasEstimate = await memberPoolContract.estimateGas.harvest()
  const gasLimit = calculateGasMargin(gasEstimate)
  const gasLimitOptions = {
    gasLimit,
  }

  const tx = await memberPoolContract.harvest({ ...gasLimitOptions })
  const receipt = await tx.wait()
  return receipt.status
}

const useHarvestPool = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const memberPoolContract = useMemberPool()

  const handleHarvest = useCallback(async () => {
    await harvestMemberPool(memberPoolContract)
    dispatch(fetchMemberPoolUserDataAsync({ account }))
  }, [account, dispatch, memberPoolContract])

  return { onReward: handleHarvest }
}

export default useHarvestPool
