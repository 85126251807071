import poolsConfig, { FOUNDING_POOL_ID, NON_FOUNDING_POOL_ID, LOCKED_POOL_ID } from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import smartChefFoundingInvestorABI from 'config/abi/smartChefFoundingInvestor.json'
import pendingWithdrawalABI from 'config/abi/pendingWithdrawal.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getMasterchefContract } from 'utils/contractHelpers'
import { getAddress } from 'utils/addressHelpers'
import { simpleRpcProvider } from 'utils/providers'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { BASE_ENVIRONMENT } from 'config'
import { LockedBalances, LockData } from 'state/types'

// Pool 0, Cake / Cake is a different kind of contract (master chef)
// Pool 2, Plearn / Plearn is a different kind of contract (smart chef founding investor)
// BNB pools use the native BNB token (wrapping ? unwrapping is done at the contract level)
const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== 'BNB')
const bnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol === 'BNB')
const nonMasterPools = poolsConfig.filter((pool) => pool.sousId !== NON_FOUNDING_POOL_ID )
const foundingInvestorPools = poolsConfig.filter((pool) => FOUNDING_POOL_ID.includes(pool.sousId))
const lockedPools = poolsConfig.filter((pool) => LOCKED_POOL_ID.includes(pool.sousId))
const masterChefContract = getMasterchefContract()

export const fetchPoolsAllowance = async (account) => {
  const calls = nonBnbPools.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'allowance',
    params: [account, getAddress(pool.contractAddress)],
  }))

  const allowances = await multicall(erc20ABI, calls)
  return nonBnbPools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(allowances[index]).toJSON() }),
    {},
  )
}

export const fetchUserBalances = async (account) => {
  // Non BNB pools
  const calls = nonBnbPools.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'balanceOf',
    params: [account],
  }))
  const tokenBalancesRaw = await multicall(erc20ABI, calls)
  const tokenBalances = nonBnbPools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(tokenBalancesRaw[index]).toJSON() }),
    {},
  )

  // BNB pools
  const bnbBalance = await simpleRpcProvider.getBalance(account)
  const bnbBalances = bnbPools.reduce(
    (acc, pool) => ({ ...acc, [pool.sousId]: new BigNumber(bnbBalance.toString()).toJSON() }),
    {},
  )

  return { ...tokenBalances, ...bnbBalances }
}

export const fetchUserStakeBalances = async (account) => {
  const nonMasterAndFoundingPools = nonMasterPools.filter((pool) => !FOUNDING_POOL_ID.includes(pool.sousId))
  const calls = nonMasterAndFoundingPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [account],
  }))
  const userInfo = await multicall(sousChefABI, calls)
  
  const stakedBalances = nonMasterAndFoundingPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(userInfo[index].amount._hex).toJSON(),
    }),
    {},
  )

  const foundingInvestorPoolCalls = foundingInvestorPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [account],
  }))
  const foundingInvestorPoolUserInfo = await multicall(smartChefFoundingInvestorABI, foundingInvestorPoolCalls)
  
  const foundingInvestorPoolStakedBalances = foundingInvestorPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(foundingInvestorPoolUserInfo[index].amount._hex).toJSON(),
    }),
    {},
  )

  
  const nonMasterPoolStakedBalances = Object.fromEntries(Object.entries(stakedBalances).filter(([key]) => !FOUNDING_POOL_ID.includes(Number(key)) && Number(key) !== NON_FOUNDING_POOL_ID && !LOCKED_POOL_ID.includes(Number(key)) ));

  // const foundingInvestorPoolStakedBalances = Object.fromEntries(Object.entries(stakedBalances).filter(([key]) => FOUNDING_POOL_ID.includes(Number(key))));

  const lockedPoolStakedBalances = Object.fromEntries(Object.entries(stakedBalances).filter(([key]) => LOCKED_POOL_ID.includes(Number(key))));
  
  // Cake / Cake pool
  const { amount: masterPoolAmount } = await masterChefContract.userInfo('0', account)

  return { 
    ...nonMasterPoolStakedBalances,
    [NON_FOUNDING_POOL_ID]: new BigNumber(masterPoolAmount.toString()).toJSON(),
    ...foundingInvestorPoolStakedBalances,
    ...lockedPoolStakedBalances
  }
}

export const fetchUserPendingRewards = async (account) => {
  const calls = nonMasterPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingReward',
    params: [account],
  }))
  const res = await multicall(sousChefABI, calls)
  const pendingRewards = nonMasterPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(res[index]).toJSON(),
    }),
    {},
  )

  const nonMasterPoolPendingRewards = Object.fromEntries(Object.entries(pendingRewards).filter(([key]) => !FOUNDING_POOL_ID.includes(Number(key)) && Number(key) !== NON_FOUNDING_POOL_ID && !LOCKED_POOL_ID.includes(Number(key)) ));

  const foundingInvestorPoolPendingRewards = Object.fromEntries(Object.entries(pendingRewards).filter(([key]) => FOUNDING_POOL_ID.includes(Number(key))));

  const lockedPoolPendingRewards = Object.fromEntries(Object.entries(pendingRewards).filter(([key]) => LOCKED_POOL_ID.includes(Number(key))));

  // // Cake / Cake pool
  const pendingReward = await masterChefContract.pendingPlearn('0', account)

  return { 
    ...nonMasterPoolPendingRewards, 
    [NON_FOUNDING_POOL_ID]: new BigNumber(pendingReward.toString()).toJSON(), 
    ...foundingInvestorPoolPendingRewards,
    ...lockedPoolPendingRewards
  }
}

// Founding Investor pool
export const fetchUserPendingUnlockedToken = async (account) => { 
  const foundingInvestorPoolCalls = foundingInvestorPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingUnlockedToken',
    params: [account],
  }))
  const res = await multicall(smartChefFoundingInvestorABI, foundingInvestorPoolCalls)
  
  const foundingInvestorPendingUnlockedToken = foundingInvestorPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(res[index].toString()).toJSON(),
    }),
    {},
  )


  return { ...foundingInvestorPendingUnlockedToken }
}

export const fetchUserIsFoundingInvestor = async (account) => { 
  const foundingInvestorPoolCalls = foundingInvestorPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'isInvestor',
    params: [account],
  }))
  const res = await multicall(smartChefFoundingInvestorABI, foundingInvestorPoolCalls)
  
  const isInvestor = foundingInvestorPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: (res[index].toString()).toLowerCase() === 'true',
    }),
    {},
  )

  return { ...isInvestor }
}

// High-yield Pool

export const fetchUserLockedBalances = async (account: string) => {
  const pendingWithdrawalCalls = lockedPools.map((p) => ({
    address: getAddress(p.pendingWithdrawalContractAddress),
    name: 'lockedBalances',
    params: [account],
  }))

  const res = await multicall(pendingWithdrawalABI, pendingWithdrawalCalls)

  const lockedBalances = res.map((p, index) => {

    const lockData: [LockData] = res[index].lockData.map((data) => {
      const lock: LockData = {
        amount: new BigNumber(data.amount.toString()).toJSON(),
        unlockTime: new BigNumber(data.unlockTime.toString()).toJSON(),
      }
      return lock
    })
    
    const initialState: LockedBalances = {
      total: new BigNumber(res[index].total.toString()).toJSON(),
      unlockable: new BigNumber(res[index].unlockable.toString()).toJSON(),
      locked: new BigNumber(res[index].locked.toString()).toJSON(),
      lockData: lockData === undefined ? undefined : lockData,
    }
    
    return initialState
  })

  const lockedPoolsLockedBalances = lockedPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: lockedBalances[index],
    }),
    {},
  )

  return { ...lockedPoolsLockedBalances }
}
