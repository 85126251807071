import React, { useState } from 'react'
import {
  Modal,
  Text,
  Button,
  Heading,
  Flex,
  AutoRenewIcon,
  ButtonMenu,
  ButtonMenuItem,
  HelpIcon,
  useTooltip,
} from '@plearn/uikit'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useToast from 'hooks/useToast'
import { Token } from '@plearn/sdk'
import { formatNumber } from 'utils/formatBalance'
import useClaimPendingWithdrawalLockedPool from '../../../hooks/useClaimPendingWithdrawal'


interface ClaimModalProps {
  formattedBalance: string
  fullBalance: string
  earningToken: Token
  earningsDollarValue: number
  sousId: number
  pendingWithdrawalContractAddress: string
  isBnbPool: boolean
  isCompoundPool?: boolean
  onDismiss?: () => void
}

const ClaimModal: React.FC<ClaimModalProps> = ({
  formattedBalance,
  fullBalance,
  earningToken,
  earningsDollarValue,
  sousId,
  pendingWithdrawalContractAddress,
  isBnbPool,
  isCompoundPool = false,
  onDismiss,
}) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { toastSuccess, toastError } = useToast()
  const { onClaim } = useClaimPendingWithdrawalLockedPool(sousId, pendingWithdrawalContractAddress)
  const [pendingTx, setPendingTx] = useState(false)

  const handleClaimConfirm = async () => {
    setPendingTx(true)
    // claim
    try {
        await onClaim()
        toastSuccess(
          `${t('Claimed')}!`,
          t('Your %symbol% earnings have been sent to your wallet!', { symbol: earningToken.symbol }),
        )
        setPendingTx(false)
        onDismiss()
      } catch (e) {
        toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
        console.error(e)
        setPendingTx(false)
      }
  }

  return (
    <Modal
      title={`${earningToken.symbol} ${ t('Claim')}`}
      onDismiss={onDismiss}
      headerBackground={theme.colors.gradients.cardHeader}
    >
      <Flex justifyContent="space-between" alignItems="center" mb="24px">
        <Text>{t('Balance')}:</Text>
        <Flex flexDirection="column">
          <Heading>
            {formattedBalance} {earningToken.symbol}
          </Heading>
          {earningsDollarValue > 0 && (
            <Text fontSize="12px" color="textSubtle">{`~${formatNumber(earningsDollarValue)} USD`}</Text>
          )}
        </Flex>
      </Flex>

      <Button
        mt="8px"
        onClick={handleClaimConfirm}
        isLoading={pendingTx}
        endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
      >
        {pendingTx ? t('Confirming') : t('Confirm')}
      </Button>
      <Button variant="text" onClick={onDismiss} pb="0px">
        {t('Close Window')}
      </Button>
    </Modal>
  )
}

export default ClaimModal
