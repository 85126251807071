import React, { useState } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Modal, ModalBody, Text, Button, Heading, Flex, Box, useTooltip } from '@plearn/uikit'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useToast from 'hooks/useToast'
import Balance from 'components/Balance'
import { formatNumber, getBalanceNumber } from 'utils/formatBalance'
import { AutoRow } from 'components/Layout/Row'
import { LockData } from 'state/types'

const UnlockedState = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.colors.primary};
`

const BorderBottomViewStyle = styled(Box)`
  flex-wrap: wrap;
  border-bottom: 1px solid #525252;
  margin-bottom: 20px;
`

function renderUnlockTime(locks: [LockData]) {
  return (
    <Flex flexDirection="column">
      {locks.map((lock) => {
        const unixTime = parseInt(lock.unlockTime)
        const newEndDate = new Date(unixTime * 1000)
        const endDateString = newEndDate.toLocaleString()
        const newStartDate = new Date()

        const oneDay = 1000 * 60 * 60 * 24
        const oneHour = 1000 * 60 * 60

        const unlockDays = Math.ceil((newEndDate.getTime() - newStartDate.getTime()) / oneDay)
        const unlockHours = Math.ceil((newEndDate.getTime() - newStartDate.getTime()) / oneHour)

        let unlockTime
        if (unlockDays > 1) {
          unlockTime = `${unlockDays} days`
        } else if (unlockHours > 1) {
          unlockTime = `in ${unlockHours} hours`
        } else if (unlockHours > 0) {
          unlockTime = `in 1 hour`
        }

        const { targetRef, tooltip, tooltipVisible } = useTooltip(
          <Text fontSize="14px" color="black">
            {endDateString}
          </Text>,
          {
            placement: 'top-end',
            tooltipOffset: [20, 10],
          },
        )

        return (
          <UnlockedState key={lock.amount + lock.unlockTime}>
            <AutoRow mb="1rem" style={{ justifyContent: 'space-between' }}>
              <Balance
                color="textSubtle"
                lineHeight="1"
                fontSize="14px"
                decimals={5}
                value={getBalanceNumber(new BigNumber(lock.amount))}
              />

              <span ref={targetRef}>
                <Text fontSize="14px" color="textSubtle">
                  {unlockTime}
                </Text>
              </span>
              {tooltipVisible && tooltip}
            </AutoRow>
          </UnlockedState>
        )
      })}
    </Flex>
  )
}

interface ViewUserLocksProps {
  sousId: number
  lockData: [LockData]
  onDismiss?: () => void
}

const ViewUserLocks: React.FC<ViewUserLocksProps> = ({ sousId, lockData, onDismiss }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  return (
    <Modal title={`${t('Unstake')}`} onDismiss={onDismiss} headerBackground={theme.colors.gradients.cardHeader}>
      <ModalBody>
        {lockData && lockData.length > 0 ? (
          <>
            <AutoRow mb="20px" style={{ justifyContent: 'space-between' }}>
              <Text fontSize="16px" color="primary" as="span">
                {t('Amount')}
              </Text>
              <Text fontSize="16px" color="primary" as="span">
                {t('Release on')}
              </Text>
            </AutoRow>
            <BorderBottomViewStyle>
              <Text>{t('')}</Text>
            </BorderBottomViewStyle>
            {renderUnlockTime(lockData)}
          </>
        ) : (
          <Text>{t('No recent unstake')}</Text>
        )}
      </ModalBody>
    </Modal>
  )
}

export default ViewUserLocks
