import { useWeb3React } from '@web3-react/core'
import { parseUnits } from 'ethers/lib/utils'
import { useMemberPool } from 'hooks/useContract'
import { useCallback } from 'react'
import { useAppDispatch } from 'state'
import { fetchMemberPoolUserDataAsync } from 'state/memberPool'
import getGasPrice from 'utils/getGasPrice'
import { calculateGasMargin } from 'utils'

const memberPoolUnstake = async (memberPoolContract, amount, decimals = 18) => {
  const units = parseUnits(amount, decimals)

  const gasEstimate = await memberPoolContract.estimateGas.withdraw(units.toString())
  const gasLimit = calculateGasMargin(gasEstimate)
  const gasLimitOptions = {
    gasLimit,
  }

  const tx = await memberPoolContract.withdraw(units.toString(), {
    ...gasLimitOptions,
  })

  const receipt = await tx.wait()
  return receipt.status
}

const useUnstakePool = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const memberPoolContract = useMemberPool()

  const handleUnstake = useCallback(
    async (amount: string, decimals: number) => {
      await memberPoolUnstake(memberPoolContract, amount, decimals)

      dispatch(fetchMemberPoolUserDataAsync({ account }))
    },
    [account, dispatch, memberPoolContract],
  )

  return { onUnstake: handleUnstake }
}

export default useUnstakePool
