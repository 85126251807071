export const getBaseNftFields = () => `
  tokenId
  metadataUrl
  currentOfferId
  currentAskPrice
  currentAskToken
  currentSeller
  currentOfferExpire
  latestTradedPrice
  latestTradedToken
  highestTradePrice
  tradeVolumeBNB
  totalTrades
  isTradable
  createdAt
  updatedAt
  owner
  collection {
    id
  }
`

export const getBaseTransactionFields = () => `
  id
  block
  timestamp
  askPrice
  netPrice
  dealToken
  buyer {
    id
  }
  seller {
    id
  }
  side
  withBNB
`

export const getCollectionBaseFields = () => `
  id
  name
  symbol
  active
  totalTrades
  totalVolumeBNB
  numberTokensListed
  creatorAddress
  creatorFee
  tradingFee
`
