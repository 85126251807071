import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'
import { farmsConfig } from 'config/constants'
import useRefresh from 'hooks/useRefresh'
import { deserializeToken } from 'state/user/hooks/helpers'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync, nonArchivedFarms } from '.'
import { State, SerializedFarm, DeserializedFarmUserData, DeserializedFarm, DeserializedFarmsState } from '../types'

const deserializeFarmUserData = (farm: SerializedFarm): DeserializedFarmUserData => {
  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
    lockedBalances: farm.userData ? farm.userData.lockedBalances : undefined,
  }
}

export const deserializeFarm = (farm: SerializedFarm): DeserializedFarm => {
  const {
    lpAddresses,
    lpSymbol,
    pid,
    dual,
    multiplier,
    isCommunity,
    isLocked,
    lockPeriod,
    pendingWithdrawalContractAddress,
    contractAddress,
    quoteTokenPriceBusd,
    tokenPriceBusd,
  } = farm
  return {
    lpAddresses,
    lpSymbol,
    pid,
    dual,
    multiplier,
    isCommunity,
    isLocked,
    lockPeriod,
    pendingWithdrawalContractAddress,
    contractAddress,
    quoteTokenPriceBusd,
    tokenPriceBusd,
    token: deserializeToken(farm.token),
    quoteToken: deserializeToken(farm.quoteToken),
    userData: deserializeFarmUserData(farm),
    tokenAmountTotal: farm.tokenAmountTotal ? new BigNumber(farm.tokenAmountTotal) : BIG_ZERO,
    lpTotalInQuoteToken: farm.lpTotalInQuoteToken ? new BigNumber(farm.lpTotalInQuoteToken) : BIG_ZERO,
    lpTotalSupply: farm.lpTotalSupply ? new BigNumber(farm.lpTotalSupply) : BIG_ZERO,
    tokenPriceVsQuote: farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO,
    poolWeight: farm.poolWeight ? new BigNumber(farm.poolWeight) : BIG_ZERO,
  }
}

export const usePollFarmsPublicData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync(pids))
  }, [includeArchive, dispatch, slowRefresh])
}

export const usePollFarmsWithUserData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync(pids))

    if (account) {
      dispatch(fetchFarmUserDataAsync({ account, pids }))
    }
  }, [includeArchive, dispatch, slowRefresh, account])
}

/**
 * Fetches the "core" farm data used globally
 * 1 = PLN-BUSD LP
 * 2 = PLN-SIX LP
 */
export const usePollCoreFarmData = () => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync([1, 2, 3, 4, 5, 6]))
  }, [dispatch, fastRefresh])
}

export const useFarms = (): DeserializedFarmsState => {
  const farms = useSelector((state: State) => state.farms)
  const deserializedFarmsData = farms.data.map(deserializeFarm)
  const { loadArchivedFarmsData, userDataLoaded } = farms
  return {
    loadArchivedFarmsData,
    userDataLoaded,
    data: deserializedFarmsData,
  }
}

export const useFarmFromPid = (pid: number): DeserializedFarm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return deserializeFarm(farm)
}

export const useFarmFromLpSymbol = (lpSymbol: string): DeserializedFarm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return deserializeFarm(farm)
}

export const useFarmUser = (pid): DeserializedFarmUserData => {
  const { userData } = useFarmFromPid(pid)
  const { allowance, tokenBalance, stakedBalance, earnings, lockedBalances } = userData
  return {
    allowance,
    tokenBalance,
    stakedBalance,
    earnings,
    lockedBalances,
  }
}

// Return the base token price for a farm, from a given pid
export const useBusdPriceFromPid = (pid: number): BigNumber => {
  const farm = useFarmFromPid(pid)
  return farm && new BigNumber(farm.tokenPriceBusd)
}

export const useLpTokenPrice = (symbol: string) => {
  const farm = useFarmFromLpSymbol(symbol)
  const farmTokenPriceInUsd = useBusdPriceFromPid(farm.pid)
  let lpTokenPrice = BIG_ZERO

  if (farm.lpTotalSupply.gt(0) && farm.lpTotalInQuoteToken.gt(0)) {
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(farm.tokenAmountTotal)
    // Double it to get overall value in LP
    const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
    // Divide total value of all tokens, by the number of LP tokens
    const totalLpTokens = getBalanceAmount(farm.lpTotalSupply)
    lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
  }

  return lpTokenPrice
}

// /!\ Deprecated , use the BUSD hook in /hooks

export const usePricePlearnBusd = (): BigNumber => {
  const plearnBusdFarm = useFarmFromPid(1)

  const plearnPriceBusdAsString = plearnBusdFarm.tokenPriceBusd

  const plearnPriceBusd = useMemo(() => {
    return new BigNumber(plearnPriceBusdAsString)
  }, [plearnPriceBusdAsString])

  return plearnPriceBusd
}

export const useBNBPriceBusd = (): BigNumber => {
  const plearnBnbFarm = useFarmFromPid(2)

  const bnbPriceBusdAsString = plearnBnbFarm.quoteTokenPriceBusd

  const bnbPriceBusd = useMemo(() => {
    return new BigNumber(bnbPriceBusdAsString)
  }, [bnbPriceBusdAsString])

  return bnbPriceBusd
}

export const useSixPriceBusd = (): BigNumber => {
  const plearnSixFarm = useFarmFromPid(3)

  const sixPriceBusdAsString = plearnSixFarm.quoteTokenPriceBusd

  const sixPriceBusd = useMemo(() => {
    return new BigNumber(sixPriceBusdAsString)
  }, [sixPriceBusdAsString])

  return sixPriceBusd
}

export const useFarmTVL = (): BigNumber => {
  const plearnBusdFarm = useFarmFromPid(1)
  const plearnBnbFarm = useFarmFromPid(2)
  const plearnSixFarm = useFarmFromPid(3)
  const plearnBusdLockedFarm = useFarmFromPid(4)
  const plearnBnbLockedFarm = useFarmFromPid(5)
  const plearnSixLockedFarm = useFarmFromPid(6)

  const plearnBusdFarmTotalLiquidity = plearnBusdFarm.lpTotalInQuoteToken.times(plearnBusdFarm.quoteTokenPriceBusd)
  const plearnBnbFarmTotalLiquidity = plearnBnbFarm.lpTotalInQuoteToken.times(plearnBnbFarm.quoteTokenPriceBusd)
  const plearnSixFarmTotalLiquidity = plearnSixFarm.lpTotalInQuoteToken.times(plearnSixFarm.quoteTokenPriceBusd)

  const plearnBusdLockedFarmTotalLiquidity = plearnBusdLockedFarm.lpTotalInQuoteToken.times(
    plearnBusdFarm.quoteTokenPriceBusd,
  )
  const plearnBnbLockedFarmTotalLiquidity = plearnBnbLockedFarm.lpTotalInQuoteToken.times(
    plearnBnbFarm.quoteTokenPriceBusd,
  )
  const plearnSixLockedFarmTotalLiquidity = plearnSixLockedFarm.lpTotalInQuoteToken.times(
    plearnSixFarm.quoteTokenPriceBusd,
  )

  const sum = plearnBusdFarmTotalLiquidity
    .plus(plearnBnbFarmTotalLiquidity)
    .plus(plearnSixFarmTotalLiquidity)
    .plus(plearnBusdLockedFarmTotalLiquidity)
    .plus(plearnBnbLockedFarmTotalLiquidity)
    .plus(plearnSixLockedFarmTotalLiquidity)

  const sumAsString = sum

  const totalLiquidity = useMemo(() => {
    return new BigNumber(sumAsString)
  }, [sumAsString])

  return totalLiquidity
}
