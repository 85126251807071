import { request, gql } from 'graphql-request'
import { GRAPH_API_LUCKY } from 'config/constants/endpoints'
import { LuckyDrawTicket } from 'config/constants/luckydraw'
import { LotteryUserGraphEntity, LuckyDrawNodeResponse, UserRound } from 'state/types'
import { getRoundIdsArray, fetchMultipleLucky, hasRoundBeenClaimed } from './helpers'
import { fetchUserTicketsForMultipleRounds } from './getUserTicketsData'

export const MAX_USER_LOTTERIES_REQUEST_SIZE = 200

/* eslint-disable camelcase */
type UserLuckiesWhere = { lucky_in?: string[] }

const applyNodeDataToUserGraphResponse = (
  userNodeData: { roundId: string; userTickets: LuckyDrawTicket[] }[],
  userGraphRounds: UserRound[],
  luckyNodeData: LuckyDrawNodeResponse[],
): UserRound[] => {
  //   If no graph rounds response - return node data
  // console.debug('applyNodeDataToUserGraphResponse')
  // console.debug({ rounds: userGraphRounds, luckyNodeData })
  if (userGraphRounds.length === 0) {
    console.debug('no graph rounds response - return node data')
    return luckyNodeData.map((nodeRound) => {
      const ticketDataForRound = userNodeData.find((roundTickets) => roundTickets.roundId === nodeRound.luckyId)
      return {
        endTime: nodeRound.endTime,
        status: nodeRound.status,
        luckyId: nodeRound.luckyId.toString(),
        claimed: hasRoundBeenClaimed(ticketDataForRound.userTickets),
        totalTickets: `${ticketDataForRound.userTickets.length.toString()}`,
        tickets: ticketDataForRound.userTickets,
      }
    })
  }

  // Return the rounds with combined node + subgraph data, plus all remaining subgraph rounds.
  const nodeRoundsWithGraphData = userNodeData.map((userNodeRound) => {
    const userGraphRound = userGraphRounds.find(
      (graphResponseRound) => graphResponseRound.luckyId === userNodeRound.roundId,
    )
    const nodeRoundData = luckyNodeData.find((nodeRound) => nodeRound.luckyId === userNodeRound.roundId)

    return {
      endTime: nodeRoundData.endTime,
      status: nodeRoundData.status,
      luckyId: userNodeRound.roundId,
      claimed: hasRoundBeenClaimed(userNodeRound.userTickets), // คิดว่าไม่ได้ใช้แล้ว อาจจะเอาออกในอนาคต
      totalTickets: userGraphRound?.totalTickets || userNodeRound.userTickets.length.toString(),
      tickets: userGraphRound?.tickets ?? userNodeRound.userTickets ?? [], // (userNodeRound.userTickets.length > 0) ? userNodeRound.userTickets : userGraphRound?.tickets ?? []
    }
  })

  const nodeIds = nodeRoundsWithGraphData.map((r) => r.luckyId)
  const remainingSubgraphRounds = userGraphRounds.filter((r) => nodeIds.indexOf(r.luckyId) < 0)
  const remainingSubgraphRoundsFixed = remainingSubgraphRounds.map((remainingSubgraph) => {
    return {
      endTime: remainingSubgraph.endTime,
      status: remainingSubgraph.status,
      luckyId: remainingSubgraph.luckyId,
      totalTickets: remainingSubgraph.totalTickets,
      tickets: remainingSubgraph.tickets ?? [],
    }
  })

  const mergedResponse = [...nodeRoundsWithGraphData, ...remainingSubgraphRoundsFixed]
  // เอาเฉพาะรอบที่ user มีการซื้อ ticket
  const mergedResponseWithTickets = mergedResponse.filter((round) => round.tickets.length > 0)
  return mergedResponseWithTickets
}

export const getGraphLotteryUser = async (
  account: string,
  first = MAX_USER_LOTTERIES_REQUEST_SIZE,
  skip = 0,
  where: UserLuckiesWhere = {},
): Promise<LotteryUserGraphEntity> => {
  let userRes: any
  const blankUser: LotteryUserGraphEntity = {
    account,
    totalPlearn: '0',
    totalTickets: '0',
    rounds: [],
  }

  try {
    const response = await request(
      GRAPH_API_LUCKY,
      gql`
        query getUserRounds($account: ID!, $first: Int!, $skip: Int!, $where: UserRound_filter) {
          user(id: $account) {
            id
            totalTickets
            totalPlearn
            rounds(first: $first, skip: $skip, where: $where, orderDirection: desc, orderBy: block) {
              lucky {
                id
                endTime
                status
              }
              totalTickets
              tickets(first: 1000) {
                id
                number
                name
                isHaveFollower
                isLucky
                bracket
                luckyId
              }
            }
          }
        }
      `,
      { account, first, skip, where },
    )
    userRes = response.user
    console.debug(userRes)
    // If no user returned - return blank user
    if (!userRes) {
      return blankUser
    }
  } catch (error) {
    console.error(error)
    return blankUser
  }

  const formattedUser = {
    account: userRes.id,
    totalPlearn: userRes.totalPlearn,
    totalTickets: userRes.totalTickets,
    rounds: userRes.rounds.map((round) => ({
      luckyId: round.lucky.id,
      endTime: round.lucky.endTime,
      totalTickets: round.totalTickets,
      status: round.lucky.status.toLowerCase(),
      tickets: round.tickets.map((ticket) => ({
        id: ticket.id,
        number: ticket.number,
        name: ticket.name ?? '',
        isHaveFollower: ticket.isHaveFollower ?? null,
        isLucky: ticket.isLucky,
        bracket: ticket.bracket ? Number(ticket.bracket) : 0,
        roundId: round.lucky.id,
      })),
    })),
  }

  // console.debug(formattedUser)
  return formattedUser
}

const getUserLuckyData = async (account: string, currentLuckyId: string): Promise<LotteryUserGraphEntity> => {
  const idsForTicketsNodeCall = getRoundIdsArray(currentLuckyId).filter((round) => Number(round) > 0)
  const roundDataAndUserTickets = await fetchUserTicketsForMultipleRounds(idsForTicketsNodeCall, account)
  const idsForUserLuckydrawNodeCall = roundDataAndUserTickets.map((round) => round.roundId)
  const lotteriesNodeData = await fetchMultipleLucky(idsForUserLuckydrawNodeCall) // จะไป fetch เฉพาะ round ที่ user มีการซื้อ ticket ไว้ และรอบปัจจุบันด้วย
  const graphResponse = await getGraphLotteryUser(account)

  const mergedRoundData = applyNodeDataToUserGraphResponse(
    roundDataAndUserTickets,
    graphResponse.rounds,
    lotteriesNodeData,
  )

  const graphResponseWithNodeRounds = { ...graphResponse, rounds: mergedRoundData }

  return graphResponseWithNodeRounds
}

export default getUserLuckyData
