import React from 'react'
import styled from 'styled-components'
import { Box } from '@plearn/uikit'
import Container from '../Layout/Container'
import { PageHeaderProps } from './types'

const Outer = styled(Box)<{ background?: string }>`
  margin-top: 24px;
  background: '${({ theme, background }) => background || theme.colors.gradients.violetAlt}';
`

const Inner = styled(Container)`
  padding-top: 32px;
  padding-bottom: 32px;
`

const PageHeader: React.FC<PageHeaderProps> = ({ background, children, ...props }) => (
  <Outer id="outerPageHeader" background={background} {...props}>
    <Inner>{children}</Inner>
  </Outer>
)

export default PageHeader
