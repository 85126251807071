export const TICKET_LIMIT_PER_ROUND = 1000
export const NUM_ROUNDS_TO_CHECK_FOR_REWARDS = 2
export const NUM_ROUNDS_TO_FETCH_FROM_NODES = 3 // The rest will be fetch subgraph

export enum LuckyDrawStatus {
  PENDING = 'pending', // 0 initial (default) status
  OPEN = 'open', // 1 กำลังเปิดขาย
  CLOSE = 'close', // 2 ปิดรอบ ไม่ให้ซื้อ ticket แล้ว แต่ยังไม่ได้ประกาศรางวัล
  CLAIMABLE = 'claimable', // 3 ประกาศรางวัลแล้ว -> ยังเคลมรางวัลได้
  FINISH = 'finish', // 4 ปิดรอบ ไม่ให้เคลมรางวัลแล้ว
}

export interface LuckyDrawTicket {
  id: string; // The unique ticket id for all round
  number: string; // Ticket Number, eg: 102003
  name?: string; // Transfer to name
  isHaveFollower?: boolean;
  isLucky: boolean;
  bracket: number; // If user is not confirm, bracket is 0, if user has confirmed then bracket is not 0
  roundId?: string;
}


export interface LuckyDrawTicketClaimData {
  winningTickets: LuckyDrawTicket[]
  roundId: string
}