import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ChainName } from 'config/constants/types'
import { PortfolioState as State, ChainFilter } from './types'

const initialPorfolioState: State = {
  chainFilter: 'all',
}

export const Portfolio = createSlice({
  name: 'Portfolio',
  initialState: initialPorfolioState,
  reducers: {
    setChainFilter: (state, action: PayloadAction<{ chain: string }>) => {
      state.chainFilter = action.payload.chain
    },
  },
})

// Actions
export const { setChainFilter } = Portfolio.actions

export default Portfolio.reducer
