import { Currency } from '@plearn/sdk'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { pancakeBunniesAddress } from 'views/Nft/market/constants'
import { getPlearnNFTContract } from 'utils/contractHelpers'
import { simpleBSCRpcProvider, simplePolygonRpcProvider } from 'utils/providers'
import {
  getNftsFromCollectionApi,
  getNftsMarketData,
  getCollectionsApi,
  getCollectionsSg,
  getUserActivity,
  combineCollectionData,
  getCollectionSg,
  getCollectionApi,
  getNftsFromDifferentCollectionsApi,
  getCompleteAccountNftData,
  getNftsByBunnyIdSg,
  getMarketDataForTokenIds,
  getMetadataWithFallback,
  getPancakeBunniesAttributesField,
  combineApiAndSgResponseToNftToken,
  fetchNftsFiltered,
  getLowestPriceInCollection,
  getDealTokensSg,
} from './helpers'
import {
  State,
  Collection,
  ApiCollections,
  TokenIdWithCollectionAddress,
  NFTMarketInitializationState,
  UserNftInitializationState,
  MarketEvent,
  NftToken,
  NftLocation,
  ApiSingleTokenData,
  NftAttribute,
  NftFilterLoadingState,
  NftActivityFilter,
  ApiCollectionDistribution,
  CollectionMarketDataBaseFields,
} from './types'

const initialNftActivityFilterState: NftActivityFilter = {
  typeFilters: [],
  collectionFilters: [],
}

const initialNftMarketState: State = {
  initializationState: NFTMarketInitializationState.UNINITIALIZED,
  data: {
    dealTokens: [],
    collections: {},
    nfts: {},
    filters: {
      loadingState: NftFilterLoadingState.IDLE,
      activeFilters: {},
      showOnlyOnSale: false,
      ordering: {
        field: 'tokenId',
        direction: 'asc',
      },
      searchInput: '',
    },
    activityFilters: {},
    activityOrdering: {
      field: 'All time',
      duration: 0,
    },
    loadingState: {
      isUpdatingPancakeBunnies: false,
      latestPancakeBunniesUpdateAt: 0,
    },
    users: {},
    user: {
      userNftsInitializationState: UserNftInitializationState.UNINITIALIZED,
      nfts: [],
      activity: {
        initializationState: UserNftInitializationState.UNINITIALIZED,
        offerHistory: [],
        buyTradeHistory: [],
        sellTradeHistory: [],
      },
    },
  },
}

/**
 * Fetch all collections data by combining data from the API (static metadata) and the Subgraph (dynamic market data)
 */

export const fetchDealTokens = createAsyncThunk<Currency[]>('nft/fetchDealTokens', async () => {
  // const bnbToken = { decimals: 18, symbol: 'BNB', name: 'BNB' }
  const dealTokensSg = await getDealTokensSg()
  return dealTokensSg
})

export const fetchCollections = createAsyncThunk<Record<string, Collection>>('nft/fetchCollections', async () => {
  const [collections, collectionsMarket] = await Promise.all([getCollectionsApi(), getCollectionsSg()])
  const collectionsMarketWithLowestPrice = await Promise.all(
    collectionsMarket.map(async (item): Promise<CollectionMarketDataBaseFields> => {
      const lowestCollectionPrice = await getLowestPriceInCollection(item.id)
      const collection: CollectionMarketDataBaseFields = {
        ...item,
        lowestPrice: String(lowestCollectionPrice),
      }
      return {
        ...item,
        ...collection,
      }
    }),
  )

  return combineCollectionData(collections, collectionsMarketWithLowestPrice)
})

/**
 * Fetch collection data by combining data from the API (static metadata) and the Subgraph (dynamic market data)
 */
export const fetchCollection = createAsyncThunk<Record<string, Collection>, string>(
  'nft/fetchCollection',
  async (collectionAddress) => {
    const [collection, collectionMarket] = await Promise.all([
      getCollectionApi(collectionAddress),
      getCollectionSg(collectionAddress),
    ])
    const lowestCollectionPrice = await getLowestPriceInCollection(collectionMarket?.id)
    const collectionWithLowestPrice: CollectionMarketDataBaseFields = {
      ...collectionMarket,
      lowestPrice: String(lowestCollectionPrice),
    }

    return combineCollectionData([collection], [collectionWithLowestPrice])
  },
)

/**
 * Fetch all NFT data for a collections by combining data from the API (static metadata)
 * and the Subgraph (dynamic market data)
 * @param collectionAddress
 */
export const fetchNftsFromCollections = createAsyncThunk<
  NftToken[],
  { collectionAddress: string; page: number; size: number }
>('nft/fetchNftsFromCollections', async ({ collectionAddress, page, size }) => {
  try {
    if (collectionAddress === pancakeBunniesAddress) {
      // PancakeBunnies don't need to pre-fetch "all nfts" from the collection
      // When user visits IndividualNFTPage required nfts will be fetched via bunny id
      return []
    }

    const nfts = await getNftsFromCollectionApi(collectionAddress, size, page)

    if (!nfts?.data) {
      return []
    }

    // Filter with minted token ids
    const { getMintedTokens, maxSupply } = getPlearnNFTContract(collectionAddress, simpleBSCRpcProvider)
    const nftMaxSupply = await maxSupply()
    const mintedTokenIds = await getMintedTokens(0, Number(nftMaxSupply))
    const tokenIds = mintedTokenIds[0].map(Number)
    const sortTokenIds = tokenIds.sort((a, b) => (a < b ? -1 : 1)).map(String)
    // const tokenIds = Object.values(nfts.data).map((nft) => nft.tokenId)
    // const nftsMarket = await getMarketDataForTokenIds(collectionAddress, tokenIds)
    const nftsMarket = await getMarketDataForTokenIds(collectionAddress, sortTokenIds)

    return sortTokenIds.map((id) => {
      const apiMetadata = nfts.data[id]
      const marketData = nftsMarket.find((nft) => nft.tokenId === id)

      return {
        tokenId: id,
        name: apiMetadata.name,
        description: apiMetadata.description,
        collectionName: apiMetadata.collection.name,
        collectionAddress,
        image: apiMetadata.image,
        attributes: apiMetadata.attributes,
        marketData,
        rarityScore: apiMetadata.score,
      }
    })
  } catch (error) {
    console.error(`Failed to fetch collection NFTs for ${collectionAddress}`, error)
    return []
  }
})

export const filterNftsFromCollection = createAsyncThunk<
  NftToken[],
  { collectionAddress: string; nftFilters: Record<string, NftAttribute> }
>('nft/filterNftsFromCollection', async ({ collectionAddress, nftFilters }) => {
  try {
    const attrParams = Object.values(nftFilters).reduce(
      (accum, attr) => ({
        ...accum,
        [attr.traitType]: attr.value,
      }),
      {},
    )
    const attrFilters = await fetchNftsFiltered(collectionAddress, attrParams)

    // Filter with minted token ids
    const { getMintedTokens, maxSupply } = getPlearnNFTContract(collectionAddress)
    const nftMaxSupply = await maxSupply()
    const mintedTokenIds = await getMintedTokens(0, Number(nftMaxSupply))
    const tokenIdsList = mintedTokenIds[0].map(Number)

    // Fetch market data for each token returned
    const tokenIds = Object.values(attrFilters.data).map((apiToken) => apiToken.tokenId)
    const marketData = await getNftsMarketData({ tokenId_in: tokenIds, collection: collectionAddress })

    const data = Object.values(attrFilters.data).filter((nft) => tokenIdsList.includes(Number(nft.tokenId)))

    const nftTokens: NftToken[] = data.map((apiToken) => {
      const apiTokenMarketData = marketData.find((tokenMarketData) => tokenMarketData.tokenId === apiToken.tokenId)

      return {
        tokenId: apiToken.tokenId,
        name: apiToken.name,
        description: apiToken.description,
        collectionName: apiToken.collection.name,
        collectionAddress,
        image: apiToken.image,
        attributes: apiToken.attributes,
        marketData: apiTokenMarketData,
      }
    })

    return nftTokens
  } catch {
    return []
  }
})

/**
 * This action keeps data on the individual PancakeBunny page up-to-date. Operation is a twofold
 * 1. Update existing NFTs in the state in case some were sold or got price modified
 * 2. Fetch 30 more NFTs with specified bunny id
 */
export const fetchNewPBAndUpdateExisting = createAsyncThunk<
  NftToken[],
  {
    bunnyId: string
    existingTokensWithBunnyId: string[]
    allExistingPBTokenIds: string[]
    existingMetadata: ApiSingleTokenData
    orderDirection: 'asc' | 'desc'
  }
>(
  'nft/fetchNewPBAndUpdateExisting',
  async ({ bunnyId, existingTokensWithBunnyId, allExistingPBTokenIds, existingMetadata, orderDirection }) => {
    try {
      // 1. Update existing NFTs in the state in case some were sold or got price modified
      const [updatedNfts, updatedNftsMarket] = await Promise.all([
        getNftsFromCollectionApi(pancakeBunniesAddress),
        getMarketDataForTokenIds(pancakeBunniesAddress, allExistingPBTokenIds),
      ])

      if (!updatedNfts?.data) {
        return []
      }
      const updatedTokens = updatedNftsMarket.map((marketData) => {
        const apiMetadata = getMetadataWithFallback(updatedNfts.data, '')
        const attributes = getPancakeBunniesAttributesField('')
        return combineApiAndSgResponseToNftToken(apiMetadata, marketData, attributes)
      })

      // 2. Fetch 30 more NFTs with specified bunny id
      let newNfts = { data: { [bunnyId]: existingMetadata } }

      if (!existingMetadata) {
        newNfts = await getNftsFromCollectionApi(pancakeBunniesAddress)
      }
      const nftsMarket = await getNftsByBunnyIdSg(bunnyId, existingTokensWithBunnyId, orderDirection)

      if (!newNfts?.data) {
        return updatedTokens
      }

      const moreTokensWithRequestedBunnyId = nftsMarket.map((marketData) => {
        const apiMetadata = getMetadataWithFallback(newNfts.data, '')
        const attributes = getPancakeBunniesAttributesField('')
        return combineApiAndSgResponseToNftToken(apiMetadata, marketData, attributes)
      })
      return [...updatedTokens, ...moreTokensWithRequestedBunnyId]
    } catch (error) {
      console.error(`Failed to update PancakeBunnies NFTs`, error)
      return []
    }
  },
)

export const fetchUserNfts = createAsyncThunk<
  NftToken[],
  { account: string; profileNftWithCollectionAddress?: TokenIdWithCollectionAddress; collections: ApiCollections }
>('nft/fetchUserNfts', async ({ account, profileNftWithCollectionAddress, collections }) => {
  const completeNftData = await getCompleteAccountNftData(account, collections, profileNftWithCollectionAddress)
  return completeNftData
})

export const updateUserNft = createAsyncThunk<
  NftToken,
  { tokenId: string; collectionAddress: string; location?: NftLocation }
>('nft/updateUserNft', async ({ tokenId, collectionAddress, location = NftLocation.WALLET }) => {
  const marketDataForNft = await getNftsMarketData({
    tokenId_in: [tokenId],
    collection: collectionAddress,
  })
  const metadataForNft = await getNftsFromDifferentCollectionsApi([{ tokenId, collectionAddress }])
  const completeNftData = { ...metadataForNft[0], location, marketData: marketDataForNft[0] }

  return completeNftData
})

export const removeUserNft = createAsyncThunk<string, { tokenId: string }>(
  'nft/removeUserNft',
  async ({ tokenId }) => tokenId,
)

export const addUserNft = createAsyncThunk<
  NftToken,
  { tokenId: string; collectionAddress: string; nftLocation?: NftLocation }
>('nft/addUserNft', async ({ tokenId, collectionAddress, nftLocation = NftLocation.WALLET }) => {
  const marketDataForNft = await getNftsMarketData({
    tokenId_in: [tokenId],
    collection: collectionAddress,
  })
  const metadataForNft = await getNftsFromDifferentCollectionsApi([{ tokenId, collectionAddress }])

  return {
    ...metadataForNft[0],
    location: nftLocation,
    marketData: marketDataForNft[0],
  }
})

export const fetchUserActivity = createAsyncThunk('nft/fetchUserActivity', async (address: string) => {
  const userActivity = await getUserActivity(address)
  return userActivity
})

export const NftMarket = createSlice({
  name: 'NftMarket',
  initialState: initialNftMarketState,
  reducers: {
    addAttributeFilter: (state, action: PayloadAction<NftAttribute>) => {
      state.data.filters.activeFilters = {
        ...state.data.filters.activeFilters,
        [action.payload.traitType]: action.payload,
      }
    },
    removeAttributeFilter: (state, action: PayloadAction<string>) => {
      if (state.data.filters.activeFilters[action.payload]) {
        delete state.data.filters.activeFilters[action.payload]
      }
    },
    removeAllFilters: (state, action: PayloadAction<string>) => {
      state.data.filters.activeFilters = {}
      state.data.nfts[action.payload] = []
    },
    addActivityTypeFilters: (state, action: PayloadAction<{ collection: string; field: MarketEvent }>) => {
      if (state.data.activityFilters[action.payload.collection]) {
        state.data.activityFilters[action.payload.collection].typeFilters.push(action.payload.field)
      } else {
        state.data.activityFilters[action.payload.collection] = {
          ...initialNftActivityFilterState,
          typeFilters: [action.payload.field],
        }
      }
    },
    removeActivityTypeFilters: (state, action: PayloadAction<{ collection: string; field: MarketEvent }>) => {
      if (state.data.activityFilters[action.payload.collection]) {
        state.data.activityFilters[action.payload.collection].typeFilters = state.data.activityFilters[
          action.payload.collection
        ].typeFilters.filter((activeFilter) => activeFilter !== action.payload.field)
      }
    },
    addActivityCollectionFilters: (state, action: PayloadAction<{ collection: string }>) => {
      if (state.data.activityFilters['']) {
        state.data.activityFilters[''].collectionFilters.push(action.payload.collection)
      } else {
        state.data.activityFilters[''] = {
          ...initialNftActivityFilterState,
          collectionFilters: [action.payload.collection],
        }
      }
    },
    removeActivityCollectionFilters: (state, action: PayloadAction<{ collection: string }>) => {
      if (state.data.activityFilters['']) {
        state.data.activityFilters[''].collectionFilters = state.data.activityFilters[''].collectionFilters.filter(
          (activeFilter) => activeFilter !== action.payload.collection,
        )
      }
    },
    removeAllActivityCollectionFilters: (state) => {
      if (state.data.activityFilters['']) {
        state.data.activityFilters[''].collectionFilters = []
      }
    },
    removeAllActivityFilters: (state, action: PayloadAction<string>) => {
      state.data.activityFilters[action.payload] = { ...initialNftActivityFilterState }
    },
    setActivityOrdering: (state, action: PayloadAction<{ field: string; duration: number }>) => {
      state.data.activityOrdering = action.payload
    },
    setOrdering: (state, action: PayloadAction<{ field: string; direction: 'asc' | 'desc' }>) => {
      state.data.filters.ordering = action.payload
    },
    setSearchInput: (state, action: PayloadAction<string>) => {
      state.data.filters.searchInput = action.payload
    },
    setShowOnlyOnSale: (state, action: PayloadAction<boolean>) => {
      state.data.filters.showOnlyOnSale = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(filterNftsFromCollection.pending, (state) => {
      state.data.filters.loadingState = NftFilterLoadingState.LOADING
    })
    builder.addCase(filterNftsFromCollection.fulfilled, (state, action) => {
      const { collectionAddress, nftFilters } = action.meta.arg

      state.data.filters = {
        ...state.data.filters,
        loadingState: NftFilterLoadingState.IDLE,
        activeFilters: nftFilters,
      }
      state.data.nfts[collectionAddress] = action.payload
    })
    builder.addCase(fetchDealTokens.fulfilled, (state, action) => {
      state.data.dealTokens = action.payload
    })
    builder.addCase(fetchCollection.fulfilled, (state, action) => {
      state.data.collections = { ...state.data.collections, ...action.payload }
    })
    builder.addCase(fetchCollections.fulfilled, (state, action) => {
      state.data.collections = action.payload
      state.initializationState = NFTMarketInitializationState.INITIALIZED
    })
    builder.addCase(fetchNftsFromCollections.pending, (state) => {
      state.data.filters.loadingState = NftFilterLoadingState.LOADING
    })
    builder.addCase(fetchNftsFromCollections.fulfilled, (state, action) => {
      const { collectionAddress } = action.meta.arg
      const existingNfts: NftToken[] = state.data.nfts[collectionAddress] ?? []
      const existingNftsWithoutNewOnes = existingNfts.filter(
        (nftToken) => !action.payload.find((newToken) => newToken.tokenId === nftToken.tokenId),
      )

      state.data.filters = {
        ...state.data.filters,
        loadingState: NftFilterLoadingState.IDLE,
        activeFilters: {},
      }
      state.data.nfts[collectionAddress] = [...existingNftsWithoutNewOnes, ...action.payload]
    })
    builder.addCase(fetchNewPBAndUpdateExisting.pending, (state) => {
      state.data.loadingState.isUpdatingPancakeBunnies = true
    })
    builder.addCase(fetchNewPBAndUpdateExisting.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.data.nfts[pancakeBunniesAddress] = action.payload
      }
      state.data.loadingState.isUpdatingPancakeBunnies = false
      state.data.loadingState.latestPancakeBunniesUpdateAt = Date.now()
    })
    builder.addCase(fetchNewPBAndUpdateExisting.rejected, (state) => {
      state.data.loadingState.isUpdatingPancakeBunnies = false
      state.data.loadingState.latestPancakeBunniesUpdateAt = Date.now()
    })
    builder.addCase(fetchUserNfts.rejected, (state) => {
      state.data.user.userNftsInitializationState = UserNftInitializationState.ERROR
    })
    builder.addCase(fetchUserNfts.pending, (state) => {
      state.data.user.userNftsInitializationState = UserNftInitializationState.INITIALIZING
    })
    builder.addCase(fetchUserNfts.fulfilled, (state, action) => {
      state.data.user.nfts = action.payload
      state.data.user.userNftsInitializationState = UserNftInitializationState.INITIALIZED
    })
    builder.addCase(updateUserNft.fulfilled, (state, action) => {
      const userNftsState: NftToken[] = state.data.user.nfts
      const nftToUpdate = userNftsState.find((nft) => nft.tokenId === action.payload.tokenId)
      const indexInState = userNftsState.indexOf(nftToUpdate)
      state.data.user.nfts[indexInState] = action.payload
    })
    builder.addCase(removeUserNft.fulfilled, (state, action) => {
      const copyOfState: NftToken[] = [...state.data.user.nfts]
      const nftToRemove = copyOfState.find((nft) => nft.tokenId === action.payload)
      const indexInState = copyOfState.indexOf(nftToRemove)
      copyOfState.splice(indexInState, 1)
      state.data.user.nfts = copyOfState
    })
    builder.addCase(addUserNft.fulfilled, (state, action) => {
      state.data.user.nfts = [...state.data.user.nfts, action.payload]
    })
    builder.addCase(fetchUserActivity.fulfilled, (state, action) => {
      state.data.user.activity = { ...action.payload, initializationState: UserNftInitializationState.INITIALIZED }
    })
    builder.addCase(fetchUserActivity.rejected, (state) => {
      state.data.user.activity.initializationState = UserNftInitializationState.ERROR
    })
    builder.addCase(fetchUserActivity.pending, (state) => {
      state.data.user.activity.initializationState = UserNftInitializationState.INITIALIZING
    })
  },
})

// Actions
export const {
  addAttributeFilter,
  removeAttributeFilter,
  removeAllFilters,
  removeAllActivityFilters,
  removeActivityTypeFilters,
  removeActivityCollectionFilters,
  removeAllActivityCollectionFilters,
  addActivityTypeFilters,
  addActivityCollectionFilters,
  setActivityOrdering,
  setOrdering,
  setSearchInput,
  setShowOnlyOnSale,
} = NftMarket.actions

export default NftMarket.reducer
