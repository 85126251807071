// Set of helper functions to facilitate wallet setup

import { BASE_BSC_SCAN_URL, BASE_URL } from 'config'
import { ExternalProvider } from '@ethersproject/providers'
import { ChainId } from '@plearn/sdk'
import { nodes, polygonNodes, ethereumNodes } from './getRpcUrl'
// Set of helper functions to facilitate wallet setup

const NETWORK_CONFIG = {
  [ChainId.MAINNET]: {
    name:
      process.env.REACT_APP_BASE_CHAIN === 'BSC'
        ? 'BNB Smart Chain Mainnet'
        : process.env.REACT_APP_BASE_CHAIN === 'POLYGON'
        ? 'Polygon Mainnet'
        : 'Ethereum Mainnet',
    scanURL: BASE_BSC_SCAN_URL,
  },
  [ChainId.TESTNET]: {
    name:
      process.env.REACT_APP_BASE_CHAIN === 'BSC'
        ? 'BNB Smart Chain Testnet'
        : process.env.REACT_APP_BASE_CHAIN === 'POLYGON'
        ? 'Polygon Testnet'
        : 'Ethereum Testnet',
    scanURL: BASE_BSC_SCAN_URL,
  },
}

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (externalProvider?: ExternalProvider) => {
  const provider = externalProvider || window.ethereum

  let chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
  let name = 'BNB'
  let symbol = 'bnb'
  let rpcUrls = nodes

  switch (process.env.REACT_APP_BASE_CHAIN) {
    case 'BSC': {
      chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
      name = 'BNB'
      symbol = 'bnb'
      rpcUrls = nodes
      break
    }
    case 'POLYGON': {
      chainId = parseInt(process.env.REACT_APP_POLYGON_CHAIN_ID, 10)
      name = 'MATIC'
      symbol = 'matic'
      rpcUrls = polygonNodes
      break
    }
    case 'ETHEREUM': {
      chainId = parseInt(process.env.REACT_APP_ETH_CHAIN_ID, 10)
      name = 'ETH'
      symbol = 'eth'
      rpcUrls = ethereumNodes
      break
    }
    default:
      chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
      name = 'BNB'
      symbol = 'bnb'
      rpcUrls = nodes
      break
  }

  if (!NETWORK_CONFIG[chainId]) {
    console.error('Invalid chain id')
    return false
  }
  if (provider) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
      return true
    } catch (switchError) {
      if ((switchError as any)?.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: NETWORK_CONFIG[chainId].name,
                nativeCurrency: {
                  name,
                  symbol,
                  decimals: 18,
                },
                rpcUrls,
                blockExplorerUrls: [`${NETWORK_CONFIG[chainId].scanURL}/`],
              },
            ],
          })
          return true
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error)
          return false
        }
      }
      return false
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
    return false
  }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number) => {
  const tokenAdded = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: `${BASE_URL}/images/tokens/${tokenAddress}.png`,
      },
    },
  })

  return tokenAdded
}
