import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import {
  Modal,
  Text,
  Flex,
  Image,
  Button,
  Slider,
  BalanceInput,
  AutoRenewIcon,
  Link,
  CalculateIcon,
  IconButton,
  Message,
  InfoIcon,
  useMatchBreakpoints,
} from '@plearn/uikit'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useToast from 'hooks/useToast'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import RoiCalculatorModal from 'components/RoiCalculatorModal'
import {
  getFullDisplayBalance,
  formatNumber,
  getDecimalAmount,
  getBalanceAmount,
  getBalanceNumber,
} from 'utils/formatBalance'
import { DeserializedMemberPool } from 'state/types'
import { getInterestBreakdown } from 'utils/compoundApyHelpers'
import { FOUNDING_POOL_ID, LOCKED_POOL_ID } from 'config/constants/pools'
import tiers from 'config/constants/tiers'
import { AutoRow } from 'components/Layout/Row'
import { format } from 'date-fns'
import useStakePool from '../../hooks/useStakePool'
import useUnstakePool from '../../hooks/useUnstakePool'
import TierButton from './TierButton'

interface StakeModalProps {
  pool: DeserializedMemberPool
  stakingTokenBalance: BigNumber
  stakingTokenPrice: number
  isRemovingStake?: boolean
  isUpgrade?: boolean
  tierIndexForUpgrade?: number
  onDismiss?: () => void
}

const StyledLink = styled(Link)`
  width: 100%;
`

const AnnualRoiContainer = styled(Flex)`
  cursor: pointer;
`

const AnnualRoiDisplay = styled(Text)`
  width: 72px;
  max-width: 72px;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
`
// background: rgba(252,153,3,0.1);
const MessageContainer = styled(Message)`
  background: rgba(252, 153, 3, 0.12);
  border-radius: 8px;
  padding: 8px;
  border: solid 0px;
`

const ScrollableContainer = styled(Flex)`
  flex-direction: column;
  max-height: 400px;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-height: none;
  }
`

const StakeModal: React.FC<StakeModalProps> = ({
  pool,
  stakingTokenBalance,
  stakingTokenPrice,
  isRemovingStake = false,
  isUpgrade = false,
  tierIndexForUpgrade,
  onDismiss,
}) => {
  const { stakingToken, userData, earningPlnToken } = pool
  const { t } = useTranslation()
  const { theme } = useTheme()

  const { onStake } = useStakePool()
  const { onUnstake } = useUnstakePool()
  const { toastSuccess, toastError } = useToast()
  const { isMobile } = useMatchBreakpoints()
  const [pendingTx, setPendingTx] = useState(false)
  const [stakeAmount, setStakeAmount] = useState('')
  const [tierIndexSelected, setTierIndexSelected] = useState(
    isUpgrade ? tierIndexForUpgrade ?? 1 : userData.tierIndex ?? 1,
  )
  const [hasReachedStakeLimit, setHasReachedStakedLimit] = useState(false)
  const [hasNeedMoreAmount, setHasNeedMoreAmount] = useState(false)
  const [percent, setPercent] = useState(0)

  const tierSelected = useMemo(() => {
    return tiers[tierIndexSelected]
  }, [tierIndexSelected])

  const stakingLimit = useMemo(() => {
    const maxAmount = new BigNumber(tierSelected.maxAmount.toString())
    return getDecimalAmount(maxAmount)
  }, [tierSelected])

  const sliderMaxAmount = useMemo(() => {
    const userStakedBalance = getBalanceAmount(userData.stakedBalance).toNumber()
    let maxAmount = 0
    if (userStakedBalance < tierSelected.maxAmount) {
      maxAmount = tierSelected.maxAmount - userStakedBalance
    }
    return maxAmount
  }, [tierSelected.maxAmount, userData.stakedBalance])

  const sliderStakingAmountPerOnePercent = useMemo(() => {
    return sliderMaxAmount * 0.01
  }, [sliderMaxAmount])

  // End On
  const currentUnixTime = Math.floor(new Date().getTime() / 1000)
  const currentDay = Math.floor((currentUnixTime + 43200) / 86400)
  const endLockDay = Math.floor((userData.endLockTime - 43200) / 86400)
  const firstDayLock = userData.depositStartDay
  const endDay = currentDay + tierSelected.lockDayDuration
  const daysSinceLock = currentDay - firstDayLock
  const upgradeTierDay = endDay - daysSinceLock
  const endOn = endDay * 86400 + 43200
  const upgradeTierEndOn = upgradeTierDay * 86400 + 43200
  const isTierUpgraded = userData.tierIndex !== tierIndexSelected
  const endOnDate = currentDay > endLockDay ? endOn : isTierUpgraded ? upgradeTierEndOn : userData.endLockTime
  const timestampAsMs = endOnDate * 1000
  const localeTimestamp = format(timestampAsMs ?? 0, 'dd MMMM yyyy HH:mm')

  const fullDecimalStakeAmount = getDecimalAmount(new BigNumber(stakeAmount), stakingToken.decimals)

  const userNotEnoughToken = isRemovingStake
    ? userData.stakedBalance.lt(fullDecimalStakeAmount)
    : stakingTokenBalance.lt(fullDecimalStakeAmount)

  const usdValueStaked = new BigNumber(stakeAmount).times(stakingTokenPrice)
  const formattedUsdValueStaked = !usdValueStaked.isNaN() && formatNumber(usdValueStaked.toNumber())

  const getTokenLink = stakingToken.address ? `/swap?outputCurrency=${stakingToken.address}` : '/swap'

  useEffect(() => {
    if (stakingLimit.gt(0) && !isRemovingStake) {
      setHasReachedStakedLimit(fullDecimalStakeAmount.plus(userData.stakedBalance).gt(stakingLimit))
    }
  }, [
    stakeAmount,
    stakingLimit,
    userData,
    stakingToken,
    isRemovingStake,
    setHasReachedStakedLimit,
    fullDecimalStakeAmount,
  ])

  useEffect(() => {
    setHasNeedMoreAmount(
      Number(stakeAmount) + getBalanceNumber(userData.stakedBalance, stakingToken.decimals) < tierSelected.minAmount,
    )
  }, [stakeAmount, stakingToken.decimals, tierSelected.minAmount, userData.stakedBalance])

  useEffect(() => {
    if (isUpgrade) {
      const _tier = tiers[tierIndexForUpgrade]
      const userStakedBalance = getBalanceAmount(userData.stakedBalance).toNumber()
      let minimumAmount = 0
      if (userStakedBalance < _tier.minAmount) {
        minimumAmount = _tier.minAmount - userStakedBalance
      }

      let maximumAmount = 0
      if (userStakedBalance < _tier.maxAmount) {
        maximumAmount = _tier.maxAmount - userStakedBalance
      }

      const percentage = (minimumAmount / maximumAmount) * 100
      setPercent(Math.min(percentage, 100))
      setStakeAmount(minimumAmount.toString())
    }
  }, [isUpgrade, tierIndexForUpgrade, userData.stakedBalance])

  const handleStakeInputChange = (input: string) => {
    if (input) {
      const convertedInput = Number(input)
      const percentage = (convertedInput / sliderMaxAmount) * 100
      setPercent(Math.min(percentage, 100))
    } else {
      setPercent(0)
    }
    setStakeAmount(input)
  }

  const handleChangePercent = (sliderPercent: number) => {
    const amountToStake = (sliderPercent * sliderStakingAmountPerOnePercent).toString()

    const fullDecimalAmountToStake = getDecimalAmount(new BigNumber(amountToStake), stakingToken.decimals)
    const amountToStakeFullDisplay = getFullDisplayBalance(fullDecimalAmountToStake, stakingToken.decimals)

    setStakeAmount(amountToStakeFullDisplay)
    setPercent(sliderPercent)
  }

  const handleChangeTier = (tierIndex: number) => {
    setTierIndexSelected(tierIndex)
    const _tier = tiers[tierIndex]
    const userStakedBalance = getBalanceAmount(userData.stakedBalance).toNumber()
    let minimumAmount = 0
    if (userStakedBalance < _tier.minAmount) {
      minimumAmount = _tier.minAmount - userStakedBalance
    }

    let maximumAmount = 0
    if (userStakedBalance < _tier.maxAmount) {
      maximumAmount = _tier.maxAmount - userStakedBalance
    }

    const percentage = (minimumAmount / maximumAmount) * 100
    setPercent(Math.min(percentage, 100))
    setStakeAmount(minimumAmount.toString())
  }

  const handleConfirmClick = async () => {
    setPendingTx(true)
    try {
      if (isRemovingStake) {
        const textDisplay = t('Your %symbol% earnings have also been harvested to your wallet!', {
          symbol: earningPlnToken.symbol,
        })
        // unstaking
        await onUnstake(stakeAmount, stakingToken.decimals)
        toastSuccess(`${t('Unstaked')}!`, textDisplay)
      } else {
        // staking
        await onStake(stakeAmount, stakingToken.decimals, tierSelected.id)
        toastSuccess(
          `${t('Staked')}!`,
          t('Your %symbol% funds have been staked in the pool!', {
            symbol: stakingToken.symbol,
          }),
        )
      }
      setPendingTx(false)
      onDismiss()
    } catch (e) {
      toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      setPendingTx(false)
    }
  }

  return (
    <Modal
      //   minWidth="346px"
      maxWidth="420px"
      title={isRemovingStake ? t('Unstake') : t('Stake in Pool')}
      onDismiss={onDismiss}
      headerBackground={theme.colors.gradients.cardHeader}
    >
      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Text bold>{isRemovingStake ? t('Unstake') : t('Stake')}:</Text>
        <Flex alignItems="center" minWidth="70px">
          <Image src={`/images/tokens/${stakingToken.address}.png`} width={24} height={24} alt={stakingToken.symbol} />
          <Text ml="4px" bold>
            {stakingToken.symbol}
          </Text>
        </Flex>
      </Flex>

      <Flex flexWrap="wrap">
        {Object.keys(tiers).map((tierIndex) => {
          if (parseInt(tierIndex) >= 1) {
            const isTierSelected = tierIndexSelected === parseInt(tierIndex)
            const isTierDisabled =
              userData.tierIndex > parseInt(tierIndex) ||
              getBalanceNumber(userData.stakedBalance, stakingToken.decimals) === tiers[tierIndex].maxAmount

            return (
              <TierButton
                key={tierIndex}
                onClick={() => handleChangeTier(parseInt(tierIndex))}
                isSelected={isTierSelected}
                isDisabled={isTierDisabled}
              >
                {tiers[tierIndex].name}
              </TierButton>
            )
          }
          return null
        })}
      </Flex>

      {tierSelected && (
        <>
          {tierIndexSelected === 4 ? (
            <Text color="#E7D4B7" mb="16" style={{ textAlign: 'center' }}>
              {`(${tierSelected.name}: ${formatNumber(tierSelected.minAmount, 0, 0)} - no maximum)`}
            </Text>
          ) : (
            <Text color="#E7D4B7" mb="16" style={{ textAlign: 'center' }}>
              {`(${tierSelected.name}: ${formatNumber(tierSelected.minAmount, 0, 0)} - ${formatNumber(
                tierSelected.maxAmount,
                0,
                0,
              )} PLN)`}
            </Text>
          )}
        </>
      )}
      <Flex mb="16px">
        <Slider
          min={0}
          max={100}
          value={percent}
          onValueChanged={handleChangePercent}
          name="stake"
          // valueLabel={`${formatNumber(percent * sliderStakingAmountPerOnePercent)} PLN`}
          step={1}
          width="100%"
        />
      </Flex>

      <BalanceInput
        value={stakeAmount}
        onUserInput={handleStakeInputChange}
        currencyValue={stakingTokenPrice !== 0 && `~${formattedUsdValueStaked || 0} USD`}
        isWarning={hasReachedStakeLimit || userNotEnoughToken}
        decimals={stakingToken.decimals}
      />
      {hasReachedStakeLimit && (
        <Text color="failure" fontSize="12px" style={{ textAlign: 'right' }} mt="4px">
          {t('Maximum total stake: %amount% %token%', {
            amount: getFullDisplayBalance(new BigNumber(stakingLimit), stakingToken.decimals, 0),
            token: stakingToken.symbol,
          })}
        </Text>
      )}
      {userNotEnoughToken && (
        <Text color="failure" fontSize="12px" style={{ textAlign: 'right' }} mt="4px">
          {t('Insufficient %symbol% balance', {
            symbol: stakingToken.symbol,
          })}
        </Text>
      )}
      <Text ml="auto" color="textSubtle" fontSize="12px" mb="8px">
        {t('Balance: %balance%', {
          balance: getFullDisplayBalance(stakingTokenBalance, stakingToken.decimals),
        })}
      </Text>

      <Text bold>Position overview :</Text>
      <AutoRow mt="3px" style={{ justifyContent: 'space-between' }}>
        <Text color="textSubtle" fontSize="14px">
          Staked
        </Text>
        <Text color="textSubtle" fontSize="14px">
          {`${formatNumber(getBalanceNumber(userData.stakedBalance, stakingToken.decimals))} PLN`}
        </Text>
      </AutoRow>
      <AutoRow style={{ justifyContent: 'space-between' }}>
        <Text color="textSubtle" fontSize="14px">
          Stake amount
        </Text>
        <Text color="textSubtle" fontSize="14px">
          {formatNumber(Number(stakeAmount))} PLN
        </Text>
      </AutoRow>
      <AutoRow style={{ justifyContent: 'space-between' }}>
        <Text color="textSubtle" fontSize="14px">
          Total
        </Text>
        <Text color="textSubtle" fontSize="14px">
          {formatNumber(Number(stakeAmount) + getBalanceNumber(userData.stakedBalance, stakingToken.decimals))} PLN
        </Text>
      </AutoRow>
      <AutoRow style={{ justifyContent: 'space-between' }}>
        <Text color="textSubtle" fontSize="14px">
          Lock duration
        </Text>
        <Text color="textSubtle" fontSize="14px">
          {tierSelected.lockDayDuration} days
        </Text>
      </AutoRow>
      <AutoRow style={{ justifyContent: 'space-between' }}>
        <Text color="textSubtle" fontSize="14px">
          APR
        </Text>
        <Text color="textSubtle" fontSize="14px">
          {tierSelected.plnBaseApr + tierSelected.plnLockApr}%
        </Text>
      </AutoRow>
      <AutoRow style={{ justifyContent: 'space-between' }}>
        <Text color="textSubtle" fontSize="14px">
          PCC per 100 PLN
        </Text>
        <Text color="textSubtle" fontSize="14px">
          {tierSelected.pccBaseApr + tierSelected.pccLockApr} PCC
        </Text>
      </AutoRow>
      <AutoRow style={{ justifyContent: 'space-between' }}>
        <Text color="textSubtle" fontSize="14px">
          Ends on
        </Text>
        <Text color="textSubtle" fontSize="14px">
          {localeTimestamp}
        </Text>
      </AutoRow>
      <Flex mt="24px">
        <Button
          width="100%"
          isLoading={pendingTx}
          endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
          onClick={handleConfirmClick}
          disabled={
            !stakeAmount ||
            parseFloat(stakeAmount) === 0 ||
            hasReachedStakeLimit ||
            userNotEnoughToken ||
            hasNeedMoreAmount
          }
        >
          {hasNeedMoreAmount ? `Minimum ${tierSelected.minAmount} PLN` : pendingTx ? t('Confirming') : t('Confirm')}
        </Button>
      </Flex>

      {!isRemovingStake && (
        <StyledLink external href={getTokenLink}>
          <Button width="100%" mt="8px" mb="10px" variant="secondary">
            {t('Get %symbol%', { symbol: stakingToken.symbol })}
          </Button>
        </StyledLink>
      )}
    </Modal>
  )
}

export default StakeModal
